import { Form } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect } from "react-redux";
import { TGeneral, TGeneralStore } from "../../../../redux/reducers";
import { useDispatch, useSelector, useStore } from "react-redux";
import { actGeneratoreSetModel, actGeneratoreSetOptionals, actGeneratoreSetVoltaggio, actGeneral } from "../../../../redux/actions";
import {
  EGeneratoreType,
} from "../../../../redux/reducers/generatore";
import Modal, { ModalSize } from "../../../../components/Modal";
import { useState } from "react";
import SelectableGridItem from "../../../../components/GridItem/SelectableGridItem";
import { TGeneratoreModelType, TGeneratoreModelStoreState, TGeneratoreType, TGalleryItem, filterModels, TGeneratoreModelDetailType, TDescriptionBlockType, getVolume } from "../../../../redux/reducers/generatoreestint";
import AsyncSelect from "../../../../components/AsyncSelect";
import TextCell from "../../../../components/AsyncSelect/TextCell";
import ActionButton, { IActionButtonType } from "../../../../components/Buttons/ActionButton";
import { getGeneratoriInterni, getGeneratoriEsterni, getModelliDiProdotto, getModelloDiProdotto, getOptional } from "../../../../network/actions";
import { LIMIT, PLACEHOLDER_IMAGE, SLUG_NUVOLA_SP_110, SLUG_NUVOLA_SP_140, SLUG_NUVOLA_SP_180, SLUG_NUVOLA_SP_250, SLUG_NUVOLA_SP_30, SLUG_NUVOLA_SP_360, SLUG_NUVOLA_SP_45, SLUG_NUVOLA_SP_55, SLUG_NUVOLA_SP_70, SLUG_NUVOLA_SP_90 } from "../../../../utils/consts";
import { useEffect } from "react";
import { TVanoDocciaStoreState } from "../../../../redux/reducers/vano_doccia";
import { capitalize, capitalizeAllWords, fromMCubiToCurrentUnitOfMeasure, getLocalization, getUnitOfMeasureMCubiLabel, Localization } from "../../../../utils/utils";
import { TStrutturaVanoDocciaStoreState } from "../../../../redux/reducers/struttura_vano_doccia";
import { getLabelEVoltaggioType, getVoltaggiExternal, getVoltaggiInternal } from "../../../../redux/reducers/voltaggio";
import Gallery from "../../../../components/Gallery";
import { StepConfiguratore } from "../..";
import MsgError from "../../../../components/MsgError";
import { TOptionalDetailType } from "../../../../redux/reducers/optional";
import { CrmLanguage, getAuthInfo } from "../../../../utils/storage";

function GeneratoreEstInt({ ...props }) {
  const dispatch = useDispatch();
  const store: TGeneralStore = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const generatore_product: TGeneratoreModelStoreState= useSelector(
    (state: TGeneral) => state.generatore_product
  );
  const vano_doccia: TVanoDocciaStoreState= useSelector(
    (state: TGeneral) => state.vano_doccia
  );
  const struttura_vano_doccia: TStrutturaVanoDocciaStoreState= useSelector(
    (state: TGeneral) => state.struttura_vano_doccia
  );

  const [currentGeneratore, setCurrentGeneratore] = useState<TGeneratoreType|undefined>(undefined);

  //InfiniteScroll
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [items, setItems] = useState<TGeneratoreType[]>([]);
  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [isLoadingModelli, setIsLoadingModelli] = useState<boolean>(false);

  const openModal = (title: string, content: any) => {
    setModalData({ title, content, size:ModalSize.xl });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
    setCurrentGeneratore(undefined)
  };

  useEffect(()=>{
    getGeneratoriFromAPI()
  },[])

  const getGeneratoriFromAPI = async () => {
    try {
      setIsLoading(true)
      let data:TGeneratoreType[];
      if(!isGeneratoreEsternoSelected() && store.getState()?.generatore_product?.list_generatori_interni?.length)
        data = store.getState()?.generatore_product?.list_generatori_interni as TGeneratoreType[]
      else
        data = (isGeneratoreEsternoSelected() ? await getGeneratoriEsterni(offset, LIMIT) : await getGeneratoriInterni(offset, LIMIT)) as TGeneratoreType[]
      data = data?.filter(g => !!!g?.acf?.hide_config)
      setItems(data)
      setOffset(offset+LIMIT)
      setHasMore(data?.length>0 && data?.length%LIMIT===0)
      setIsLoading(false)
    } catch (err) {
      //TODO: Alert
      setIsLoading(false)
    }
  }

  const isGeneratoreEsternoSelected = () : boolean => store.getState()?.generatore.tipologia == EGeneratoreType.Esterno;

  const getCaratteristicheField = (model:TGeneratoreModelDetailType|undefined, maxChar?:number) => {
      let field = model ? (model.acf?.description_block || []).slice(2).map((block:TDescriptionBlockType, indexBlock: number)=>{
        return(
          `<span class="font-bold">${capitalize(block.titolo_blocco_descrizione)}:</span><br/><span style="white-space: normal;">${block.content_blocco_descrizione}</span>`
        )
      }).join("<br/>") : ""
      return maxChar ? `${field.substr(0,maxChar)}...` : field
  }

  const roundMCubi = (mcubi:number) => getLocalization()===Localization.AMERICA ? Math.round(mcubi) : mcubi
  const _min_metri_cubi_no_kit_di_coibentazione = store.getState()?.struttura_vano_doccia?.kit_coibentazione ? (store?.getState()?.generatore_product?.generatore_model?.acf?.min_metri_cubi_potenza) : (store?.getState()?.generatore_product?.generatore_model?.acf?.min_metri_cubi_no_kit_di_coibentazione)
  const _max_metri_cubi_no_kit_di_coibentazione = store.getState()?.struttura_vano_doccia?.kit_coibentazione ? (store?.getState()?.generatore_product?.generatore_model?.acf?.metri_cubi_potenza_max) : (store?.getState()?.generatore_product?.generatore_model?.acf?.max_metri_cubi_no_kit_di_coibentazione)
  const _voltaggi = store?.getState()?.generatore?.tipologia===EGeneratoreType.Esterno ? getVoltaggiExternal() : getVoltaggiInternal()
  const _powerLabel = `${store?.getState()?.generatore_product?.generatore_model?.acf?.more_details?.power_model}kW max${getLocalization()===Localization.AMERICA ? ` - ${_voltaggi.map(v => `${props.t(getLabelEVoltaggioType(v))}`).join(", ")}` : ""}`
  const _mCubicLabel = _min_metri_cubi_no_kit_di_coibentazione && _max_metri_cubi_no_kit_di_coibentazione ? `${roundMCubi(Number(fromMCubiToCurrentUnitOfMeasure(_min_metri_cubi_no_kit_di_coibentazione))||0)} - ${roundMCubi(Number(fromMCubiToCurrentUnitOfMeasure(_max_metri_cubi_no_kit_di_coibentazione))||0)} ${props.t(getUnitOfMeasureMCubiLabel())}` : ""
  const _gallery: string[] = (store?.getState()?.generatore_product?.generatore_model && Array.isArray(currentGeneratore?.acf.slider_gallery)) ? currentGeneratore!.acf.slider_gallery.map((curr_gallery_modello:TGalleryItem)=>curr_gallery_modello?.["image-slider-gallery"]) : [];
  
  return (
    <>
        <H3
          title={isGeneratoreEsternoSelected() ? props.t("generatoreestint.esterni.titolo") : props.t("generatoreestint.interni.titolo")}
          className="text-left text-black-effe m-title"
          isLoading={isLoading}
        />
        <div>
          <Row className="mb-4">
              {items.map((it:TGeneratoreType) => {
                const isSelected = generatore_product?.generatore_product?.id == it.id && !!generatore_product?.generatore_model?.id;
                const _volume = getVolume(store?.getState().vano_doccia)
                console.log(`Vol VanoDoccia ${_volume} => ${it?.title.rendered} conKit(${it?.acf?.max_volume_generatore_con_kit}), senzaKit(${it?.acf?.max_volume_generatore_no_kit})`)
                return (
                  <Col key={it.id} xs={6} sm={6} md={6} lg={6} className="mb-4">
                    <SelectableGridItem
                      key={it?.id}
                      title={it.title.rendered}
                      isLoading={isLoadingModelli}
                      disabled={(isLoadingModelli && !isSelected) || 
                          (getLocalization()===Localization.AMERICA && !store?.getState()?.struttura_vano_doccia?.kit_coibentazione && _volume > Number(it?.acf?.max_volume_generatore_no_kit)) || 
                          _volume > Number(it?.acf?.max_volume_generatore_con_kit)
                      }
                      titleAction={props.t("generatoreestint.modelli.title")}
                      isSelected={isSelected}
                      htmlDecode
                      img={it?.acf?.steam_image?.url || PLACEHOLDER_IMAGE}
                      imgStyle={{maxHeight:170}} 
                      onClick={()=>{
                          if(!isSelected){
                              dispatch(actGeneral({appLoading: true}))

                              dispatch(actGeneratoreSetModel({
                                  generatore_product: it,
                                  generatore_model: undefined
                              }))
                              //reset optionals due to they're linked to product
                              dispatch(actGeneratoreSetOptionals({optionals:[]}))
                              setCurrentGeneratore(it)
                              //Download gallery
                              getModelliDiProdotto(it.id).then(async (res:TGeneratoreType)=>{     
                                const list_modelli: TGeneratoreModelType[] = res?.acf.accordion_group.models_relationship || []
                                let _modelli : TGeneratoreModelDetailType[] = []

                                for (let indexModel = 0; indexModel < list_modelli.length; indexModel++) {
                                  const modello = list_modelli[indexModel];
                                  try {
                                    const _dati_modello = await getModelloDiProdotto(modello.ID)
                                    _dati_modello.title!.rendered! = String(_dati_modello.title?.rendered).replaceAll("#038;","")
                                    _modelli.push(_dati_modello)
                                  } catch (err) { }
                                }


                                const modelsFiltered = filterModels(_modelli, getLocalization()===Localization.EUROPA || (getLocalization()===Localization.AMERICA && !!struttura_vano_doccia.kit_coibentazione) , vano_doccia)
                                if(!store?.getState()?.generatore_product?.generatore_model && modelsFiltered?.filter(m => m.is_visible).length>0){
                                  dispatch(actGeneratoreSetModel({
                                    generatore_model: modelsFiltered.filter(m => m.is_visible)[0]
                                  }))
                                  dispatch(actGeneratoreSetVoltaggio({
                                    voltaggio: undefined
                                  }))
                                }
                                dispatch(actGeneral({appLoading: false}))

                                openModal(it.title.rendered,()=>{
                                    const isSmartPower = store?.getState()?.generatore_product?.generatore_product?.slug === "nuvola-smart";
                                    let listSteamDiffusors:any[] = [];
                                    // console.log(`~~update~~`,store?.getState()?.generatore_product?.generatore_model?.slug)
                                    if(isSmartPower){
                                      switch (store?.getState()?.generatore_product?.generatore_model?.slug) {
                                        case SLUG_NUVOLA_SP_30:
                                        case SLUG_NUVOLA_SP_45:
                                          listSteamDiffusors = [
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["1PZ"].CAMOUFLAGE,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["1PZ"].GLASS,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["1PZ"].TRADITIONAL_CROMO,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["1PZ"].TRADITIONAL_GUN,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["1PZ"].TRADITIONAL_NERO
                                          ];
                                          break;
                                        case SLUG_NUVOLA_SP_55:
                                        case SLUG_NUVOLA_SP_70:
                                        case SLUG_NUVOLA_SP_90:
                                          listSteamDiffusors = [
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["1PZ"].CAMOUFLAGE,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["1PZ"].GLASS,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["2PZ"].TRADITIONAL_CROMO,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["2PZ"].TRADITIONAL_GUN,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["2PZ"].TRADITIONAL_NERO
                                          ]; 
                                            break;

                                        case SLUG_NUVOLA_SP_110:
                                        case SLUG_NUVOLA_SP_140:
                                        case SLUG_NUVOLA_SP_180:
                                          listSteamDiffusors = [
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["2PZ"].CAMOUFLAGE,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["2PZ"].GLASS,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["4PZ"].TRADITIONAL_CROMO,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["4PZ"].TRADITIONAL_GUN,
                                            store?.getState()?.optional.listNSPSteamDiffusors?.["4PZ"].TRADITIONAL_NERO
                                          ]; 
                                          break;
                                        case SLUG_NUVOLA_SP_250:
                                        case SLUG_NUVOLA_SP_360:
                                            listSteamDiffusors = [
                                              store?.getState()?.optional.listNSPSteamDiffusors?.["4PZ"].CAMOUFLAGE,
                                              store?.getState()?.optional.listNSPSteamDiffusors?.["4PZ"].GLASS,
                                              store?.getState()?.optional.listNSPSteamDiffusors?.["8PZ"].TRADITIONAL_CROMO,
                                              store?.getState()?.optional.listNSPSteamDiffusors?.["8PZ"].TRADITIONAL_GUN,
                                              store?.getState()?.optional.listNSPSteamDiffusors?.["8PZ"].TRADITIONAL_NERO
                                            ]; 
                                            break;
                                        default:
                                          break;
                                      }
                                      listSteamDiffusors = JSON.parse(JSON.stringify(listSteamDiffusors))
                                    }
                                    const showNSPSelects = isSmartPower

                                    return(<div>
                                      {
                                        modelsFiltered.filter(m => m.is_visible).length ? 
                                          <Form form={form} name="basic" layout={"vertical"} autoComplete={"off"}>
                                            <Row>
                                              <Col lg={12} className="mb-8 col-content">
                                                <AsyncSelect
                                                  isSecondary
                                                  currValue={store?.getState()?.generatore_product?.generatore_model ? {
                                                    name: store?.getState()?.generatore_product?.generatore_model?.title.rendered,
                                                    id: store?.getState()?.generatore_product?.generatore_model?.id,
                                                  } : null}

                                                  placeholder={props.t("general.scegli_opzione")}
                                                  onChange={(coll: TGeneratoreModelDetailType) => {
                                                    dispatch(actGeneratoreSetModel({
                                                      generatore_model: coll
                                                    }))
                                                    dispatch(actGeneratoreSetOptionals({ optionals: [] }))
                                                  }}
                                                  onStyleLabel={(item: any) => {
                                                    return <TextCell isSecondary title={item.name} disabled={!item.is_visible} htmlDecode />;
                                                  }}
                                                  loadOptions={async (
                                                    searchQuery: any,
                                                    loadedOptions: any,
                                                    { page }: any
                                                  ) => {
                                                    return {
                                                      options: modelsFiltered
                                                        .sort((m1: TGeneratoreModelDetailType, m2: TGeneratoreModelDetailType) => (getLocalization() === Localization.EUROPA || !store?.getState()?.struttura_vano_doccia?.kit_coibentazione ? m1.acf!.max_metri_cubi_no_kit_di_coibentazione! < m2.acf!.max_metri_cubi_no_kit_di_coibentazione! : m1.acf!.metri_cubi_potenza_max! < m2.acf!.metri_cubi_potenza_max!) ? 1 : 0)
                                                        .filter(m => m.is_visible)
                                                        .map((m: TGeneratoreModelDetailType) => {
                                                          return { name: m.title.rendered, ...m };
                                                        }),
                                                      hasMore: false,
                                                      additional: {},
                                                    };
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                            {
                                              showNSPSelects ?
                                              <Row key={"panel_control"}>
                                                <Col lg={12} className="mb-8">
                                                  <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t("v9.pannello_controllo")}</p>
                                                  <AsyncSelect
                                                    isSecondary
                                                    currValue={{
                                                      id: store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey === "panel_control")?.[0]?.optional?.id,
                                                      name: store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey === "panel_control")?.[0]?.optional?.title?.rendered?.toUpperCase()
                                                    }}
                                                    placeholder={props.t("general.scegli_opzione")}
                                                    onChange={(opt: TOptionalDetailType) => {
                                                      dispatch(actGeneratoreSetModel({
                                                        optionalsForModel:
                                                          store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey !== "panel_control")?.concat([{ optional: opt, quantity: 1, disableDelete: true, optKey: "panel_control" }])
                                                      }))
                                                    }}
                                                    onStyleLabel={(item: any) => {
                                                      return <TextCell isSecondary title={item.name} />;
                                                    }}
                                                    loadOptions={async (
                                                      searchQuery: any,
                                                      loadedOptions: any,
                                                      { page }: any
                                                    ) => {
                                                      return {
                                                        options: store?.getState()?.optional?.listNSPControlPanels?.map(o => {
                                                          return ({ name: String(o?.title?.rendered).toUpperCase(), value: o?.id, ...o })
                                                        }),
                                                        hasMore: false,
                                                        additional: {},
                                                      };
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                              : null 
                                            }
                                            {
                                              showNSPSelects ?
                                              <Row key={"diffusor"}>
                                                <Col lg={12} className="mb-8">
                                                  <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t("v9.diffusore_di_vapore")}</p>
                                                  <AsyncSelect
                                                    isSecondary
                                                    currValue={{
                                                      id: store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey === "diffusor")?.[0]?.optional?.id,
                                                      name: store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey === "diffusor")?.[0]?.optional?.title?.rendered?.toUpperCase()
                                                    }}
                                                    placeholder={props.t("general.scegli_opzione")}
                                                    onChange={(opt: TOptionalDetailType) => {
                                                      dispatch(actGeneratoreSetModel({
                                                        optionalsForModel:
                                                        store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey !== "diffusor")?.concat([{ optional: opt, quantity: 1, disableDelete: true, optKey: "diffusor" }])
                                                      }))
                                                    }}
                                                    onStyleLabel={(item: any) => {
                                                      return <TextCell isSecondary title={item.name} />;
                                                    }}
                                                    cacheUniqs={[store?.getState()?.generatore_product?.generatore_model?.id, listSteamDiffusors]}
                                                    loadOptions={async (
                                                      searchQuery: any,
                                                      loadedOptions: any,
                                                      { page }: any
                                                    ) => {
                                                      // console.log("Reload options Diffusors....", listSteamDiffusors)
                                                      return {
                                                        options: listSteamDiffusors?.map(o => {
                                                          return ({ name: String(o?.title?.rendered).toUpperCase(), value: o?.id, ...o })
                                                        }),
                                                        hasMore: false,
                                                        additional: {},
                                                      };
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                              : null 
                                            }
                                          </Form>
                                        :
                                        <p className="text-center">
                                          {props.t("v2.no_models",{prodotto:it.title.rendered,dimensioni:`${store.getState()?.vano_doccia?.larghezza} x ${store.getState()?.vano_doccia?.profondita} x ${store.getState()?.vano_doccia?.altezza}h`})}
                                        </p>
                                      }
                                    </div>)
                                })
                            })
                          }else{
                              dispatch(actGeneratoreSetModel({
                                  generatore_product: undefined,
                                  generatore_model: undefined,
                                  optionalsForModel: []
                              }))
                          }
                      }}
                    />
                  </Col>
                );
              })}
          </Row>         

          {
              store.getState()?.general?.stepError?.step===StepConfiguratore.GeneratoreEstInt &&
              <Row>
                <Col className="mt-8">
                  <MsgError message={store.getState()?.general?.stepError?.msg}/>
                </Col>
              </Row>
            }
        </div>
      

      <Modal
        title={modalData.title}
        onClose={() => {
            dispatch(actGeneratoreSetModel({
                generatore_product: undefined,
                generatore_model: undefined,
                optionalsForModel: []
            }))
            closeModal()
        }}
        closeInline
        htmlDecode
        visible={modalIsOpen}
        size={modalData.size}
        gallery={_gallery}
        footer={ store?.getState()?.generatore_product?.generatore_model && 
            <Row className="mt-8 ">
              <Col  lg={12} className="text-center col-content"> 
                  <ActionButton disabled={
                    getAuthInfo().crm_language===CrmLanguage.IT &&
                    store?.getState()?.generatore_product?.generatore_product?.slug === "nuvola-smart" && 
                    store?.getState()?.generatore_product?.optionalsForModel?.length!<2} 
                    importance={IActionButtonType.Secondary} title={props.t("generatoreestint.modelli.seleziona")} onClick={()=>{
                      closeModal()
                  }}/>
              </Col>
            </Row>
        }
      >
        {modalData.content && modalData.content()}
        {store?.getState()?.generatore_product?.generatore_model && <div>

            <div className="mb-3 xs:block md:hidden ">
              <Col lg={12} className="col-content">
                <Gallery gallery={_gallery}/>
              </Col>
            </div>

            <Row className="mb-3">
                      <Col lg={12} className="col-content">
                          <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t("generatoreestint.modelli.fields.potenza")}</p>
                          <p className="text-black-effe text-titleSectionGenerator">{_powerLabel}</p>
                      </Col>
            </Row>
            {
            _mCubicLabel &&
            <Row className="mb-3">
                    <Col lg={12} className="col-content">
                        <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t("v2.dim_vano_suggerite")}</p>
                        <p className="text-black-effe text-titleSectionGenerator">{_mCubicLabel}</p>
                        {getLocalization()===Localization.AMERICA && <p className="text-black-effe text-titleSectionGenerator">{`${_min_metri_cubi_no_kit_di_coibentazione} - ${_max_metri_cubi_no_kit_di_coibentazione} ${props.t("generatoreestint.modelli.fields.m_cubi")}`}</p>}
                    </Col>
            </Row>
            }
            <Row className="mb-3">
                      <Col lg={12} className="col-content">
                          <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t("generatoreestint.modelli.fields.dimensione")}</p>
                          <p className="text-black-effe text-titleSectionGenerator">{`${store?.getState()?.generatore_product?.generatore_model?.acf?.more_details?.size_x} x ${store?.getState()?.generatore_product?.generatore_model?.acf?.more_details?.size_Z} x ${store?.getState()?.generatore_product?.generatore_model?.acf?.more_details?.size_Y} h`}</p>
                      </Col>
            </Row>
            {
              (store?.getState()?.generatore_product?.generatore_model?.acf?.description_block || []).slice(0,2).map((block:TDescriptionBlockType, indexBlock: number)=>{
                return(
                  <Row key={indexBlock} className="mb-3 ">
                      <Col lg={12} className="col-content">
                          <p className="text-verde-effe font-bold text-titleSectionGenerator">{capitalize(block.titolo_blocco_descrizione)}</p>
                          <p className="text-black-effe text-titleSectionGenerator">{block.content_blocco_descrizione}</p>
                      </Col>
                  </Row>
                )
              })
            }
            {
              (store?.getState()?.generatore_product?.generatore_model?.acf?.description_block || []).length > 2 &&
              <Row className="mb-3">
                        <Col lg={12} className="col-content">
                            <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t("porte_vetrate.popup.caratteristiche")} 
                            </p>
                            <p className="text-black-effe text-titleSectionGenerator" style={{whiteSpace:"pre-wrap"}}>
                              <div dangerouslySetInnerHTML={{__html: getCaratteristicheField(store?.getState()?.generatore_product?.generatore_model)}}></div>
                            </p>
                        </Col>
              </Row>
            }
        </div>}
      </Modal>
    </>
  );
}

export default connect((state:TGeneral, ownProps) => ({
  general: state.general,
  vano_doccia: state.vano_doccia,
  generatore: state.generatore,
  struttura_vano_doccia: state.struttura_vano_doccia,
  optional: state.optional
}), { actGeneratoreSetModel, actGeneratoreSetVoltaggio, actGeneral, actGeneratoreSetOptionals })(
  withTranslation()(GeneratoreEstInt)
);
