import Action, { ActionTypes } from "../actionTypes";
import { TFeaturedMediaType } from "./media";
import { TListinoItemType } from "./porte_vetrate";

export type TListinoAccessorioItemType = TListinoItemType & {
  multipli_for_quantity_sku: boolean;
  multiply_price: number;
  label_quantity?: string;
}

export type TAccessorio = {
    id: string;
    slug: string;
    title: {
      rendered: string
    };
    content:{
      rendered: string;
    };
    _embedded: TFeaturedMediaType;
    description: string; //TODO: Manca campo
    acf: {
      item_relationship: TListinoAccessorioItemType[];
      max_quantity_optional: number
    };
}
export type TAccessorioQuantity = {
    accessorio: TAccessorio;
    quantity: number;
}

export type TAccessoriStoreState = {
    accessori?: TAccessorioQuantity[];
}
const initialState: TAccessoriStoreState = {
    accessori: []
};


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetAccessori: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
