import { Spin } from "antd";
import { FC } from "react";
import InnerLoader from "../../InnerLoader";

interface ITitle extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  title: string;
  isLoading?: boolean;
}

const H3: FC<ITitle> = ({ title, isLoading, ...props }: ITitle) => {
  return (
    <h3 {...props} className={`font-h3 xs:text-h3mobile md:text-h3 flex items-center ${props.className}`} style={{...props.style}}>
      {title} {isLoading ? <InnerLoader small className={"ml-2"}/> : null}
    </h3>
  );
};
export default H3;
