import { TRiepilogoPayload } from "../../redux/reducers/riepilogo";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { H3 } from "../../components/Typography";
import RiepilogoGridItem from "../../components/GridItem/RiepilogoGridItem";
import RiepilogoItem from "../../components/RiepilogoItem";
import RiepilogoSimpleGridItem from "../../components/GridItem/RiepilogoSimpleGridItem";
import { capitalize, capitalizeAllWords, formatPriceForContext, Localization } from "../../utils/utils";
import tailwindMyConfig from "../../tailwind.my.config";
import moment from "moment";
import { Language } from "../../utils/storage";
import i18n from "../../i18n";
import { getRiepilogo } from "../../network/actions";
import Loader, { ELoader } from "../../components/Loader";
import logo from "./../../assets/images/Logo-Effe.png"
import logo_us from "./../../assets/images/PerfectWellness Logo big-01.png"
import ImgVano from "../../components/ImgVano";
import { ConfigurationType } from "../../redux/reducers/scelta_configurazione";
import { IconDownload } from "../../components/Icon";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function Overview({ ...props }) {
  const [payload, setPayload] = useState<TRiepilogoPayload>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [isInternalView, setIsInternalView] = useState<boolean>(true);
  const [areImgVanoLoaded, setAreImgVanoLoaded] = useState<boolean>(false);

  useEffect(() => {

    let idRiepilogo;
    const path_parts = window.location.pathname.split("/");
    idRiepilogo = !path_parts[path_parts.length - 1] ? path_parts[path_parts.length - 2] : path_parts[path_parts.length - 1]
    console.log({ idRiepilogo }, path_parts)

    setIsLoading(true)
    getRiepilogo(idRiepilogo).then((data: TRiepilogoPayload) => {
      setPayload(data)
      console.log("LANGUAGE", data?.language)
      i18n.changeLanguage(data?.language);
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      setError(true)
    })
  }, [])

  const downloadPDF = async () => {


    try {
      setIsLoading(true)
      const input = document.getElementById("root");
      const canvas = await html2canvas(input!, {
        windowWidth: 1800,
        windowHeight: 1200,
        logging: false,
        allowTaint: false,
        useCORS: true,
        ignoreElements: function (el) {
          if (el.classList.contains("hide-print") || el.classList.contains("img-wizard-container")) {
            return true
          }
          return false
        }, onclone: function (doc, el) {
          console.log(doc, el)
          let container = doc.querySelector('.container');
          container?.removeAttribute('class');
          container?.classList?.add("container-pdf");
          doc.querySelector('.line-through')?.classList?.remove("line-through");
          const imagesVano = doc.querySelectorAll('.img-vano-print');
          [].forEach.call(imagesVano, function (currEl: HTMLElement) {
            currEl.classList.replace("w-1/2", "w-96");
            currEl.classList.replace("h-full", "h-96");
            currEl.classList.add("mt-4");
          });

          const fontsBold = doc.querySelectorAll('.font-bold');
          [].forEach.call(fontsBold, function (currEl: HTMLElement) {
            currEl.classList.replace("font-bold", "font-semibold");
            // currEl.classList.replace("h-full", "h-96");
            // currEl.classList.add("mt-4");
          });

          const titles = doc.querySelectorAll('.text-titleOverview');
          [].forEach.call(titles, function (currEl: HTMLElement) {
            currEl.classList.replace("text-titleOverview", "text-text");
          });
          const titlesSections = doc.querySelectorAll('.text-titleSectionGenerator');
          [].forEach.call(titlesSections, function (currEl: HTMLElement) {
            currEl.classList.replace("text-titleSectionGenerator", "text-titleCard");
          });
          const colData = doc.querySelectorAll('.col-data');
          [].forEach.call(colData, function (currEl: HTMLElement) {
            currEl.classList.add("pr-8");
          });
          const colorGreenLights = doc.querySelectorAll('.text-fluo-effe');
          [].forEach.call(colorGreenLights, function (currEl: HTMLElement) {
            currEl.classList.replace("text-fluo-effe", "text-verde-medioscuro-effe");
          });
          const borderColorGreenLights = doc.querySelectorAll('.border-fluo-effe');
          [].forEach.call(borderColorGreenLights, function (currEl: HTMLElement) {
            currEl.classList.replace("border-fluo-effe", "border-verde-medioscuro-effe");
          });
          const bgColorGreenLights = doc.querySelectorAll('.bg-fluo-effe');
          [].forEach.call(bgColorGreenLights, function (currEl: HTMLElement) {
            currEl.classList.replace("bg-fluo-effe", "bg-verde-medioscuro-effe");
          });
          const textXs = doc.querySelectorAll('.text-xs');
          [].forEach.call(textXs, function (currEl: HTMLElement) {
            currEl.classList.replace("text-xs", "text-m");
            currEl.classList.replace("text-wizard-effe", "text-black-effe");
          });
          const bgSubtotals = doc.querySelectorAll('.text-subtitle-cell');
          [].forEach.call(bgSubtotals, function (currEl: HTMLElement) {
            currEl.classList.remove("text-titleCard")
            currEl.classList.add("text-h5");
          });
          const textGrayTexts = doc.querySelectorAll('.text-subtitle-effe');
          [].forEach.call(textGrayTexts, function (currEl: HTMLElement) {
            currEl.classList.remove("text-subtitle-effe");
          });


          doc.getElementById('form-costi')?.classList?.replace("bg-modal-effe", "bg-modal-effe-light")
        }
      })

      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm', "a4");
      var position = 0;

      var imgData = canvas.toDataURL('image/jpeg', 1);

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, `Page: 1`, "SLOW");
      heightLeft -= pageHeight;

      let indexPage = 1;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, `Page: ${indexPage + 1}`, "SLOW");
        indexPage += 1;
        heightLeft -= pageHeight;
      }
      setIsLoading(false)
      doc.save(`${payload?.link}.pdf`);
    } catch (error) {
      window.print()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (areImgVanoLoaded) {
      if (props.downloadPdf) {
        try {
          downloadPDF();
        } catch (error) {
          console.log(error)
        }
      }
    }
  }, [areImgVanoLoaded])

  const isFeaturedOnRework = () => {
    return Number(payload?.version) >= 3
  }

  const isKitCoibentazioneAddedToQuote = () => payload?.data?.info_tecniche?.add_kit_coibentazione_to_quote === "YES";

  return <div className="overflow-scroll h-full pb-4" id="overview-quote">
    <div className="container">

      {
        isLoading || error ?
          <div>
            <Loader type={ELoader.SECONDARY} error={!!error} disableAction />
            {error && <p className="text-black-effe absolute bottom-12 text-center w-full left-0">{"Not found"}</p>}
          </div>
          :
          <>
            <Row className="w-full">
              <Col md={payload?.customer_info?.riferimento_nome_del_progetto ? 4 : 6} lg={payload?.customer_info?.riferimento_nome_del_progetto ? 4 : 6} className="lg:mb-0 xs:mb-2">
                <div className="flex my-8 justify-center">
                  <img id="logo-wizard" src={logo} className="w-28 " />
                  {payload?.localization === Localization.AMERICA && <img id="logo-wizard" src={logo_us} className="w-32 ml-2 object-contain" />}
                </div>
              </Col>
              <Col md={3} lg={3} className="flex justify-start flex-col lg:mb-0 xs:mb-2 md:py-8 xs:py-2">
                {
                  payload?.customer_info &&

                  <div className="flex flex-col">
                    <p className="xs:text-center md:text-left text-black-effe font-bold text-sm mb-0">{`${props.t("v2.info_personal")}`}</p>
                    <p style={{ fontSize: 14 }} className="xs:text-center md:text-left text-black-effe mb-0">
                      {`${payload?.customer_info?.company_name ?
                        `${capitalizeAllWords(payload?.customer_info?.company_name)},` :
                        `${capitalizeAllWords(payload?.customer_info?.firstname || "")} ${capitalizeAllWords(payload?.customer_info?.lastname || "")},`
                        }`}
                    </p>
                    <p style={{ fontSize: 14 }} className="xs:text-center md:text-left text-black-effe mb-0">
                      {`${capitalizeAllWords(payload?.customer_info?.city)} ${payload?.customer_info?.country ? `- ${capitalizeAllWords(payload?.customer_info?.country)}` : ""}`}
                    </p>
                  </div>
                }
              </Col>
              {
                payload?.customer_info?.riferimento_nome_del_progetto &&

                <Col md={3} lg={3} className="flex justify-start flex-col lg:mb-0 xs:mb-2 md:py-8 xs:py-2">
                  <div className="flex flex-col">
                    <p className="xs:text-center md:text-left text-black-effe font-bold text-sm mb-0">{`${props.t("v2.preventivo_n")}`}</p>
                    <p style={{ fontSize: 14 }} className="xs:text-center md:text-left text-black-effe mb-0">{`${payload?.customer_info?.riferimento_nome_del_progetto}`}</p>
                  </div>
                </Col>
              }
              <Col md={2} lg={2} className="flex justify-start flex-col lg:mb-0 xs:mb-2 md:py-8 xs:py-2">
                <div className="flex flex-col">
                  <p className="xs:text-center md:text-left text-black-effe font-bold text-sm mb-0">{`${props.t("v2.data_creazione")}`}</p>
                  <p style={{ fontSize: 14 }} className="xs:text-center md:text-left text-black-effe mb-0">{`${moment(payload?.timestamp).format((payload?.language == Language.EN || payload?.localization === Localization.AMERICA) ? "MM/DD/YYYY" : "DD/MM/YYYY")}`}</p>
                </div>
              </Col>
            </Row>

            <Row className="w-full box-col-data">
              <Col xs={12} sm={12} md={6} lg={6} className="col-data">

                <div id="content-overview" className="overflow-scroll mt-4">

                  {
                    payload?.type_configuration !== ConfigurationType.PORTE_VETRATE &&
                    <div className="mb-6">
                      <RiepilogoGridItem
                        isQuote
                        title={payload?.data?.generatore?.name || ""}
                        disclaimer={payload?.data?.info_tecniche?.kit_coibentazione === "NO" ? props.t("v8.disclaimer_no_kit_coibentazione") : null}
                        subtitle={`${payload?.data?.generatore?.subtotal} ${payload?.valute}`}
                        descriptionItems={(payload?.data?.generatore?.model_items || [])?.map((it, itIndex) => {
                          return (
                            <RiepilogoItem
                              key={it?.name}
                              className={itIndex === 0 ? "mt-4" : "mt-2"}
                              title={it?.name}
                              bold={isFeaturedOnRework()}
                              separator={itIndex > 1}
                              units={it?.units || 1}
                              image={itIndex === 0 && isFeaturedOnRework() ? payload?.data?.generatore?.images?.[0] : ""}
                              priceUnit={it?.price}
                              isMultipleUnits={(it?.units || 1) > 1}
                              valute={payload?.valute}
                              classNameImage="h-full object-contain img-vano-print"
                            />
                          )
                        }).concat(
                          payload?.localization === Localization.AMERICA && !isKitCoibentazioneAddedToQuote() ?
                            [
                              <p style={{ paddingLeft: 30 }} className="mb-0 pt-2 w-full text-xs text-wizard-effe text-left" >
                                {"We recommend to insulate your walls before the waterproofing. Several different brands have solutions for Steam Shower/Steam Room. Check the best solution with your tile contractor.The steam generator has been sized assuming that you’re installing a thermal insulation on your walls"}
                              </p>
                            ]
                            : []
                        )
                        }
                      // images={payload?.data?.generatore?.images||[]}
                      />
                    </div>
                  }

                  {
                    (payload?.data?.optionals?.optionals || []).length > 0 &&
                    <div className="mb-6">
                      <RiepilogoGridItem
                        isQuote
                        title={props.t("riepilogo.optional")}
                        subtitle={`${payload?.data?.optionals?.subtotal} ${payload?.valute}`}
                        // images={payload?.data?.optionals?.images || []}
                        descriptionItems={payload?.data?.optionals?.optionals?.map((opt, indexIt) => {
                          return (
                            <RiepilogoItem
                              key={opt?.name}
                              title={opt?.name}
                              className={`py-3 ${indexIt === 0 && "mt-2"}`}
                              bold={isFeaturedOnRework()}
                              subItems={(opt.subitems || []).map((z, indexOptList) => {
                                return (
                                  {
                                    title: z?.name,
                                    price: z.price,
                                    valute: payload?.valute
                                  }
                                )
                              })}
                              priceUnit={opt?.price}
                              valute={payload?.valute}
                            />
                          )
                        })} />
                    </div>
                  }

                  {
                    (payload?.data?.porte_vetrate?.model_items?.length || payload?.data?.porte_vetrate?.custom_items?.length) ?
                      <div className="mb-6">
                        <RiepilogoGridItem
                          isQuote
                          title={props.t("riepilogo.porte_vetrate")}
                          subtitle={`${payload?.data?.porte_vetrate?.subtotal} ${payload?.valute}`}
                          // images={payload?.data?.porte_vetrate?.images || []}
                          descriptionItems={
                            payload?.data?.porte_vetrate?.model_items?.length ?
                              ([
                                payload?.data?.porte_vetrate?.model_items?.map((pv, indexPv) => {
                                  return (
                                    <RiepilogoItem
                                      key={pv.name}
                                      title={pv.name}
                                      separator={indexPv > 0}
                                      className={`${indexPv === 0 && "mt-3"}`}
                                      bold={isFeaturedOnRework() && (indexPv === 0 || (payload?.data?.porte_vetrate?.numParetiVetrata === 2 && indexPv === 1))}
                                      classNameMainItem={"py-3"}
                                      priceUnit={pv.price}
                                      valute={payload?.valute}
                                    />
                                  )
                                })
                              ])
                              :
                              (payload?.data?.porte_vetrate?.custom_items?.length ?
                                [
                                  payload?.data?.porte_vetrate?.custom_items.map((item, indexPv) => {
                                    return (
                                      <RiepilogoItem
                                        className={`${indexPv === 0 && "mt-3"}`}
                                        classNameMainItem={"py-3"}
                                        key={`${indexPv}${item.title}`}
                                        bold
                                        title={item.title}
                                        subItems={item.subItems}
                                      />
                                    )
                                  })
                                ]
                                :
                                [])
                          } />
                      </div>
                      : null
                  }

                  {//La custom ha i prezzi solo in euro
                    (payload?.data?.panche?.panche || []).length > 0 &&
                    <div className="mb-6">
                      <RiepilogoGridItem
                        isQuote
                        title={props.t("riepilogo.panche")}
                        subtitle={`${payload?.data?.panche?.subtotal} ${payload?.valute}`}
                        // images={payload?.data?.panche?.images || []}
                        descriptionItems={
                          payload?.data?.panche?.panche.map((c, indexPan) => {
                            return (
                              <RiepilogoItem
                                key={c?.name}
                                title={c?.name}
                                units={c?.units}
                                bold={isFeaturedOnRework()}
                                separator={indexPan > 0}
                                className={`${indexPan === 0 && "mt-3"}`}
                                classNameMainItem={"py-3"}
                                isMultipleUnits={(c?.units || 1) > 1}
                                priceUnit={c?.price}
                                valute={payload?.valute}
                              />
                            )
                          })
                        } />
                    </div>
                  }


                  {
                    (payload?.data?.accessori?.accessori || []).length > 0 &&
                    <div className="mb-6">
                      <RiepilogoGridItem
                        isQuote
                        title={props.t("riepilogo.accessori")}
                        subtitle={`${payload?.data?.accessori?.subtotal} ${payload?.valute}`}
                        // images={payload?.data?.accessori?.images || []}
                        descriptionItems={payload?.data?.accessori?.accessori?.map((acc, indexIt) => {
                          return (
                            <RiepilogoItem
                              key={acc?.name}
                              title={acc?.name}
                              className={`py-3 ${indexIt === 0 && "mt-2"}`}
                              bold={isFeaturedOnRework()}
                              subItems={(acc?.subitems || []).map((z, indexOptList) => {
                                return (
                                  {
                                    title: `${z?.name}${z.label_quantity ? ` (${acc.quantity} ${z.label_quantity})` : ""}`,
                                    price: z.price,
                                    valute: payload?.valute,
                                    quantity: z.multipli_for_quantity_sku && (acc.quantity || 1) > 1 ? acc.quantity : 1,
                                    label_quantity: z.multipli_for_quantity_sku && z.label_quantity && (acc.quantity || 1) > 1 ? z.label_quantity : ""
                                  }
                                )
                              })}
                              priceUnit={acc?.price}
                              valute={payload?.valute}
                            />
                          )
                        })} />
                    </div>
                  }
                </div>


              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="col-data">
                {
                  isFeaturedOnRework() ?

                    <ImgVano
                      isInternal={payload?.type_configuration !== ConfigurationType.PORTE_VETRATE && isInternalView}
                      mainInternalImg={payload?.mainInternalImg || ""}
                      layerInternalImgs={payload?.layerInternalImgs || []}
                      mainExternalImg={payload?.mainExternalImg || ""}
                      layerExternalImgs={payload?.layerExternalImgs || []}
                      showToggle={payload?.type_configuration !== ConfigurationType.PORTE_VETRATE}
                      onToggleChanged={(isChecked: boolean) => {
                        setIsInternalView(isChecked ? false : true)
                      }}
                      onAllImagesLoaded={() => {
                        setAreImgVanoLoaded(true)
                      }}
                      useAnonymusCORSImg
                    />
                    : null
                }
                {
                  payload?.type_configuration !== ConfigurationType.PORTE_VETRATE ?
                    <div>
                      <H3
                        title={props.t("riepilogo.info_tecniche.titolo")}
                        className="text-left text-black-effe m-title mt-12 px-0 mx-0"
                      />

                      <div className="mt-8">
                        <RiepilogoSimpleGridItem
                          title={props.t("v2.dim_box_doccia")}
                          description={payload?.data?.info_tecniche?.dimensione_box || ""}
                          className={`py-4 border-b-half border-solid border-divider`}
                        />
                        <RiepilogoSimpleGridItem
                          title={props.t("v2.volume")}
                          description={payload?.data?.info_tecniche?.volume || ""}
                          className={`py-4 border-b-half border-solid border-divider`}
                        />
                        {
                          payload?.localization === Localization.AMERICA ?
                            <>
                              <RiepilogoSimpleGridItem
                                title={props.t("vanodoccia_struttura.rivestimento.title")}
                                description={payload?.data?.info_tecniche?.rivestimento || ""}
                                className={`py-4 border-b-half border-solid border-divider`}
                              />
                              <RiepilogoSimpleGridItem
                                title={props.t("vanodoccia_struttura.kit_coibentazione.modal.title")}
                                description={payload?.data?.info_tecniche?.kit_coibentazione || ""}
                                className={`py-4 border-b-half border-solid border-divider`}
                              />
                              <RiepilogoSimpleGridItem
                                title={props.t("voltaggio.titolo")}
                                description={payload?.data?.info_tecniche?.volt || ""}
                                className={`py-4`}
                              />
                            </>
                            :
                            <>
                              <RiepilogoSimpleGridItem
                                title={props.t("vanodoccia_struttura.struttura.title")}
                                description={payload?.data?.info_tecniche?.struttura || ""}
                                className={`py-4`}
                              />
                            </>
                        }

                        {
                          payload?.data?.porte_vetrate?.custom_extra_info?.length ?
                            <RiepilogoSimpleGridItem
                              title={props.t("porte_vetrate.titolo")}
                              description={payload?.data?.porte_vetrate?.custom_extra_info?.filter(it => !!it).join(" - ") || ""}
                              className={`py-4`}
                            />
                            : null
                        }

                      </div>

                    </div>
                    : (payload?.data?.porte_vetrate?.custom_extra_info?.length) ?
                      <RiepilogoSimpleGridItem
                        title={props.t("porte_vetrate.titolo")}
                        description={payload?.data?.porte_vetrate?.custom_extra_info?.filter(it => !!it).join(" - ") || ""}
                        className={`py-4`}
                      />
                      : null

                }
                <div>

                  <div id="form-costi" className="bg-modal-effe mt-3">
                    <div className="p-12">
                      <div className="mb-12">
                        <p className="mb-0 text-center uppercase text-fluo-effe font-bold text-titlePrice">{props.t("v3.riepilogo_costi")}</p>
                        <hr className="hide-print h-px w-1/5 mx-auto bg-fluo-effe border-0" />
                      </div>

                      {
                        (payload?.localization !== Localization.AMERICA && payload?.percentual_discount) ?
                          <Row className="py-2 border-b border-fluo-effe border-dotted">
                            <Col xs={8} className="px-0 mx-0">
                              <p className="mb-0 font-light text-left text-fluo-effe text-text">{props.t("riepilogo.tot_prezzo_listino")}</p>
                            </Col>
                            <Col xs={4} className="px-0 mx-0">
                              <p className={`mb-0 font-regular text-right text-fluo-effe text-text ${(payload?.percentual_discount) && "line-through"}`}>{`${formatPriceForContext(payload?.price)} ${payload?.valute}`}</p>
                            </Col>
                          </Row>
                          : null
                      }
                      {
                        (payload?.localization !== Localization.AMERICA && payload?.percentual_discount) ?
                          <Row className="py-2 border-b border-fluo-effe border-solid">
                            <Col xs={8} className="px-0 mx-0">
                              <p className="mb-0 font-light text-left text-fluo-effe text-text">{props.t("riepilogo.perc_sconto")}</p>
                            </Col>
                            <Col xs={4} className=" px-0 mx-0 text-right">
                              <p className="mb-0 font-light text-right text-fluo-effe text-text">{`${payload?.percentual_discount} %`}</p>
                            </Col>
                          </Row>
                          : null
                      }
                      <Row className="py-2">
                        <Col xs={8} className="px-0 mx-0">
                          <p className="mb-0 capitalize font-bold text-left text-fluo-effe text-text">{payload?.localization !== Localization.AMERICA ? `${capitalize(props.t("riepilogo.prezzo_finale"))}` : "Total suggested retail price"}</p>
                          <p className="mb-0 font-light text-left text-fluo-effe text-textpopup">{`${props.t("v2.iva_trasporto_esclusi")}`}</p>
                          {payload?.customer_info?.commenti_per_l_acquirente && <p className="mb-0 mt-3 text-m font-light text-left text-fluo-effe">{`${props.t("general.note")}:\n${payload?.customer_info?.commenti_per_l_acquirente}`}</p>}

                        </Col>
                        <Col xs={4} className="px-0 mx-0">
                          <p className="mb-0 font-bold text-right text-fluo-effe text-text">{`${formatPriceForContext(payload?.price_discounted)} ${payload?.valute}`}</p>
                        </Col>
                      </Row>

                      <button style={{ display: "flex", flexDirection: "column" }} className={`hide-print justify-center mx-auto pt-4 `}
                        onClick={async () => {
                          downloadPDF();
                        }}>
                        <IconDownload fill={tailwindMyConfig.theme.extend.colors["fluo-effe"]} />
                        <span className={`mt-4 font-bold text-fluo-effe text-titleSectionGenerator`}>{props.t("v5.download_pdf")}</span>
                      </button>


                    </div>
                  </div>


                </div>
              </Col>
            </Row>
          </>
      }
    </div>
  </div>;
}

export default withTranslation()(Overview);