import Action, { ActionTypes } from "../actionTypes";
import { TFeaturedMediaType } from "./media";
import { TListinoItemAcfType, TListinoItemContentType, TListinoItemType } from "./porte_vetrate";

//Standard
export type TPancaType = {
    id: string;
    slug: string;
    title: {
      rendered: string
    };
    content: {
      rendered: string
    };
    _embedded: TFeaturedMediaType;
    description: string; //TODO: Manca campo
    acf: {
      max_quantity_optional: number;
      item_relationship: TListinoItemType[];
    };
}
export type TPancaQuantity = {
    panca: TPancaType;
    quantity: number|undefined;
    item_listino: TListinoItemContentType|undefined;
}
//Su misura
export type TPancaCustomType = {
    id: string;
    slug: string;
    name: string;
    img: string;
    descrizione: string;
}
export type TPancaCustomConfigurationType = {
    id: string;
    name: string;
    price_euro:TListinoItemAcfType, //only for eu
    img: string;
    descrizione: string;
    dimensioni: string;
}
export type TPancaCustomConfigurationQuantity = {
    panca_custom: TPancaCustomConfigurationType;
    quantity: number;
}
export type TPancaCustomChoice = {
    modello: TPancaCustomType;
    configurations: TPancaCustomConfigurationQuantity[];
}


//Store
export type TPancheStoreState = {
    panche?: TPancaQuantity[]; 
    panca_custom?: TPancaCustomChoice;
}
const initialState: TPancheStoreState = {
    panche: [],
    panca_custom: undefined
};


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetPanca: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
