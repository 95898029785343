import { FC } from "react";
import { Text } from "../../Typography";
import { Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { IconEdit } from "../../Icon";
import tailwindMyConfig from "../../../tailwind.my.config";

interface IRiepilogoSimpleGridItem{
  title: string;
  description: string;
  maxCharDescription?: number;
  onClick?: any;
  className?: string;
  style?:any;
  t?: any;
}

const RiepilogoSimpleGridItem: FC<IRiepilogoSimpleGridItem> = ({ title, description, maxCharDescription, className, onClick, style, ...props }: IRiepilogoSimpleGridItem) => {
    return (
    <div style={{ ...(style || {})}} className={`flex  items-center justify-between ${className}`}>
        <Col xs={5}>
            <p className={`text-black-effe mb-0 font-light text-titleSectionGenerator`}>{title}</p>
        </Col>
        <Col xs={5}>
            <Text title={maxCharDescription ? description.substr(0,maxCharDescription-3)+"..." : description} className={`text-black-effe mb-0 font-regular text-titleSectionGenerator`}/> 
        </Col>
        <Col xs={2} className="flex items-center">
            {
                onClick &&
                <button onClick={onClick}>
                        <IconEdit fill={tailwindMyConfig.theme.extend.colors["fluo-effe"]}
                        style={{width:25,height:25}}/>
                </button>
            }
        </Col>

        
    </div>
  );
};
export default withTranslation()(RiepilogoSimpleGridItem);
