import { FC } from "react";

interface ITitle extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  title: string;
}

const Text: FC<ITitle> = ({ title, ...props }: ITitle) => {
  return (
    <p {...props} className={`${props.className}`}>
      {title}
    </p>
  );
};
export default Text;
