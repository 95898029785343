import { FC } from "react";
import loader from "./../../assets/lottie/loader.json"
import loader_small from "./../../assets/lottie/loader_small.json"

import logo from "./../../assets/images/logo_green.png";
import Lottie from 'react-lottie';

interface IInnerLoader{
  small?: boolean;
  className?: string;
}

const InnerLoader: FC<IInnerLoader> = ({ small, className, ...props }: IInnerLoader) => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: small ? loader_small : loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const dimension = small ? 40 : (window?.innerWidth < 500 ? (window?.innerWidth*0.8) : 500)
  return (
    <div className={`relative ${small ? "inline-block" : "flex justify-center items-center"} ${className}`}>
      <div className="">
        <Lottie options={defaultOptions} height={dimension} width={dimension}/>
      </div>
      {!small && <img src={logo} className={`absolute w-48`} style={{ maxWidth: 135 }} />}
    </div>
  );
}

export default InnerLoader;
