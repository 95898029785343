import { getCmFromFtAndInch, getUnitOfMeasure, UnitOfMeasure } from "../../utils/utils";
import Action, { ActionTypes } from "../actionTypes";
import { TBetterFeaturedMediaType, TFeaturedMediaType } from "./media";
import { TOptional, TOptionalDetailType, TOptionalQuantity } from "./optional";
import { TListinoItemType } from "./porte_vetrate";
import { TVanoDocciaStoreState } from "./vano_doccia";

export const getVolume = (vano_doccia_state:TVanoDocciaStoreState) => {
    const isCmUnit = getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI;
    const cm_3 = (isCmUnit ? vano_doccia_state.profondita||0 : getCmFromFtAndInch(vano_doccia_state.profondita,vano_doccia_state.profondita_inc) ) * 
    (isCmUnit ? vano_doccia_state.larghezza||0 : getCmFromFtAndInch(vano_doccia_state.larghezza,vano_doccia_state.larghezza_inc) ) *
    (isCmUnit ? vano_doccia_state.altezza||0 : getCmFromFtAndInch(vano_doccia_state.altezza,vano_doccia_state.altezza_inc) ) 
    // console.log("PRF",vano_doccia_state.profondita,vano_doccia_state.profondita_inc,getCmFromFtAndInch(vano_doccia_state.profondita,vano_doccia_state.profondita_inc))
    // console.log("LRG",vano_doccia_state.larghezza,vano_doccia_state.larghezza_inc,getCmFromFtAndInch(vano_doccia_state.larghezza,vano_doccia_state.larghezza_inc))
    // console.log("ALT",vano_doccia_state.altezza,vano_doccia_state.altezza_inc,getCmFromFtAndInch(vano_doccia_state.altezza,vano_doccia_state.altezza_inc))
    const room_volume = cm_3 * Math.pow(10, -6); // to m3
    return Number(room_volume.toFixed(2))
}

export const filterModels = (
  models: TGeneratoreModelDetailType[],
  is_kit_coibentazione_included: boolean,
  vano_doccia_state:TVanoDocciaStoreState
): TGeneratoreModelDetailType[] => {
  const room_volume = getVolume(vano_doccia_state) 

  console.log(`>> Volume ${room_volume}m^3
  Kit di coibentazione ${is_kit_coibentazione_included}
  Sizes: ${JSON.stringify(vano_doccia_state)}`);

  const toNumber = (s: string): number => parseFloat(s);
  return models.map((m: TGeneratoreModelDetailType) => {
    console.log(`Modello ${m.title.rendered}: MinMetri^3 (conKit) ${m.acf.min_metri_cubi_potenza} | MaxMetri^3 (conKit) ${m.acf.metri_cubi_potenza_max}
    MinMetri^3 (noKit) ${m.acf.min_metri_cubi_no_kit_di_coibentazione} | MaxMetri^3 (noKit) ${m.acf.max_metri_cubi_no_kit_di_coibentazione}`);
    
    return ({
      ...m,
      is_visible: (is_kit_coibentazione_included &&
        (m.acf.min_metri_cubi_potenza === undefined || room_volume >= toNumber(m.acf.min_metri_cubi_potenza) || toNumber(m.acf.min_metri_cubi_potenza) > room_volume) &&
        (m.acf.metri_cubi_potenza_max === undefined || room_volume <= toNumber(m.acf.metri_cubi_potenza_max)))
           ||
      (!is_kit_coibentazione_included &&
        (m.acf.min_metri_cubi_no_kit_di_coibentazione === undefined || room_volume >= toNumber(m.acf.min_metri_cubi_no_kit_di_coibentazione) || toNumber(m.acf.min_metri_cubi_no_kit_di_coibentazione) > room_volume) &&
        (m.acf.max_metri_cubi_no_kit_di_coibentazione === undefined || room_volume <= toNumber(m.acf.max_metri_cubi_no_kit_di_coibentazione)))
    }) 
      
  });
};

export type TGalleryItem = {
  ["image-slider-gallery"]: string;
};


export enum EDocumentType{
  URL = "url",
  FILE  = "file"
}
export type TDocument = {
  resource_url: string;
  document_title: string;
  input_type: EDocumentType
}

export type TGeneratoreType = {
  id: string;
  slug: string;
  title: {
    rendered: string;
  };
  _embedded: TFeaturedMediaType;
  better_featured_image: TBetterFeaturedMediaType;
  acf: {
    accordion_group: {
      models_relationship: TGeneratoreModelType[];
    };
    slider_gallery: TGalleryItem[];
    optional_products_group: {
      related_optional: TOptional[]
    },
    documents_related_collection_group: TDocument[],
    max_volume_generatore_no_kit: string;
    max_volume_generatore_con_kit: string;
    steam_image: {
      url:string
    };
    hide_config?: boolean | undefined;
  };
  
};

export type TDescriptionBlockType = {
  titolo_blocco_descrizione: string;
  content_blocco_descrizione: string;
};

export type TGeneratoreModelType = {
  ID: string;
  post_title: string;
  post_name: string;
  potenza: string; //TODO: manca campo nel modello
  dimensione: string; //TODO: manca campo nel modello
  dimensione_controcassa: string; //TODO: manca campo nel modello
  materiali: string; //TODO: manca campo nel modello
  caratteristiche: string; //TODO: manca campo nel modello
};

export type TGeneratoreModelDetailType = {
  id: string;
  title: {
    rendered: string;
  };
  slug: string;
  acf: {
    gallery_modello: {
      url: string;
    }[];
    description_block: TDescriptionBlockType[];
    metri_cubi_potenza_max: string | undefined;
    min_metri_cubi_potenza: string | undefined;
    max_metri_cubi_no_kit_di_coibentazione: string | undefined;
    min_metri_cubi_no_kit_di_coibentazione: string | undefined;
    more_details: {
      power_model: string;
      size_Y: string;
      size_Z: string;
      size_x: string;
    };
    item_related: TListinoItemType[]
  };
  is_visible: any;
};

export type TGeneratoreModelStoreState = {
  generatore_product?: TGeneratoreType; 
  generatore_model?: TGeneratoreModelDetailType;
  optionalsForModel?: TOptionalQuantity[];
  list_generatori_interni?: TGeneratoreType[];
};
const initialState: TGeneratoreModelStoreState = {
  generatore_product: undefined,
  generatore_model: undefined,
  list_generatori_interni: [],
  optionalsForModel: []
};

export default function (state = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetModel: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
