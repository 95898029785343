import { FC, useEffect, useRef, useState, RefObject } from "react";
import { withTranslation } from "react-i18next";
import { ImgType, ZINDEX_VANO_ESTERNO } from "../../controller/Configuratore";
import { EInternalExternalImage } from "../../redux/reducers/general";

interface IImgVano {
  className?: string
  isInternal: boolean;
  mainInternalImg: string;
  layerInternalImgs: ImgType[];
  mainExternalImg: string;
  layerExternalImgs: ImgType[];
  showToggle: boolean;
  onToggleChanged: (e: boolean) => void;
  onAllImagesLoaded?: (e: boolean) => void;
  styleImg?: any;
  t?: any;
  useAnonymusCORSImg?: boolean;
}

export const useOnLoadImages = (ref: RefObject<HTMLElement>) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const updateStatus = (images: HTMLImageElement[]) => {
      setStatus(
        images.map((image) => image.complete).every((item) => item === true)
      );
    };

    if (!ref?.current) return;

    const imagesLoaded = Array.from(ref.current.querySelectorAll("img"));

    if (imagesLoaded.length === 0) {
      setStatus(true);
      return;
    }

    imagesLoaded.forEach((image) => {
      image.addEventListener("load", () => updateStatus(imagesLoaded), {
        once: true
      });
      image.addEventListener("error", () => updateStatus(imagesLoaded), {
        once: true
      });
    });

    return;
  }, [ref]);

  return status;
};

const ImgVano: FC<IImgVano> = ({ className, isInternal, showToggle,
  mainInternalImg, layerInternalImgs,
  mainExternalImg, layerExternalImgs,
  useAnonymusCORSImg,
  styleImg, onToggleChanged, onAllImagesLoaded, ...props }: IImgVano) => {
  const clsImg = "xs:pb-8 lg:pb-0"
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  useEffect(() => {
    if (imagesLoaded) {
      console.log(`All  images are loaded from Vano`)
      onAllImagesLoaded && onAllImagesLoaded(true)
    }
  }, [imagesLoaded])

  const timestamp = new Date().getTime();

  return (
    <div
      ref={wrapperRef} className={`relative ${className} img-wizard-container type-${isInternal ? EInternalExternalImage.INTERNAL : EInternalExternalImage.EXTERNAL} flex lg:flex-1 items-end justify-end relative xs:pb-4 lg:pb-0`}>
      <img
        src={useAnonymusCORSImg ? (isInternal ? `${mainInternalImg}?v=${timestamp}` : `${mainExternalImg}?v=${timestamp}`) : (isInternal ? mainInternalImg : mainExternalImg)}
        className={`h-full w-full ${clsImg}`}
        crossOrigin={useAnonymusCORSImg ? "anonymous" : undefined}
        style={{ objectFit: "contain", ...styleImg, zIndex: (!isInternal && showToggle) ? ZINDEX_VANO_ESTERNO : 1 }}
      />
      {(isInternal ? layerInternalImgs : layerExternalImgs).map((pathImg: ImgType, index: number) => {
        return (
          <img key={index}
            crossOrigin={useAnonymusCORSImg ? "anonymous" : undefined}
            src={useAnonymusCORSImg ? `${pathImg.src}?v=${timestamp}` : pathImg.src}
            className={`h-full absolute left-0 top-0 w-full object-contain ${clsImg}`} style={{ zIndex: pathImg.zIndex !== undefined ? pathImg.zIndex : (index + 1) }} />
        )
      })}

      <div>
        {/* {width >= WIDTH_DESKTOP && <span key={"view"} style={{position:"absolute", bottom: 50, fontSize:12, width:"100%", fontWeight:"bold", left:0, textAlign:"left"}} className="font-bold text-black-effe pr-3">{props.t("v2.select_View")}</span>} */}
        <div className={`img-box-main flex flex-col ${!isInternal ? "ex-fade-in ex-fade-out" : "in-fade-in in-fade-out"}  
          absolute w-full flex justify-center xs:items-center lg:items-start xs:bottom-0 lg:bottom-10`} style={{ left: 0, right: 0, margin: "auto", zIndex: ZINDEX_VANO_ESTERNO + 10 }}>
          {
            showToggle &&
            (
              <div className="flex items-center hide-print">
                <span className={`text-xs  ${isInternal ? "text-fluo-effe" : "text-modal"}`}>{props.t("v2.interno")}</span>
                <span className={`text-xs text-modal mx-1`}>{" / "}</span>
                <span className={`text-xs ${!isInternal ? "text-fluo-effe" : "text-modal"}`}>{props.t("v2.esterno")}</span>
                <label className="switch mx-2">
                  <input type="checkbox" checked={!isInternal} onChange={(e: any) => {
                    onToggleChanged(e.target.checked)
                  }} />
                  <span className="slider round"></span>
                </label>
              </div>
            )
          }

          <p className="mb-0 pt-2 w-full text-xs text-wizard-effe text-left" style={{ zIndex: 99999999 }}>
            {`${props.t("v4.vano_indicativo")}`}
          </p>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ImgVano);
