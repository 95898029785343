import { FC } from "react";
import { Text } from "..";

interface ISubTitle extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  title: string;
}

const Subtitle: FC<ISubTitle> = ({ title, ...props }: ISubTitle) => {
  return (
   <Text title={title} className="m-subtitle underline text-center text-subtitle-effe"/>
  );
};

export default Subtitle;
