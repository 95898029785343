import Action, { ActionTypes } from "../actionTypes";

export type TVetrateStoreState = {
    quantita?: number;
}
const initialState: TVetrateStoreState = {
  quantita: undefined
};


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionVetrateSetData: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
