import { FC, useState } from "react";
import { motion } from "framer-motion";
interface IIMage {
  img: string;
  imgStyle?: any;
  classNameImage?:string;
  classNameWrapper?:string;
}

const Image: FC<IIMage> = ({ img, imgStyle, classNameImage, classNameWrapper, ...props }: IIMage) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [pulsing, setPulsing] = useState(true);

  const imageLoaded = () => {
    setImageLoading(false);
    setTimeout(() => setPulsing(false), 600);
  };

  return (
    <div
      className={`${pulsing ? "pulse" : ""} loadable ${classNameWrapper}`}
      style={{ background: pulsing ? "#ccc" : "transparent" }}
    >
      <motion.img
        initial={{ height: "16rem", opacity: 0 }}
        animate={{
          height: imageLoading ? "16rem" : "auto",
          opacity: imageLoading ? 0 : 1,
        }}
        transition={{ opacity: { delay: 0.5, duration: 0.4 } }}
        onLoad={imageLoaded}
        src={img}
        style={imgStyle ? imgStyle : {}}
        className={classNameImage}
      />
    </div>
  );
};

export default Image;
