import { FC } from "react";
import { motion } from "framer-motion";
interface IMsgError {
  message?:string;
  className?: string;
}

const MsgError: FC<IMsgError> = ({ message, className, ...props }: IMsgError) => {
  return (
    <div className={`${className}`}>
        <p className="text-warning-effe font-regular text-titleCard">{message}</p>
    </div>
  );
};

export default MsgError;
