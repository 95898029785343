import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector, useStore } from "react-redux";
import { StepConfiguratore } from "../..";
import SelectableDetailGridItem from "../../../../components/GridItem/SelectableDetailGridItem";
import SelectableGridItem from "../../../../components/GridItem/SelectableGridItem";
import MsgError from "../../../../components/MsgError";
import { H3 } from "../../../../components/Typography";
import { actSceltaConfigurazione } from "../../../../redux/actions";
import { TGeneral, TGeneralStore } from "../../../../redux/reducers";
import { ConfigurationType, TSceltaConfigurazioneStoreState } from "../../../../redux/reducers/scelta_configurazione";
import { PLACEHOLDER_IMAGE } from "../../../../utils/consts";

function SceltaConfigurazione({...props}) {
    const dispatch = useDispatch();
    const store: TGeneralStore = useStore();
    const scelta_configurazione: TSceltaConfigurazioneStoreState= useSelector(
        (state: TGeneral) => state.scelta_configurazione
      );
  return (
    <>
        <H3
          title={props.t("scelta_configurazione.titolo")}
          className="text-left text-black-effe m-title"
        />
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} className="">
            <SelectableGridItem
                key={"standard"}
                title={props.t("scelta_configurazione.type_standard")}
                titleAction={props.t("general.seleziona")}
                isSelected={scelta_configurazione.type===ConfigurationType.STANDARD}
                img={"https://s3.eu-central-1.amazonaws.com/steam.effe.it/configurazione-standard.svg"}
                imgStyle={{maxHeight:170}} 
                onClick={()=>{
                        dispatch(actSceltaConfigurazione({
                            type: scelta_configurazione.type===ConfigurationType.STANDARD ? undefined : ConfigurationType.STANDARD
                        }))
                }}
            />
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} className="">
            <SelectableGridItem
                key={"porte_vetrate"}
                title={props.t("scelta_configurazione.type_porte_vetrate")}
                titleAction={props.t("general.seleziona")}
                isSelected={scelta_configurazione.type===ConfigurationType.PORTE_VETRATE}
                img={"https://s3.eu-central-1.amazonaws.com/steam.effe.it/configurazione-porte.svg"}
                imgStyle={{maxHeight:170}} 
                onClick={()=>{
                        dispatch(actSceltaConfigurazione({
                            type: scelta_configurazione.type===ConfigurationType.PORTE_VETRATE ? undefined : ConfigurationType.PORTE_VETRATE
                        }))
                }}
            />
        </Col>
      </Row>
      {
            store.getState()?.general?.stepError?.step===StepConfiguratore.SceltaConfigurazione &&
            <Row>
            <Col className="mt-8">
                <MsgError message={store.getState()?.general?.stepError?.msg}/>
            </Col>
            </Row>
        }
    </>
  );
}


export default connect((state:TGeneral, ownProps) => ({
    general: state.general,
    scelta_configurazione: state.scelta_configurazione
  }), {  })(
    withTranslation()(SceltaConfigurazione)
  );
  
