import { FC } from "react";
import { withTranslation } from "react-i18next";
import { isMobile } from "../../../utils/utils";

interface IFormButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  isDisabled?: boolean;
  onClick: any;
  icon?: any;
  t?: any;
}

const FormButton: FC<IFormButton> = ({ title, isDisabled, onClick, icon, t, ...props }: IFormButton) => {
  return (
    <button
            className={`configuratore-btn ${props.className} wizard-btn relative focus:outline-none rounded border-black-effe
            hover:border-fluo-effe hover:bg-fluo-effe hover:text-black-effe 
            p-2 xs:w-auto sm:w-full ${
                isDisabled ? "disabled cursor-not-allowed" :"active"
            }`}
            style={{ borderWidth: 1 }}
            onClick={() => {
                (!isDisabled && onClick) && onClick();
            }}
          >
            <span
              className="uppercase text-black-effe btn-title"
              style={{ fontSize: 14 }}
            >
              {title}
            </span>
            {icon ? icon : null}
          </button>
  );
};
export default withTranslation()(FormButton);
