import { Form, Spin } from "antd";
import { Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3, Text } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import { TGeneral, TGeneralStore } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { actGeneral, actGeneratoreSetAccessori } from "../../../../redux/actions";
import Modal, { ModalSize } from "../../../../components/Modal";
import { useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import SelectableDetailGridItem from "../../../../components/GridItem/SelectableDetailGridItem";
import { TAccessorio, TAccessoriStoreState } from "../../../../redux/reducers/accessori";
import { HEIGHT_SCROLL, LIMIT, PLACEHOLDER_IMAGE, SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED, SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED } from "../../../../utils/consts";
import { useEffect } from "react";
import { getAccessori } from "../../../../network/actions";
import { TFeaturedMediaItemType } from "../../../../redux/reducers/media";
import { getIdLightHammam, getIdModuleLightHammam } from "../../../../utils/utils";

function Accessori({ ...props }) {
  const store: TGeneralStore = useStore();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const accessori: TAccessoriStoreState = useSelector(
    (state: TGeneral) => state.accessori
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //InfiniteScroll
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [listAccessori, setListAccessori] = useState<TAccessorio[]>([]);  

  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});

  const [accessoriMap, setAccessoriMap] = useState<any>({});

  const openModal = (title: string, content: any, size: any) => {
    setModalData({ title, content, size });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
  };

  useEffect(()=>{
    getAccessoriFromAPI()
  },[])

  const getAccessoriFromAPI = async () => {
    try {
      setIsLoading(true)
      const data: any = await getAccessori(offset, LIMIT)
      setListAccessori(data)
      setOffset(offset+LIMIT)
      setHasMore(data?.length>0 && data?.length%LIMIT===0)
      setIsLoading(false)
    } catch (err) {
      //TODO: Alert
      setIsLoading(false)
    }
  }

  return (
    <>
        <H3
            title={props.t("accessori.titolo")}
            className="text-left text-black-effe m-title"
            isLoading={isLoading}
        />
        <>
          {listAccessori.concat(
            (store.getState()?.panche?.panca_custom?.modello?.id === SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED || 
            store.getState()?.panche?.panca_custom?.modello?.id === SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED) ? 
            [
              {
                id: "mood-light-hammam",
                title:{
                  rendered:"Mood light hammam"
                },
                content:{
                  rendered: props.t("v5.descrizione_striscia_led_panca")
                },
                _embedded:{
                  "wp:featuredmedia":[{source_url:"https://s3.eu-central-1.amazonaws.com/steam.effe.it/Mood+Light.svg"} as TFeaturedMediaItemType]
                },
                acf:{
                  max_quantity_optional: 7,
                  item_relationship:[
                    {
                      ID:getIdLightHammam(),
                      post_title:"Mood light hammam",
                      multipli_for_quantity_sku: true,
                      multiply_price: 100,
                      label_quantity: "cm"
                    },
                    {
                      ID:getIdModuleLightHammam(),
                      post_title:"Module mood light",
                      multipli_for_quantity_sku: false,
                      multiply_price: 1
                    }
                  ] 
                },
                description: props.t("v5.descrizione_striscia_led_panca"),
                slug:"mood-light-hammam" 
              }
            ] : []

          ).map((acc:TAccessorio) => {
              const indexAccessorioSelected = accessori?.accessori?.map(a => a.accessorio.id).indexOf(acc.id)
              const isSelected = indexAccessorioSelected !== -1
              let _options: number[] = [];
              for (let index = 0; index < 7; index++) {
                for (let indexJ = 0; indexJ < 10; indexJ++) {
                  _options.push((index*100) + ((indexJ+1)*10));
                }
              }

              return(
                  <SelectableDetailGridItem
                      key={acc?.id}
                      title={acc.title.rendered.replace(/(.*)-/g,"")}
                      isSelected={isSelected}
                      description={""}
                      descriptionHtml={acc?.content?.rendered}
                      maxCharDescription={50}
                      className="mb-8"
                      buttonTitleSelect={props.t("general.aggiungi")}
                      image={acc?._embedded?.["wp:featuredmedia"]?.[0]?.source_url || PLACEHOLDER_IMAGE}
                      maxQuantity={Number(acc.acf.max_quantity_optional)}
                      optionsQuantiy={_options}
                      labelQuantity={Number(acc.acf.max_quantity_optional) > 1 ? "cm" : ""}
                      onClick={()=>{
                          let _accessori = accessori?.accessori || []
                          if(isSelected){
                              _accessori.splice(indexAccessorioSelected!,1)


                              let _accessoriMap = accessoriMap
                              delete _accessoriMap[acc.id]
                              setAccessoriMap(JSON.parse(JSON.stringify(_accessoriMap)))

                          }else if(!isSelected){
                              if(!accessoriMap[acc.id] && acc.acf.max_quantity_optional>1){
                                  openModal(props.t('general.attenzione'), ()=>{
                                      return(
                                      <Text title={props.t("accessori.warning.inserire_quantita")} className="text-center"/>
                                      )
                                  },ModalSize.lg)
                              }
                              else{
                                  _accessori.push({accessorio: acc,quantity: accessoriMap[acc.id]||1})
                              }
                          }
                          dispatch(actGeneratoreSetAccessori({
                              accessori: _accessori
                          }))
                      }}
                      selectQuantityPlaceholder={props.t('accessori.quantita')}
                      isVisibleSelectQuantity={!isSelected && acc.acf.max_quantity_optional>1} 
                      selectQuantity={accessoriMap[acc.id]||0}
                      onChangeSelectQuantity={(qty:number)=>{
                          let _accessoriMap = accessoriMap
                          _accessoriMap[acc.id] = qty
                          setAccessoriMap(JSON.parse(JSON.stringify(_accessoriMap)))


                          let _accessori = accessori?.accessori || []
                          if(isSelected) _accessori.splice(indexAccessorioSelected!,1)
                          _accessori.push({accessorio: acc,quantity: qty})
                          dispatch(actGeneratoreSetAccessori({
                            accessori: _accessori
                          }))
                      }}
                  />
              )
          })}
        </>

        <Modal
            title={modalData.title}
            onClose={() => closeModal()}
            visible={modalIsOpen}
            size={modalData.size}
            closeInline
        >
            {modalData.content && modalData.content()}
        </Modal>
    </>
  );
}

export default connect(null, { actGeneratoreSetAccessori, actGeneral })(
  withTranslation()(Accessori)
);
