import { Form, Spin } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect } from "react-redux";
import { TGeneral } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { actGeneral, actPanche } from "../../../../redux/actions";
import Modal, { ModalSize } from "../../../../components/Modal";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { IconAccordionClosed, IconAccordionOpened, IconFrecciaDown, IconFrecciaUp } from "../../../../components/Icon";
import tailwindMyConfig from "../../../../tailwind.my.config";
import SelectableDetailGridItem from "../../../../components/GridItem/SelectableDetailGridItem";
import ActionButton, { IActionButtonType } from "../../../../components/Buttons/ActionButton";
import { TPancaCustomConfigurationQuantity, TPancaCustomConfigurationType, TPancaCustomType, TPancaQuantity, TPancaType, TPancheStoreState } from "../../../../redux/reducers/panche";
import PlusGridItem from "../../../../components/GridItem/PlusGridItem";
import { HEIGHT_SCROLL, LIMIT, PLACEHOLDER_IMAGE, SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_ANGOLO, SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_BASE, SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_END_COVER, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_ANGOLO, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_BASE, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_END_COVER, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_ANGOLO, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_BASE, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_END_COVER, SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED, SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE, SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED } from "../../../../utils/consts";
import { getItem, getPanche } from "../../../../network/actions";
import InfiniteScroll from 'react-infinite-scroll-component';
import { TListinoItemContentType } from "../../../../redux/reducers/porte_vetrate";
import AsyncSelect from "../../../../components/AsyncSelect";
import TextCell from "../../../../components/AsyncSelect/TextCell";


function Panche({ ...props }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const panche: TPancheStoreState = useSelector(
    (state: TGeneral) => state.panche
  );
  const [currentAccordion, setCurrentAccordion] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //InfiniteScroll
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [listPanche, setListPanche] = useState<TPancaType[]>([]);

  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});

  interface IPancaMap {
    [key: string]: TPancaQuantity;
  }
  const [pancaMap, setPancaMap] = useState<IPancaMap>({});
  const [pancaCustomMap, setPancaCustomMap] = useState<Map<string, TPancaCustomConfigurationQuantity>>(new Map());


  const openModal = (title: string, content: any, size: any) => {
    setModalData({ title, content, size });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
    //reset temp map
    setPancaCustomMap(new Map())
  };

  useEffect(() => {
    getPancheFromAPI()
  }, [])

  const getPancheFromAPI = async () => {
    try {
      setIsLoading(true)
      const data: TPancaType[] = (await getPanche(offset, LIMIT))
      setListPanche(data)
      setOffset(offset + LIMIT)
      setHasMore(data?.length > 0 && data?.length % LIMIT === 0)
      setIsLoading(false)
    } catch (err) {
      //TODO: Alert
      setIsLoading(false)
    }
  }

  const getModelliPancheCustomSezioneRettangolare = (): TPancaCustomConfigurationType[] => {
    return [{ id: SLUG_PANCA_CONFIGURATION_RETTANGOLARE_BASE,
    	name: props.t("panche.modelli.sez_rettangolare"),
    dimensioni: "cm 50 x 100 x 42 h",
    img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare.svg",
    descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
    price_euro: { price: "263",
    price_extra: "271",
    materials: [],
    sku: "sez_rettangolare",
    voltaggio: null,
    descrizione: "" } },
    { id: SLUG_PANCA_CONFIGURATION_RETTANGOLARE_END_COVER,
    name: props.t("panche.modelli.sez_rettangolare_end_cover"),
    dimensioni: "cm 50 x 25 x 42 h",
    img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare+end+cover.svg",
    descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare_end_cover")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
    price_euro: { price: "175",
    price_extra: "191",
    materials: [],
    sku: "sez_rettangolare_end_cover",
    voltaggio: null,
    descrizione: "" } },
    { id: SLUG_PANCA_CONFIGURATION_RETTANGOLARE_ANGOLO,
    name: props.t("panche.modelli.sez_rettangolare_angolo"),
    dimensioni: "cm 75+75 x 50 x 42 h",
    img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare+angolo.svg",
    descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare_angolo")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
    price_euro: { price: "515",
    price_extra: "546",
    materials: [],
    sku: "sez_rettangolare_angolo",
    voltaggio: null,
    descrizione: "" } }]
  }
  const getModelliPancheCustomSezioneRettangolareLuciLed = (): TPancaCustomConfigurationType[] => {
    return [{ id: SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_BASE,
    	name: props.t("panche.modelli.sez_rettangolare"),
    dimensioni: "",
    img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare+LED.svg",
    descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
    price_euro: { price: "289",
    price_extra: "305",
    materials: [],
    sku: "sez_rettangolare_led",
    voltaggio: null,
    descrizione: "" } },
    { id: SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_END_COVER,
    name: props.t("panche.modelli.sez_rettangolare_end_cover"),
    dimensioni: "",
    img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare+LED+end+cover.svg",
    descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare_end_cover")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
    price_euro: { price: "210",
    price_extra: "222",
    materials: [],
    sku: "sez_rettangolare_end_cover_led",
    voltaggio: null,
    descrizione: "" } },
    { id: SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_ANGOLO,
    name: props.t("panche.modelli.sez_rettangolare_angolo"),
    dimensioni: "",
    img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare+LED+angolo.svg",
    descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare_angolo")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
    price_euro: { price: "546",
    price_extra: "577",
    materials: [],
    sku: "sez_rettangolare_angolo_led",
    voltaggio: null,
    descrizione: "" } }]
  }
  const getModelliPancheCustomSezioneArrotondataLuciLed = (): TPancaCustomConfigurationType[] => {
    return [{
      id: SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_BASE,
      name: props.t("panche.modelli.sez_arrotondata"),
      dimensioni: "",
      img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Arrotondata+LED.svg",
      descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
      price_euro: {
        price: "289",
        price_extra: "305",
        materials: [],
        sku: "sez_arrotondata_led",
        voltaggio: null,
        descrizione: ""
      }
    },
    {
      id: SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_END_COVER,
      name: props.t("panche.modelli.sez_arrotondata_end_cover"),
      dimensioni: "",
      img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Arrotondata+LED+end+cover.svg",
      descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare_end_cover")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
      price_euro: {
        price: "210",
        price_extra: "222",
        materials: [],
        sku: "sez_arrotondata_end_cover_led",
        voltaggio: null,
        descrizione: ""
      }
    },
    {
      id: SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_ANGOLO,
      name: props.t("panche.modelli.sez_arrotondata_angolo"),
      dimensioni: "",
      img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Arrotondata+LED+angolo.svg",
      descrizione: `<div class="text-center text-white">${props.t("panche.modelli.dimensioni_sez_rettangolare_angolo")}<br/>${props.t("panche.modelli.materiale_eps")}</div>`,
      price_euro: {
        price: "546",
        price_extra: "577",
        materials: [],
        sku: "sez_arrotondata_angolo_led",
        voltaggio: null,
        descrizione: ""
      }
    }]
  }

  const listPancheCustom = [
    {
      id: SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE,
      slug: SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE,
      name: props.t("panche.panca_custom_semplice"),
      img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare.svg",
      descrizione: `<div class="text-xs">${getModelliPancheCustomSezioneRettangolare().map(p => `<span class='block mb-2 text-grigio-old-effe'>${p.name}<br/>${p.dimensioni ? `(${p.dimensioni})` : ""}</span>`).join("")}</div>`,
    },
    {
      id: SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED,
      slug: SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED,
      name: props.t("panche.panca_custom_led_rettangolare"),
      img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Rettangolare+LED.svg",
      descrizione: `<div class="text-xs">${getModelliPancheCustomSezioneRettangolareLuciLed().map(p => `<span class='block mb-2 text-grigio-old-effe'>${p.name}<br/>${p.dimensioni ? `(${p.dimensioni})` : ""}</span>`).join("")}</div>`,
    },
    {
      id: SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED,
      slug: SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED,
      name: props.t("panche.panca_custom_led_arrotondata"),
      img: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/Featured_Custom/Arrotondata+LED.svg",
      descrizione: `<div class="text-xs">${getModelliPancheCustomSezioneArrotondataLuciLed().map(p => `<span class='block mb-2 text-grigio-old-effe'>${p.name}<br/>${p.dimensioni ? `(${p.dimensioni})` : ""}</span>`).join("")}</div>`,
    }
  ]

  return (
    <div>
      <H3
        title={props.t("panche.titolo")}
        className="text-left text-black-effe m-title  mb-4"
      />

      <Row>
        <Col lg={12}>
          <Accordion>
            <div>
              <Accordion.Toggle
                onClick={() => {
                  setCurrentAccordion(
                    currentAccordion === null || currentAccordion !== 0
                      ? 0
                      : null
                  );
                }}
                eventKey="0"
                className="w-full"
              >
                <div
                  className={`bg-fluo-effe flex  items-center relative py-2`}
                >
                  <span className="text-white font-bold text-titlepopup pl-24">
                    {props.t("panche.standard")}
                  </span>
                  <div className="absolute right-8">
                    {currentAccordion === 0 ? (
                      <IconAccordionClosed/>
                    ) : (
                      <IconAccordionOpened/>
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey="0"
                className=""
              >
                <div className="">
                  {listPanche.filter(p => p?.acf?.item_relationship?.length > 0).map((panca: TPancaType, indexPanca) => {
                    const indexPancaSelected = panche?.panche?.map(p => p.panca.id).indexOf(panca.id)
                    const isSelected = indexPancaSelected !== -1

                    return (
                      <SelectableDetailGridItem
                        key={panca.id}
                        title={panca.title?.rendered}
                        isSelected={isSelected}
                        heightCell={200}
                        description={""}
                        descriptionHtml={panca?.content?.rendered}
                        selectQuantity={Number(panche?.panche?.[indexPancaSelected || 0]?.quantity) || 0}
                        // extraDescription={()=>{
                        //     return isSelected ? (<div>
                        //       <p className="text-wizard-effe font-bold mt-0 ">{isSelected ? `${props.t("panche.quantita")}: ${panche!.panche![indexPancaSelected!].quantity}` : null}</p>
                        //     </div>) : null
                        // }}
                        maxCharDescription={50}
                        className={`pt-12 ${indexPanca === listPanche.length - 1 && "pb-12"}`}
                        image={panca?._embedded?.["wp:featuredmedia"]?.[0]?.source_url || PLACEHOLDER_IMAGE}
                        onClick={async () => {
                          if (isSelected) {
                            let _panche = panche?.panche || []
                            _panche.splice(indexPancaSelected!, 1)
                            dispatch(actPanche({
                              panche: _panche,
                              panca_custom: undefined
                            }))
                          }
                          else {
                            dispatch(actGeneral({ appLoading: true }))
                            //Download items listino 
                            let _items: TListinoItemContentType[] = []
                            for (let index = 0; index < panca?.acf?.item_relationship?.length; index++) {
                              const item = panca?.acf?.item_relationship[index];
                              try {
                                let _itemData = await getItem(item.ID)
                                _items.push(_itemData)
                              } catch (error) { }
                            }
                            //Set info data and select first material is present
                            let _pancaMap = pancaMap
                            _pancaMap[panca.id] = {
                              panca: panca,
                              quantity: 1,
                              item_listino: _items?.filter(i => Array.isArray(i?.acf?.materials))?.[0] || undefined
                            }
                            setPancaMap(JSON.parse(JSON.stringify(_pancaMap)))

                            dispatch(actGeneral({ appLoading: false }))
                            openModal(panca.title?.rendered, () => {
                              return (<div>

                                <Row className="mt-8">
                                  <Col sm={12} md={6} lg={6} >
                                    <AsyncSelect
                                      isSecondary
                                      currValue={pancaMap[panca.id].item_listino ? {
                                        name: pancaMap[panca.id].item_listino?.acf?.materials?.[0]?.post_title,
                                        id: pancaMap[panca.id].item_listino?.acf?.materials?.[0]?.ID,
                                      } : null}
                                      noOptionsMessage={props.t("panche.modelli.no_materials")}
                                      placeholder={props.t("panche.modelli.scegli_materiale")}
                                      onChange={(item: TListinoItemContentType) => {
                                        let _pancaMap = pancaMap[panca.id]
                                        _pancaMap.item_listino = item
                                        setPancaMap(JSON.parse(JSON.stringify(_pancaMap)))
                                      }}
                                      onStyleLabel={(item: any) => {
                                        return <TextCell isSecondary title={item.name} />;
                                      }}
                                      loadOptions={async (
                                        searchQuery: any,
                                        loadedOptions: any,
                                        { page }: any
                                      ) => {
                                        const response: TListinoItemContentType[] = _items.filter((i) => Array.isArray(i?.acf?.materials))
                                        return {
                                          options: response.map((coll: TListinoItemContentType) => {
                                            return { name: coll.acf?.materials?.[0]?.post_title, ...coll };
                                          }),
                                          hasMore: false,
                                          additional: {},
                                        };
                                      }}
                                    />
                                  </Col>
                                  <Col sm={12} md={6} lg={6} className="xs:mt-4 sm:mt-4 md:mt-0 lg:mt-0 xl:mt-0">
                                    <AsyncSelect
                                      isSecondary
                                      currValue={pancaMap[panca.id].quantity ? {
                                        name: pancaMap[panca.id].quantity,
                                        id: pancaMap[panca.id].quantity,
                                      } : null}
                                      placeholder={props.t("panche.modelli.scegli_qty")}
                                      onChange={(item: any) => {
                                        let _pancaMap = pancaMap[panca.id]
                                        _pancaMap.quantity = item.id
                                        setPancaMap(JSON.parse(JSON.stringify(_pancaMap)))
                                      }}
                                      onStyleLabel={(item: any) => {
                                        return <TextCell isSecondary title={item.name} />;
                                      }}
                                      loadOptions={async (
                                        searchQuery: any,
                                        loadedOptions: any,
                                        { page }: any
                                      ) => {
                                        const response: number[] = [1, 2, 3, 4, 5]
                                        return {
                                          options: response.map((coll: number) => {
                                            return { name: coll, id: coll };
                                          }),
                                          hasMore: false,
                                          additional: {},
                                        };
                                      }}
                                    />
                                  </Col>
                                </Row>


                                <Row className="mt-8">
                                  <Col lg={12} className="flex justify-center">
                                    <ActionButton
                                      disabled={pancaMap[panca.id]?.item_listino === undefined || !pancaMap[panca.id]?.quantity}
                                      importance={IActionButtonType.Secondary} title={props.t("porte_vetrate.btn.seleziona_config")} onClick={() => {
                                        let _panche = panche?.panche || []
                                        _panche.push(pancaMap[panca.id])
                                        dispatch(actPanche({
                                          panche: _panche,
                                          panca_custom: undefined
                                        }))
                                        closeModal()
                                      }} style={{ paddingTop: 10, paddingBottom: 10 }} />
                                  </Col>
                                </Row>
                              </div>)
                            }, ModalSize.xl)
                          }

                        }}
                        classNameImage={""}
                      // actionDescription={props.t("general.maggiori_dettagli")}
                      // actionOnClick={()=>{
                      //   openModal(panca?.title.rendered,()=>{
                      //     return(
                      //       <div dangerouslySetInnerHTML={{
                      //         __html: panca?.content?.rendered
                      //       }}></div>
                      //     )
                      //   },ModalSize.lg)
                      // }}
                      />
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </div>

            <div className="mt-2">
              <Accordion.Toggle
                onClick={() => {
                  setCurrentAccordion(
                    currentAccordion === null || currentAccordion !== 1
                      ? 1
                      : null
                  );
                }}
                eventKey="1"
                className="w-full"
              >
                <div
                  className={`bg-fluo-effe flex items-center relative py-2`}
                >
                  <span className="text-white font-bold text-titlepopup pl-24">
                    {props.t("panche.su_misura")}
                  </span>
                  <div className="absolute right-8">
                    {currentAccordion === 1 ? (
                      <IconAccordionClosed/>
                    ) : (
                      <IconAccordionOpened/>
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey="1"
                className=""
              >
                <div className="">
                  {listPancheCustom.map((panca_custom: TPancaCustomType, indexPanca) => {
                    const isSelected = panche?.panca_custom?.modello?.id == panca_custom.id
                    return (
                      <SelectableDetailGridItem
                        key={panca_custom.id}
                        title={panca_custom.name}
                        isSelected={isSelected}
                        description={""}
                        descriptionHtml={panca_custom.descrizione}
                        className={`pt-12 ${indexPanca === listPancheCustom.length - 1 && "pb-12"}`}
                        buttonTitleSelect={props.t("general.configura")}
                        image={panca_custom.img}
                        heightCell={220}
                        onClick={() => {
                          if (isSelected) {
                            dispatch(actPanche({
                              panca_custom: undefined
                            }))
                            //reset temp map
                            setPancaCustomMap(new Map())
                          } else {
                            openModal(panca_custom.name, () => {
                              return (<div>
                                <Row>
                                  {(panca_custom.id === SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE ? getModelliPancheCustomSezioneRettangolare() : panca_custom.id === SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED ? getModelliPancheCustomSezioneRettangolareLuciLed() : getModelliPancheCustomSezioneArrotondataLuciLed())
                                    .map((it: TPancaCustomConfigurationType, indexIt) => {

                                      return (
                                        <Col key={indexIt} lg={4}>
                                          <PlusGridItem
                                            quantity={pancaCustomMap.get(it.id)?.quantity || 0}
                                            title={it.name}
                                            img={it.img}
                                            dimensioni={it.dimensioni}
                                            descrizioneHtml={it.descrizione}
                                            overlay
                                            className={"mb-4"}
                                            onPlus={() => {
                                              let _pancaCustomMap = pancaCustomMap
                                              let qty = (_pancaCustomMap.get(it.id)?.quantity || 0) + 1
                                              _pancaCustomMap.set(it.id, { panca_custom: it, quantity: qty })
                                              setPancaCustomMap(JSON.parse(JSON.stringify(_pancaCustomMap)))
                                            }}
                                            onMinus={() => {
                                              let _pancaCustomMap = pancaCustomMap
                                              let qty = (_pancaCustomMap.get(it.id)?.quantity || 0) - 1
                                              _pancaCustomMap.set(it.id, { panca_custom: it, quantity: qty })
                                              setPancaCustomMap(JSON.parse(JSON.stringify(_pancaCustomMap)))
                                            }} />
                                        </Col>
                                      )
                                    })}
                                </Row>
                                <Row className="mt-4 text-center">
                                  <Col lg={12}>
                                    <ActionButton importance={IActionButtonType.Secondary}
                                      title={props.t("panche.seleziona_configurazione")}
                                      disabled={pancaCustomMap?.size == 0}
                                      onClick={() => {
                                        dispatch(actPanche({
                                          panche: [], // Reset panche standard
                                          panca_custom: {
                                            modello: panca_custom,
                                            configurations: Array.from(pancaCustomMap.values())
                                          }
                                        }))
                                        closeModal()
                                      }} />
                                  </Col>
                                </Row>
                              </div>)
                            }, ModalSize.lg)
                          }

                        }}
                        classNameImage={""}
                      />
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </Col>
      </Row>

      <Modal
        title={modalData.title}
        onClose={() => closeModal()}
        visible={modalIsOpen}
        size={modalData.size}
        closeInline
      >
        {modalData.content && modalData.content()}
      </Modal>
    </div>
  );
}

export default connect(null, { actPanche, actGeneral })(
  withTranslation()(Panche)
);
