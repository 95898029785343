import { useState } from "react";
import {
  IconCloseMenu,
} from "../../components/Icon";
import tailwindMyConfig from "../../tailwind.my.config";
import logo from "./../../assets/images/logo_text_green.png";
import ic_close from "./../../assets/images/closeicon.png";
import logo_us from "./../../assets/images/PerfectWellness Logo big-01.png";

import { FC } from "react";
import { TMenuItem } from "../../controller/Configuratore";
import { getLocalization, Localization } from "../../utils/utils";
import { clearAuthInfo, getAuthInfo } from "../../utils/storage";
import { useHistory } from "react-router";
import { BASE_WP_URL } from "../../network";
import axios from "axios";
import { Spin } from "antd";

interface IMenu extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  onClose: () => void;
  items: any[];
  onClickMenuItem: (item: TMenuItem) => void;
  currentStep: number;
  menuIsOpen: boolean;
}

const Menu: FC<IMenu> = ({ onClose, items, onClickMenuItem, currentStep,menuIsOpen, ...props }: IMenu) => {
  const [menuIsClosing, setMenuIsClosing] = useState<boolean>(false);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  return (
    <div
      className={`menu ${menuIsOpen && "open"} top-0 flex flex-1 flex-col bg-black-effe h-screen py-4 animate-fade-in-down w-full ${
        menuIsClosing && "animate-fade-out-down"
      } ${props.className}`}
      style={{zIndex:999}}
    >
      <div className="flex flex-1 justify-between flex-col relative xs:max-w-xs md:max-w-lg w-full h-full" style={{ margin:'0 auto'}}>
        <div className="flex items-center justify-center w-full" style={{height:50}}>
          <button
            className="focus:outline-none absolute left-0"
            onClick={() => {
              setMenuIsClosing(true);
              onClose()
            }}
          >
            <img className="icon-close-menu" src={ic_close}/>
          </button>

          <img src={logo} className="logo-menu w-30 object-contain" />
          {getLocalization()===Localization.AMERICA && <img src={logo_us} className="w-32 ml-2 object-contain" />}
        </div>
        <div className={` flex flex-1 flex-col overflow-scroll h-full justify-between xs:mt-4 md:mt-16`}>
          {items.map((it:TMenuItem, indexIt:number)=>{

            const isSelected = it.positionInMenu===currentStep
            return(
              <a key={it.position} 
              className={`relative block  flex-1 hover:no-underline text-menu 
              ${(it.positionInMenu||0)<currentStep ? "text-fluo-effe hover:text-fluo-effe bg-traansparent" : 
              (isSelected ? "font-extrabold text-fluo-effe hover:text-fluo-effe bg-menu-selected" : 
              "text-fluo-effe hover:text-fluo-effe opacity-40 cursor-not-allowed bg-traansparent")}`} href="#"
              style={{borderTop:"1px solid", borderBottom: "unset",
                      borderColor:"rgba(0,255,186,.25)"
                    }}
              onClick={()=>{
                if((it.positionInMenu||0)<=currentStep){
                  onClickMenuItem(it)
                  setMenuIsClosing(true);
                  onClose()
                }
              }}>
                <p className="menu-item mb-0 flex items-center h-full">
                  <span className="absolute left-2">{indexIt+1}</span>
                  <span className="w-full text-center menu-text">{it.label!}</span>
                </p>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Menu;
