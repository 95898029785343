import React from 'react'
import ManagerApp from './Managerapp';
import "./i18n";
import 'antd/dist/antd.css';
import './assets/css/font.css'
import './assets/css/index.css'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { getAuthInfo } from './utils/storage';

function App() {
  const app_version = require('../package.json').version;
  Bugsnag.start({
    apiKey: 'f60521f9a616bbef338c0d5a3a945220',
    plugins: [new BugsnagPluginReact()],
    enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user', "state"],
    releaseStage: process.env.REACT_APP_ENV_NAME,
    enabledReleaseStages: ["prod"],
    appVersion: app_version,
    onError: function (event) {
      console.log("onError",event)
      event.setUser(getAuthInfo()?.id_user||undefined, getAuthInfo()?.crm_language||undefined, app_version);
    },
  })
  const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

  return (
    <ErrorBoundary>
      <ManagerApp/>
    </ErrorBoundary>);
}

export default App;
