import moment from 'moment'
import i18n from 'i18next'
import { getLanguage, getLocalization, Localization } from './utils';

var CryptoJS = require("crypto-js");
const KEY_TOKEN = "f_a_c";

export enum CrmLanguage {
  IT = "Italiano",
  EN = "Inglese",
  FR = "Francese",
  DE = "Tedesco",
  ES = "Spagnolo",
  IT_EXPORT = "Italiano (Export)"
}

export enum Language {
  IT = "it-IT",
  EN = "en-EN",
  FR = "fr-FR",
  DE = "de-DE",
  ES = "es-ES"
}

export type AuthInfoType = {
  access_token: string;
  refresh_token: string;
  id_user: string;
  crm_language: CrmLanguage;
  at: number;
  email: string;
}

// AUTH
const setAuthInfo = (infoToken: any, idUser: string, crm_language: string, email: string): void => {
  const _jsonData = {
    ...infoToken,
    id_user: idUser,
    crm_language,
    email,
    at: moment().valueOf()
  } as AuthInfoType
  const _data = JSON.stringify(_jsonData)
  console.log("[SET AUTH DATA]", _data)

  localStorage.setItem(KEY_TOKEN, CryptoJS.AES.encrypt(_data, process.env.REACT_APP_CRYPT_KEY).toString());

  //Set language
  i18n.changeLanguage(getLangFromWPLocale());
};

const getAuthInfo = (): AuthInfoType|any => {
  try {
    const info = localStorage.getItem(KEY_TOKEN)
    const decrypted = info ? CryptoJS.AES.decrypt(info, process.env.REACT_APP_CRYPT_KEY)?.toString(CryptoJS.enc.Utf8) : "{}";
    return JSON.parse(decrypted);
  } catch (error) {
    return {} ;
  }
};

const clearAuthInfo = (): void => {
  localStorage.removeItem(KEY_TOKEN);
};

const isLogged = (): boolean => {
  return !!getAuthInfo()?.access_token && isTokenExpired() !== undefined && !isTokenExpired()
}

const isTokenExpired = (): boolean | undefined => {
  const auth = getAuthInfo();
  if (!auth?.at) return undefined
  const expire_date_ms = auth?.at + (3600 * 1000)
  return moment().valueOf() > expire_date_ms
};

const getLangFromWPLocale = (): string | undefined => {
  if (getLocalization() === Localization.AMERICA) return "en"
  else return getLanguage()
}


export { setAuthInfo, getAuthInfo, isLogged, isTokenExpired, getLangFromWPLocale, clearAuthInfo };
