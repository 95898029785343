import { BrowserRouter, Switch, Route } from "react-router-dom";
import Callback from "./controller/Callback";
import Configuratore from "./controller/Configuratore";
import Overview from "./controller/Overview";

function Router() {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path="/" component={Configuratore} />
          <Route exact path="/US" component={Configuratore} />
          <Route exact path="/callback/" component={Callback} />
          <Route path="/overview/:id" component={Overview} />
          <Route path="/download-pdf/:id" component={()=><Overview downloadPdf/>} />
          <Route exact path="*" component={()=><div className="h-screen flex justify-center items-center"><p className="text-verde-medioscuro-effe">Error, page not found : ( </p></div>} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Router;
