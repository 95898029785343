import Action, { ActionTypes } from "../actionTypes";
import { TFeaturedMediaType } from "./media";
import img_cerniera_sx from "./../../assets/images/materials/Cerniera_SX.svg"
import img_cerniera_dx from "./../../assets/images/materials/Cerniera_DX.svg"
import { CODE_ADAPTA, CODE_TELAIO_TUTTOVETRO_INSIDE, CODE_TUTTOVETRO_CONTOUR, CODE_TUTTOVETRO_INSIDE, ID_ADAPTA_PORTA, ID_TUTTOVETRO_CONTOUR_PORTA, ID_TUTTOVETRO_CONTOUR_VETRATA, ID_TUTTOVETRO_INSIDE_PORTA, ID_TUTTOVETRO_INSIDE_VETRATA, MAX_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM, MAX_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM, MIN_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM, MIN_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM, PLACEHOLDER_IMAGE, SLUG_ADAPTA_CUSTOM, SLUG_ANGOLO_VETRO_PORTA_CONTOUR, SLUG_ANGOLO_VETRO_PORTA_INSIDE, SLUG_MATERIALE_ANODIZZATO, SLUG_MATERIALE_BRONZO_SATINATO, SLUG_MATERIALE_DEFAULT, SLUG_MATERIALE_NERO_SATINATO, SLUG_MATERIALE_RAME_SATINATO, SLUG_MATERIALE_SPAZZOLATA, SLUG_TUTTOVETRO_CONTOUR, SLUG_TUTTOVETRO_INSIDE } from "../../utils/consts";
import { isVetroPortaVetroPortaModel } from "../../utils/utils";
import i18n from './../../i18n'
import { CrmLanguage, getAuthInfo } from "../../utils/storage";

export enum EPortaType {
  FIT = "fit"
}

export const getPerimetroPortaVetrataMetri = (altezza_cm: number, larghezza_cm: number): number => {
  return ((altezza_cm / 100 + larghezza_cm! / 100) * 2) + 0.18
}
export const getAreaPortaVetrataMetri = (altezza_cm: number, larghezza_cm: number): number => {
  return (altezza_cm / 100 * larghezza_cm! / 100)
}

export const getPricesCustomPortaVetrata = (store: TPorteVetrateCustomStoreState): { [key: string]: TListinoItemContentType[] } => {
  let _pricing: TListinoItemContentType[] = []
  const {crm_language} = getAuthInfo()
  let result: { [key: string]: TListinoItemContentType[] } = {};
  if (store.modello_porta) {
    if (
      (store.modello_porta && !isVetroPortaVetroPortaModel(store.modello_porta!)) &&
      (store.altezza_porta! < MIN_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM || store.altezza_porta! > MAX_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM ||
        store.larghezza_porta! < MIN_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM || store.larghezza_porta! > MAX_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM)) {
      throw new Error("La larghezza deve essere maggiore o uguale a 60cm e minori o uguali a 350cm, l'altezza deve essere maggiore o uguale a 60cm e minori o uguali a 250cm")
    }

    if (store.porta_prodotto?.slug !== SLUG_TUTTOVETRO_CONTOUR && store.porta_prodotto?.slug !== SLUG_TUTTOVETRO_INSIDE && store.porta_prodotto?.slug !== SLUG_ADAPTA_CUSTOM) {
      console.log("PRODOTTO NON GESTITO >>> ",store.porta_prodotto?.slug)
      throw new Error("Il prodotto di porta custom non è gestito")
    }

    //Const contour
    const const_profilo_esterno_contour = 10
    const const_profilo_esterno_contour_spazzolato = 15
    const const_price_anodizzato = 750

    //Const Inside
    const const_profilo_esterno_inside = 3.5
    const const_profilo_esterno_spazzolato_inside = 7.4
    const const_profilo_esterno_anodizzato_inside = 12


    //Const comuni
    const const_price_spessore_8_mm = 75
    const const_price_spessore_10_mm = 90
    const const_price_cerniere_2_pz = 106
    const const_price_cerniere_3_pz = 159
    const const_guarnizioni = 4
    const const_moltiplicatore_default = 5
    const const_moltiplicatore_ad_angolo = 5.2
    const LIMIT_ALTEZZA = 220

    //Porta
    const perimetro_porta = getPerimetroPortaVetrataMetri(store.altezza_porta!, store.larghezza_porta!)
    const area_porta = getAreaPortaVetrataMetri(store.altezza_porta!, store.larghezza_porta!)

    //$$ Contour
    const price_profilo_esterno_contour = perimetro_porta * (store.materiale?.slug === SLUG_MATERIALE_SPAZZOLATA ? const_profilo_esterno_contour_spazzolato : const_profilo_esterno_contour)
    const price_spessore_vetro_contour = area_porta * (store.altezza_porta! < LIMIT_ALTEZZA ? const_price_spessore_8_mm : const_price_spessore_10_mm)
    const price_cerniere_contour = store.altezza_porta! < LIMIT_ALTEZZA ? const_price_cerniere_2_pz : const_price_cerniere_3_pz
    const price_maniglia_contour = 23
    const price_guarnizioni_contour = perimetro_porta * const_guarnizioni
    const price_imballo_contour = 35
    const price_lavorazione_profilo_contour = 50
    const price_imballaggio_contour = 10

    //$$ Inside
    const getConstProfiloEsternoInside = () => store.materiale?.slug === SLUG_MATERIALE_ANODIZZATO ? const_profilo_esterno_anodizzato_inside : store.materiale?.slug === SLUG_MATERIALE_SPAZZOLATA ? const_profilo_esterno_spazzolato_inside : const_profilo_esterno_inside
    const price_porta_profilo_esterno_inside = getConstProfiloEsternoInside() * (perimetro_porta * 2)
    const price_spessore_vetro_inside = area_porta * (store.altezza_porta! < LIMIT_ALTEZZA ? const_price_spessore_8_mm : const_price_spessore_10_mm)
    const price_cerniere_inside = store.altezza_porta! < LIMIT_ALTEZZA ? const_price_cerniere_2_pz : const_price_cerniere_3_pz
    const price_maniglia_inside = 23
    const price_guarnizioni_inside = perimetro_porta * const_guarnizioni
    const price_imballo_inside = 25
    const price_imballaggio_inside = 20 * 2

    const getPriceTelaioInside = (perimetro: number) => {
      const telaio_profilo = 3
      const telaio_listello = 6
      const telaio_squadrette = 15
      const telaio_imballo = 5
      const telaio_lavorazione = 20
      const adeguamento_prezzo = 7
      return (telaio_profilo * perimetro) + telaio_listello + (telaio_squadrette * 1) + telaio_imballo + telaio_lavorazione + adeguamento_prezzo
    }

    const getPricePortaContour = () => {
      const percentual = 0
      let subtotale = Math.round((price_profilo_esterno_contour + price_spessore_vetro_contour + price_cerniere_contour + price_maniglia_contour + price_guarnizioni_contour + price_imballo_contour + price_lavorazione_profilo_contour + price_imballaggio_contour) * (store.modello_porta?.slug === SLUG_ANGOLO_VETRO_PORTA_CONTOUR ? const_moltiplicatore_ad_angolo : const_moltiplicatore_default)) + (store.materiale?.slug === SLUG_MATERIALE_ANODIZZATO ? const_price_anodizzato : 0)
      console.log(`Porta price CONTOUR <Perimetro:${perimetro_porta}, Area:${area_porta}>: String((${price_profilo_esterno_contour}+${price_spessore_vetro_contour}+${price_cerniere_contour}+${price_maniglia_contour}+${price_guarnizioni_contour}+${price_imballo_contour}+${price_lavorazione_profilo_contour}+${price_imballaggio_contour}) * (${store.modello_porta?.slug === SLUG_ANGOLO_VETRO_PORTA_CONTOUR ? const_moltiplicatore_ad_angolo : const_moltiplicatore_default}))  + ${+ (store.materiale?.slug === SLUG_MATERIALE_ANODIZZATO ? const_price_anodizzato : 0)}`)
      return String(Math.round(subtotale + (subtotale * percentual) / 100))
    }

    const getMoltiplicatoreInside = () => store.modello_porta?.slug === SLUG_ANGOLO_VETRO_PORTA_INSIDE ? const_moltiplicatore_ad_angolo : const_moltiplicatore_default
    const getPriceTelaioMoltiplicatoInside = () => (getPriceTelaioInside(perimetro_porta) * getMoltiplicatoreInside())
    const getPricePortaInside = () => {
      const moltiplicatore = getMoltiplicatoreInside()
      const priceTelaioMoltiplicato = getPriceTelaioMoltiplicatoInside()
      const subtotale = priceTelaioMoltiplicato + ((price_porta_profilo_esterno_inside + price_spessore_vetro_inside + price_cerniere_inside + price_maniglia_inside + price_guarnizioni_inside + price_imballo_inside + price_imballaggio_inside) * moltiplicatore)
      console.log(`Porta price INSIDE <Perimetro:${perimetro_porta}, Area:${area_porta}>: Telaio(${getPriceTelaioInside(perimetro_porta)}*${moltiplicatore}) - (${price_porta_profilo_esterno_inside} + ${price_spessore_vetro_inside} + ${price_cerniere_inside} + ${price_maniglia_inside} + ${price_guarnizioni_inside} + ${price_imballo_inside} + ${price_imballaggio_inside}) * ${moltiplicatore}`)
      return String(Math.round(subtotale))
    }


    const getPricePortaAdapta = (extra: boolean) => {
      const price_adapta_70 = 1750;
      const price_extra_adapta_70 = 1850;
      const price_adapta_120 = 2550;
      const price_extra_adapta_120 = 2650;

      const width_less_than_90cm = store.larghezza_porta! >= 50 && store.larghezza_porta!<=90;
      let final_price:number = (width_less_than_90cm ? ((extra ? price_extra_adapta_70 : price_adapta_70)*1.1) : store.larghezza_porta!>90 ? ((extra ? price_extra_adapta_120 : price_adapta_120)*1.15) : 0);
      if(store.materiale?.slug === SLUG_MATERIALE_RAME_SATINATO || store.materiale?.slug === SLUG_MATERIALE_NERO_SATINATO || store.materiale?.slug === SLUG_MATERIALE_BRONZO_SATINATO){
        final_price+= width_less_than_90cm ? (crm_language===CrmLanguage.IT ? 1400 : 1440) : (crm_language===CrmLanguage.IT ? 2310 : 2380); 
      }
      return `${final_price?.toFixed(2)}`;
    }

    const finalPricePorta = (extra?: boolean) => store.porta_prodotto?.slug === SLUG_TUTTOVETRO_INSIDE ? `${Number(getPricePortaInside()) - getPriceTelaioMoltiplicatoInside()}` : 
      store.porta_prodotto?.slug === SLUG_TUTTOVETRO_CONTOUR ? getPricePortaContour() : 
      store.porta_prodotto?.slug === SLUG_ADAPTA_CUSTOM ? getPricePortaAdapta(!!extra) : "";

    result[
      store.porta_prodotto?.slug === SLUG_TUTTOVETRO_CONTOUR ? ID_TUTTOVETRO_CONTOUR_PORTA : 
      store.porta_prodotto?.slug === SLUG_TUTTOVETRO_INSIDE ? ID_TUTTOVETRO_INSIDE_PORTA : 
      store.porta_prodotto?.slug === SLUG_ADAPTA_CUSTOM ? ID_ADAPTA_PORTA : ""] = [
      {
        id: store.modello_porta!.id,
        slug: store.porta_prodotto?.slug,
        title: store.modello_porta!.title,
        acf: { 
          sku: store.porta_prodotto?.slug === SLUG_TUTTOVETRO_CONTOUR ? CODE_TUTTOVETRO_CONTOUR : 
          store.porta_prodotto?.slug === SLUG_TUTTOVETRO_INSIDE ? CODE_TUTTOVETRO_INSIDE : 
          store.porta_prodotto?.slug === SLUG_ADAPTA_CUSTOM ? CODE_ADAPTA : "",
          descrizione: "",
          voltaggio: null,
          side_cerniera: store.versione!,
          materials: [],
          price: finalPricePorta(),
          price_extra: finalPricePorta(true)
        }
      },
    ].concat(store.porta_prodotto?.slug === SLUG_TUTTOVETRO_INSIDE ? [
      {
        id: store.modello_porta!.id,
        slug: store.porta_prodotto?.slug,
        title: { rendered: i18n.t("v6.telaio_porta") },
        acf: {
          sku: CODE_TELAIO_TUTTOVETRO_INSIDE,
          descrizione: "",
          side_cerniera: store.versione!,
          voltaggio: null,
          materials: [],
          price: `${getPriceTelaioMoltiplicatoInside()}`,
          price_extra: `${getPriceTelaioMoltiplicatoInside()}`,
        }
      }
    ] : [])

    //Vetrata
    if (store.modello_vetrata) {
      const perimetro_vetrata = getPerimetroPortaVetrataMetri(store.altezza_vetrata!, store.larghezza_vetrata!)
      const area_vetrata = getAreaPortaVetrataMetri(store.altezza_vetrata!, store.larghezza_vetrata!)

      const price_vetrata_spessore_vetro = area_vetrata * (store.altezza_vetrata! < LIMIT_ALTEZZA ? const_price_spessore_8_mm : const_price_spessore_10_mm)
      const price_vetrata_guarnizioni = perimetro_vetrata * const_guarnizioni

      //Contour
      const price_vetrata_profilo_esterno_contour = (perimetro_vetrata * (store.materiale?.slug === SLUG_MATERIALE_SPAZZOLATA ? const_profilo_esterno_contour_spazzolato : const_profilo_esterno_contour))

      //Inside
      const price_vetrata_profilo_esterno_inside = (perimetro_vetrata * 2) * getConstProfiloEsternoInside()

      const getPriceVetrataContour = () => {
        const percentual = 0
        let subtotale = Math.round((price_vetrata_profilo_esterno_contour + price_vetrata_spessore_vetro + price_vetrata_guarnizioni + price_imballo_contour + price_lavorazione_profilo_contour + price_imballaggio_contour) * const_moltiplicatore_default) + (store.materiale?.slug === SLUG_MATERIALE_ANODIZZATO ? const_price_anodizzato : 0)
        console.log(`Vetrata Contour price <Perimetro:${perimetro_vetrata}, Area:${area_vetrata}>: String((${price_vetrata_profilo_esterno_contour}+${price_vetrata_spessore_vetro}+${price_vetrata_guarnizioni}+${price_imballo_contour}+${price_lavorazione_profilo_contour}+${price_imballaggio_contour}) * (${const_moltiplicatore_default})) + ${(store.materiale?.slug === SLUG_MATERIALE_ANODIZZATO ? const_price_anodizzato : 0)} `)
        return String(Math.round(subtotale + (subtotale * percentual) / 100))
      }
      const getPriceVetrataInside = () => {
        const percentual = 0
        const moltiplicatore = const_moltiplicatore_default
        const subtotale = (getPriceTelaioInside(perimetro_vetrata) * moltiplicatore) + ((price_vetrata_profilo_esterno_inside + price_vetrata_spessore_vetro + price_vetrata_guarnizioni + price_imballo_inside + price_imballaggio_inside) * moltiplicatore)
        console.log(`Vetrata Inside price <Perimetro:${perimetro_vetrata}, Area:${area_vetrata}>: String(Telaio(${getPriceTelaioInside(perimetro_vetrata)}*${moltiplicatore}) + (${price_vetrata_profilo_esterno_inside}+${price_vetrata_spessore_vetro}+${price_vetrata_guarnizioni}+${price_imballo_inside}+${price_imballaggio_inside}) * (${moltiplicatore}))  `)
        return String(Math.round(subtotale + (subtotale * percentual) / 100))
      }
      const _price_vetrata_total = store.porta_prodotto?.slug === SLUG_TUTTOVETRO_CONTOUR ? getPriceVetrataContour() : getPriceVetrataInside()

      result[store.porta_prodotto?.slug === SLUG_TUTTOVETRO_CONTOUR ? ID_TUTTOVETRO_CONTOUR_VETRATA : ID_TUTTOVETRO_INSIDE_VETRATA] = [
        {
          id: store.modello_vetrata!.id,
          slug: store.porta_prodotto?.slug,
          title: store.modello_vetrata!.title,
          acf: {
            sku: store.porta_prodotto?.slug === SLUG_TUTTOVETRO_CONTOUR ? CODE_TUTTOVETRO_CONTOUR : CODE_TUTTOVETRO_INSIDE,
            descrizione: "",
            voltaggio: null,
            side_cerniera: store.versione!,
            materials: [],
            price: _price_vetrata_total,
            price_extra: _price_vetrata_total
          }
        }
      ]
    }
  }


  return result
}


export enum EPortaStdCustomType {
  STANDARD = "Standard",
  CUSTOM = "Custom"
}

export type TPortaType = {
  id: string;
  slug: string;
  title: {
    rendered: string
  };
  content: {
    rendered: string
  };
  _embedded: TFeaturedMediaType;
  descrizione: string; //TODO: Descrizione prodotto porta vetrata
  acf: {
    tipologia_di_prodotto: EPortaStdCustomType,
    models_relationship: TModelloPortaType[];
    min_altezza_modello_porta: string;
    min_larghezza_modello_porta: string;
    min_altezza_modello_vetrata: string;
    min_larghezza_modello_vetrata: string;
    custom_configuration_fields: {
      material_door_custom: TMaterial[]
    }
  };
}
export type TModelloPortaType = {
  ID: string;
  post_title: string;
  dim_vetrata: string; //TODO:
  dim_controtelaio: string; //TODO:
  materiali: string; //TODO:
  caratteristiche: string; //TODO:
  acf: {
    item_relationship: TListinoItemType[];
  };
}
export type TModelloDetailPortaType = {
  id: string;
  slug: string;
  categoria_modello_pv: number[];
  title: {
    rendered: string
  };
  content: {
    rendered: string
  };
  _embedded: TFeaturedMediaType;
  acf: {
    item_relationship: TListinoItemType[];
    standard_configuration_fields: {
      x_axis_door: number;
      y_axis_door: number;
    }
  };
  is_visible: boolean;
}

export type TListinoItemType = {
  ID: string;
  post_title: string;
}
export enum ESideCerniera {
  DX = "right",
  SX = "left"
}
export enum EVoltaggio {
  V208 = "208",
  V240 = "240"
}

export const getLabelForESideCerniera = (side?: ESideCerniera) => {
  switch (side) {
    case ESideCerniera.DX:
      return "porte_vetrate.versione_dx"
    case ESideCerniera.SX:
      return "porte_vetrate.versione_sx"
    default:
      return "";
  }
}
export const getImageForESideCerniera = (side?: ESideCerniera) => {
  switch (side) {
    case ESideCerniera.DX:
      return img_cerniera_dx
    case ESideCerniera.SX:
      return img_cerniera_sx
    default:
      return PLACEHOLDER_IMAGE;
  }
}

export type TListinoItemAcfType = {
  sku: string;
  descrizione: string;
  price: string;
  price_extra: string;
  side_cerniera?: ESideCerniera;
  materials: TMaterial[];
  voltaggio: EVoltaggio | null;
}

export type TListinoItemContentType = {
  id: string;
  slug: string;
  acf: TListinoItemAcfType;
  title: {
    rendered: string
  };
  multipli_for_quantity_sku?: boolean;
  label_quantity?: string;
  variant?: string;
}
export type TMaterial = {
  ID: string;
  post_title: string;
  post_name: string;
}
export type TMaterialDetails = {
  id: string;
  slug: string;
  title: {
    rendered: string
  };
  _embedded: TFeaturedMediaType;
}

export type TVetrataType = {
  id: string;
  name: string;
  img: string;
  descrizione: string;
}
export type TCollezioniSuMisura = {
  id: string;
  name: string;
  img: string;
  descrizione: string;
}

export type TPorteVetrateStandardStoreState = {
  porta_prodotto?: TPortaType;
  porta_listino?: TListinoItemContentType[];
  modello_porta?: TModelloDetailPortaType;
  modello_vetrata?: TModelloDetailPortaType;
}
export type TPorteVetrateCustomStoreState = {
  porta_prodotto?: TPortaType;
  larghezza_porta?: number;
  altezza_porta?: number;
  larghezza_vetrata?: number;
  altezza_vetrata?: number;
  materiale?: TMaterialDetails;
  versione?: ESideCerniera;
  modello_porta?: TModelloDetailPortaType;
  modello_vetrata?: TModelloDetailPortaType;
}
const initialStateStandard: TPorteVetrateStandardStoreState = {
  porta_prodotto: undefined,
  porta_listino: undefined,
  modello_porta: undefined,
  modello_vetrata: undefined
};
const initialStateCustom: TPorteVetrateCustomStoreState = {
  porta_prodotto: undefined,
  larghezza_porta: undefined,
  altezza_porta: undefined,
  larghezza_vetrata: undefined,
  altezza_vetrata: undefined,
  materiale: undefined,
  versione: undefined,
  modello_porta: undefined,
  modello_vetrata: undefined
};


export default function (state = { ...initialStateStandard, ...initialStateCustom }, action: Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetPortaVetrata: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ActionTypes.ActionGeneratoreSetPortaVetrataCustom: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
