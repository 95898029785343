import { FC } from "react";
import tailwindMyConfig from "../../../tailwind.my.config";

interface ITextCell extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  title: string;
  disabled?: boolean;
  htmlDecode?: boolean;
  isSecondary?: boolean;
}

const TextCell: FC<ITextCell> = ({ title, disabled, htmlDecode, isSecondary, ...props }: ITextCell) => {
  const styleText:any = {
    fontSize: 16,
    margin: 0,
    fontFamily:'avertastd-regularuploadedfile',
    padding: "2px 0",
    textAlign: "left",
  }
  return (
    <div style={{ width: "100%", padding:"0 10px 0 12px", margin: "auto", opacity: disabled ? 0.5 : 1, cursor: disabled ? "not-allowed": "pointer" }}
    className={`textcell ${isSecondary ? "secondary":"primary"}`}
    onClick={(e)=>{
      if(disabled){
        e.preventDefault()
        e.stopPropagation()
        return
      }
    }}>
      {
        htmlDecode ? 
        <div style={styleText} dangerouslySetInnerHTML={{
          __html: title}
         }></div>
        :
        <p
          style={styleText}
        >
        {title}
        </p>
        }
      <hr
        className={`separator ${isSecondary ? "bg-modal-effe" : "bg-fluo-effe"}`}
        style={{
          height: 1,
          width: "100%",
          margin: "auto",
        }}
      />
    </div>
  );
};
export default TextCell;
