import tailwindconfig from "./../../tailwind.my.config";
export default {
  control: (styles,a,b) => {
        delete styles['&:hover']
        delete styles['minHeight']
      return {
        ...styles,
        minHeight: 30,
        height: 30,
        backgroundColor: tailwindconfig.theme.extend.colors["fluo-effe"],
        '.error-field &':{
          backgroundColor: tailwindconfig.theme.extend.colors["warning-effe"],
        },
        borderRadius: 2,
        border: `0`,
        borderBottom: `0px`,
        padding: "0 7px 0 10px",
        boxShadow: `0px`,
        textAlign: 'left',
      }
  },

  menu: (provided, { selectProps }) => {
    return {
      ...provided,
      border: "0px",
      textAlign: 'left',
      marginTop:0,
      boxShadow:"unset"
    };
  },


  menuList: (provided, { selectProps }) => {
    return {
      ...provided,
      maxHeight: 345,
      paddingTop:0,
      paddingBottom:0,
      borderRadius: 2
    };
  },

  indicatorSeparator: () => {
    return {
      display: "none",
    };
  },

  indicatorsContainer: (style) => {
    return {
      textAlign: 'left',
      padding: '0 !important',
      '& > div': {
        padding: '0 !important',
      }
    };
  },

  container: (provided, { isFocused, isSelected }) => {
    return {
      border: "0px !important",
      padding: 0,
      textAlign: 'left',
    };
  },

  option: (provided, { isFocused, isSelected, ...props }) => {
    return {
      // ...provided,
      transition:"all .3s",
      margin: '0px',
      padding: props.onStyleLabel ? 0 : '8px 12px',
      backgroundColor: isSelected
        ? tailwindconfig.theme.extend.colors["fluo-effe"]
        : isFocused
        ? tailwindconfig.theme.extend.colors["modal-effe"]
        : tailwindconfig.theme.extend.colors["modal-effe"],
      color: isSelected ? tailwindconfig.theme.extend.colors["modal-effe"] : tailwindconfig.theme.extend.colors["divider"],
      '&:hover':{
        cursor: "pointer",
        backgroundColor: tailwindconfig.theme.extend.colors["fluo-effe"],
        color: tailwindconfig.theme.extend.colors["modal-effe"]
      },
      borderBottom: `0px solid ${tailwindconfig.theme.extend.colors["fluo-effe"]}`,
    };
  },

  menuPortal: (base) => {
    return{
      ...base,
      zIndex:999999
    }
  },

  singleValue: (provided, state) => {
    const transition = "opacity 300ms";
    return { ...provided, 
      transition, 
      textAlign: 'left' ,
      width: "100%",
      fontSize: 16,
      fontFamily:'avertastd-regularuploadedfile',
      paddingBottom: 0
    };
  },

  placeholder: (provided) => {
    return{
      ...provided,
      textAlign: 'left',
      width: "100%",
      fontSize: 16,
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontFamily:'avertastd-regularuploadedfile',
      textOverflow: "ellipsis",
      paddingBottom: 5,
      paddingTop: 5
    }
  },

  valueContainer: (provided) => {
    return {
      ...provided,
      width: "95%",
      padding:"2px 8px 2px 0",
      position: "relative",
      '& > div:last-child': {
        backgroundColor:"white !important"
        // display: "none"
      }
    };
  },

};
