import { Spin } from "antd";
import { FC } from "react";
import tailwindMyConfig from "../../../tailwind.my.config";

export enum IActionButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  Tertiary = "Tertiary"
}

interface IActionButton extends React.ButtonHTMLAttributes<HTMLElement> {
  importance: IActionButtonType;
  title: string;
  quantity?: number;
  isVisibleQuantity?: boolean;
  onPlus?: () => void;
  onMinus?: () => void;
  isLoading?: boolean;
  style?: any;
  icon?: boolean;
  isSelected?: boolean;
  disableHover?: boolean;
}

const ActionButton: FC<IActionButton> = ({
  importance,
  title,
  isVisibleQuantity,
  quantity,
  onPlus,
  onMinus,
  isLoading,
  style,
  icon,
  isSelected,
  disableHover,
  ...props
}: IActionButton) => {
  return (
    <button
      {...props}
      className={`action-btn configuratore-btn ${
        isVisibleQuantity ? "bg-transparent border-b border-solid border-black-effe" :
        importance == IActionButtonType.Primary
          ? "bg-transparent text-fluo-effe text-left underline"
          : (importance == IActionButtonType.Secondary ? `font-bold text-center text-black-effe ${!disableHover && 'hover:border-0 hover:bg-fluo-effe'} border-fluo-effe border-2 px-16 py-4` : 
          "text-grigio-effe bg-grigio-chiato-effe ")
      }  text-titleCard ${props.className}`}
      style={{ 
        zIndex: 2,
        opacity: props.disabled ? 0.5 : 1, 
        cursor: props.disabled ? "not-allowed" : "pointer", 
        ...style }}
    >

      {
      isLoading ? <Spin className={`${importance == IActionButtonType.Primary && "spin-fluo-effe"}`}/> 
      :(
      isVisibleQuantity ? 
      (
        <div className="flex items-center justify-center">
          <div className="flex flex-1 items-center justify-center">
            <button className="px-2 text-xl" onClick={onMinus} style={{minHeight:30}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="1" viewBox="0 0 20 1">
                <g id="Raggruppa_804" data-name="Raggruppa 804" transform="translate(0 -9.5)">
                  <path id="Tracciato_805" data-name="Tracciato 805" d="M0,0V20" transform="translate(20 10) rotate(90)" fill="none" stroke={tailwindMyConfig.theme.extend.colors["fluo-effe"]} strokeWidth="1"/>
                </g>
              </svg>  
            </button>
          </div>
          <div className="flex flex-1 px-4 items-center justify-center text-titleSectionGenerator text-black-effe">
            {quantity}
          </div>
          <div className="flex flex-1 items-center justify-center">
            <button className="px-2 text-xl" onClick={onPlus} style={{minHeight:30}}>
              <svg id="Raggruppa_803" data-name="Raggruppa 803" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Tracciato_804" data-name="Tracciato 804" d="M10882.131,545.5v20" transform="translate(-10872.131 -545.5)" fill="none" stroke={tailwindMyConfig.theme.extend.colors["fluo-effe"]} strokeWidth="1"/>
                <path id="Tracciato_805" data-name="Tracciato 805" d="M0,0V20" transform="translate(20 10) rotate(90)" fill="none" stroke={tailwindMyConfig.theme.extend.colors["fluo-effe"]} strokeWidth="1"/>
              </svg>
            </button>
          </div>
        </div>
      ) : 
      <div className={`flex items-center ${importance == IActionButtonType.Secondary && "font-bold"}`}>
        {title}
        {
          icon && 
          (
            isSelected ? 
            <svg className="icon-arrow-close ml-2" xmlns="http://www.w3.org/2000/svg" width="10.707" height="10.707" viewBox="0 0 10.707 10.707">
              <g id="Raggruppa_805" data-name="Raggruppa 805" transform="translate(-430.745 -699.293)">
                <path id="Tracciato_724" data-name="Tracciato 724" d="M8634.1,672.647l10,10" transform="translate(-8203 27)" fill="none" stroke="#2effba" strokeWidth="1"/>
                <path id="Tracciato_725" data-name="Tracciato 725" d="M8634.1,672.647l10,10" transform="translate(1113.745 -7934.452) rotate(90)" fill="none" stroke="#2effba" strokeWidth="1"/>
              </g>
            </svg>
            : 
          <svg className="icon-arrow ml-2" xmlns="http://www.w3.org/2000/svg" width="20.415" height="10.707" viewBox="0 0 20.415 10.707">
            <g id="Raggruppa_714" data-name="Raggruppa 714" transform="translate(-486 -490.706)">
              <path id="Tracciato_550" data-name="Tracciato 550" d="M6621,471h13.617" transform="translate(-6135 25.06)" fill="none" stroke="#2effba" strokeWidth="1"/>
              <path id="Tracciato_551" data-name="Tracciato 551" d="M6645.664,467.049l5,5-5,5" transform="translate(-6144.956 24.011)" fill="none" stroke="#2effba" strokeWidth="1"/>
            </g>
          </svg>)
        }

      </div>
      )
      }
    </button>
  );
};
export default ActionButton;
