import Action, { ActionTypes } from "../actionTypes";

//Vano Doccia
export type TVanoDocciaStoreState = {
  larghezza?: number;
  larghezza_inc?: number;
  profondita?: number;
  profondita_inc?: number;
  altezza?: number;
  altezza_inc?: number;
}
const initialState: TVanoDocciaStoreState = {
  larghezza: undefined,
  larghezza_inc: undefined,
  profondita: undefined,
  profondita_inc: undefined,
  altezza: undefined,
  altezza_inc: undefined
};


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionVanoDocciaSetData: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
