import { getLocalization, Localization } from "../../utils/utils";
import Action, { ActionTypes } from "../actionTypes";

//Struttura
export enum EStrutturaVanoDoccia {
  Cartongesso = "cartongesso",
  Muratura = "muratura",
  Poliestirene = "poliestirene",
}
export const getLabelEStrutturaVanoDoccia = (k: EStrutturaVanoDoccia) => {
  const key = String(k).toLowerCase()
  if (key === EStrutturaVanoDoccia.Cartongesso) return "vanodoccia_struttura.struttura.cartongesso"
  else if (key === EStrutturaVanoDoccia.Muratura) return "vanodoccia_struttura.struttura.muratura"
  else if (key === EStrutturaVanoDoccia.Poliestirene) return "vanodoccia_struttura.struttura.poliestirene"
  else return ""
}

//Rivestimento
export enum ERivestimentoVanoDoccia {
  Ceramica = "ceramica",
  Marmo = "marmo",
  Pietra = "pietra"
}
export const getLabelERivestimentoVanoDoccia = (k: ERivestimentoVanoDoccia) => {
  const key = String(k).toLowerCase()
  if (key === ERivestimentoVanoDoccia.Ceramica) return "vanodoccia_struttura.rivestimento.ceramica"
  else if (key === ERivestimentoVanoDoccia.Marmo) return "vanodoccia_struttura.rivestimento.marmo"
  else if (key === ERivestimentoVanoDoccia.Pietra) return "vanodoccia_struttura.rivestimento.pietra"
  else return ""
}

//Struttura Vano Doccia
export type TStrutturaVanoDocciaStoreState = {
  struttura?: EStrutturaVanoDoccia;
  rivestimento?: ERivestimentoVanoDoccia;
  kit_coibentazione?: boolean;
  add_kit_coibentazione_to_quote?: boolean;
}
const initialState: TStrutturaVanoDocciaStoreState = {
  struttura: undefined,
  rivestimento: undefined,
  kit_coibentazione: getLocalization() === Localization.EUROPA ? true : undefined,
  add_kit_coibentazione_to_quote: undefined
};


export default function (state = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.ActionStrutturaVanoDocciaSetData: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
