import axios from "axios";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Loader, { ELoader } from "../../components/Loader";
import { actGeneral } from "../../redux/actions";
import { TGeneral } from "../../redux/reducers";
import store from "../../redux/store";
import { setAuthInfo } from "../../utils/storage";
import jwt_decode from "jwt-decode";


function Callback({...props}){
    const history = useHistory();
    const [anErrorOccurred,setAnErrorOccurred] = useState<boolean>(false);
    const [errorAuth,setErrorAuth] = useState<String>("");
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(actGeneral({appLoading: true}))
        init()
    },[])

    const init = async () => {
        setErrorAuth("") //reset msg
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id_token = urlParams.get('id_token')
            if(!id_token){
                setAnErrorOccurred(true)
            }else{

                const decoded:{email:string} = jwt_decode(id_token);
                const responseVerifyUserAuthorization:any = await axios.get(`${process.env.REACT_APP_AUTH_WEBSITE_URI}/wp-json/steam-effe-api/v1/has-steam-permission?email=${decoded?.email}`)

                if(responseVerifyUserAuthorization.data?.has_permission){
                    setAuthInfo({
                        access_token: id_token
                    }, 
                        responseVerifyUserAuthorization.data?.user_id, 
                        responseVerifyUserAuthorization.data?.crm_language, 
                        decoded?.email)
                    history.replace("/")
                }else{
                    setErrorAuth(props.t("v2.error.ruolo_non_ammesso"))
                }                
            }
        } catch (error) {
            console.log(`Error during callback: ${error}`)
            setErrorAuth(props.t("v2.error.ruolo_non_ammesso"))
        }
    }
    return(<Loader loading={store.getState()?.general?.appLoading} type={ELoader.PRIMARY} error={anErrorOccurred || !!errorAuth} errorMsg={errorAuth}/>)
}

export default connect((state:TGeneral, ownProps) => ({
    general: state.general
  }), { actGeneral })(withTranslation()(Callback));