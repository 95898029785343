import NetworkClient from "..";
import { TGeneratoreModelDetailType, TGeneratoreType } from "../../redux/reducers/generatoreestint";
import { EKitCoibentazioneModel, TOptional, TOptionalDetailType } from "../../redux/reducers/optional";
import { TPancaType } from "../../redux/reducers/panche";
import { TListinoItemContentType, TMaterialDetails, TModelloDetailPortaType, TModelloPortaType } from "../../redux/reducers/porte_vetrate";
import { TRiepilogoPayload, TRiepilogoRequest } from "../../redux/reducers/riepilogo";
import { endpoint } from "../api";

//Generatori

export const getGeneratoriInterni = (start: number, limit: number): Promise<TGeneratoreType[]> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.generatori_interni(start, limit))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const getGeneratoriEsterni = (start: number, limit: number) => {
    return new Promise((resolve, reject) => {
      NetworkClient()
        .get(endpoint.generatori_esterni(start, limit))
        .then((res: any) => {
          resolve(res?.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
};

export const getKitCoibentazioneWithSlug = (model: EKitCoibentazioneModel): Promise<TOptionalDetailType[]> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.kit_coibentazione(model))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const getModelliDiProdotto = (id_prodotto: string) : Promise<TGeneratoreType> => {
    return new Promise((resolve, reject) => {
      NetworkClient()
        .get(endpoint.modelli_di_prodotto(id_prodotto))
        .then((res: any) => {
          resolve(res?.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
};

export const getModelloDiProdotto = (id_modello: string) : Promise<TGeneratoreModelDetailType> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.modello_di_prodotto(id_modello))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};


//Optionals

export const getOptionals = (start: number, limit: number) => {
    return new Promise((resolve, reject) => {
      NetworkClient()
        .get(endpoint.optionals(start, limit))
        .then((res: any) => {
          resolve(res?.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
};

export const getOptional = (id_optional: string) : Promise<TOptionalDetailType> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.optional(id_optional))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};


//Accessori
export const getAccessori = (start: number, limit: number) => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.accessori(start, limit))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

//Panche
export const getPanche = (start: number, limit: number) : Promise<TPancaType[]> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.panche(start, limit))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};



//Porte
export const getPorte = (is_standard: boolean, start: number, limit: number) => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(is_standard ? endpoint.prodotti_porte_standard(start, limit) : endpoint.prodotti_porte_custom(start, limit))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};


export const getPorta = (id_porta: string) => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.porta(id_porta))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const getModelloPorta = (id_product_porta: string) : Promise<TModelloDetailPortaType> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.model_porta(id_product_porta))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

//Items

export const getItem = (id_item: string) : Promise<TListinoItemContentType> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.item(id_item))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

//Materiale

export const getMateriale = (id_materiale: string) : Promise<TMaterialDetails> => {
  return new Promise((resolve, reject) => {
    NetworkClient()
      .get(endpoint.materiale(id_materiale))
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

// Riepilogo


export const addRiepilogo = (id: string, payload: TRiepilogoPayload) : Promise<any> => {
  return new Promise((resolve, reject) => {
    NetworkClient(true)
      .post(endpoint.quotes(), {
        id: id,
        data: payload
      })
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};


export const getRiepilogo = (id: any) : Promise<any> => {
  return new Promise((resolve, reject) => {
    NetworkClient(true)
      .get(endpoint.quotes(id))
      .then((res: any) => {
        resolve(res?.data?.data?.data as TRiepilogoPayload);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};