import { useRef, useState } from "react";
import {
  IconCloseMenu, IconWarning,
} from "../../components/Icon";
import ic_close from "./../../assets/images/closeicon.png"
import { H3 } from "../../components/Typography";
import tailwindMyConfig from "../../tailwind.my.config";
import { FC } from "react";
import { Modal as ModalAntD } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Gallery from "../Gallery";


export enum IModalType {
  Default = "Default",
  Success = "Success",
  Error = "Error",
  Warning = "Warning"
}

export enum ModalSize {
  sm="sm",
  lg="lg",
  xl="xl"
}
interface IModal extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  title: string;
  visible: boolean;
  onClose: () => void;
  cancelAction?: any;
  okAction?: any;
  modalType?: IModalType;
  size?: ModalSize;
  gallery?: string[]; //list of img
  footer?: React.ReactNode;
  htmlDecode?: boolean;
  closeInline?: boolean;
  contentClassName?:string;
  fullscreen?: boolean;
}

const Modal: FC<IModal> = ({ title, onClose, visible, contentClassName, fullscreen, cancelAction, okAction, htmlDecode, modalType, size, closeInline, gallery, footer, ...props }: IModal) => {
  const col = gallery?.length ? 6 : 12
  

  return (
    <ModalAntD
      id={`${fullscreen ? "modal-configuratore-fullscreen" : "modal-configuratore"}`}
      show={visible}
      size={size || ModalSize.lg}
      onHide={()=>{
        onClose && onClose()
      }}
      contentClassName={contentClassName}
      backdropClassName="blur"
    >
      <Row>
        <Col xs={12} sm={12} md={col} lg={col} xl={col} className="">
          <div>
          <Row>
            <Col className={`relative ${closeInline && "flex items-center"}`}>
              <button
                onClick={() => {
                  onClose && onClose()
                }}
                className={`${!closeInline && "absolute"} focus:outline-none py-2 pr-2`}
                style={{ top: 3.5, left: 0, zIndex: 9 }}>
                <img src={ic_close} className="xs:w-4 lg:w-5"/>
              </button>
              <div className="flex justify-center items-center ml-2">
                {
                htmlDecode ? 
                <div className="text-left text-black-effe font-h3 text-h3" style={{margin:0}} dangerouslySetInnerHTML={{
                  __html: title}
                }></div>
                :
                <H3 title={title} className="text-left text-black-effe font-bold" style={{margin:0, marginLeft:15,marginRight:15}} />
                }
              </div>
              </Col>
            </Row>
          </div>
          <div className="my-4 content-modal-wrapper">{props.children}</div>
          {okAction || cancelAction ? (
            <div className={`action flex ${!okAction || !cancelAction ? "justify-center" : "justify-evenly"} mt-2`}>
              {cancelAction && cancelAction}
              {okAction && okAction}
            </div>
          ) : null}
        </Col>
        {
          gallery?.length ?
          <Col xs={12} sm={12} md={col} lg={col} xl={col} className={"xs:hidden md:block flex flex-col justify-start"}>
              <Gallery gallery={gallery}/>
          </Col>
        : null}
      </Row>
    
      {footer}
    </ModalAntD>
  );
};

export default Modal;
