import logo_white from "./../../assets/images/Logo-Effe-Bianco.svg";
import logo from "./../../assets/images/logo_green.png";
import { Spin } from "antd";
import { FC } from "react";
import tailwindMyConfig from "../../tailwind.my.config";
import { withTranslation } from "react-i18next";
import ActionButton, { IActionButtonType } from "../Buttons/ActionButton";
import { useHistory } from "react-router";
import InnerLoader from "../InnerLoader";
import { motion } from 'framer-motion';

export enum ELoader {
  PRIMARY,
  SECONDARY
}

interface ILoader{
  type?: ELoader;
  error?: boolean;
  errorMsg?: String;
  t?: any;
  disableAction?: boolean;
  loading?: boolean;
}

const Loader: FC<ILoader> = ({ type, error, errorMsg, t, disableAction, loading, ...props }: ILoader) => {
  const history = useHistory();
  console.log(errorMsg, error)
  return (
      <div className={`fixed loader-effe ${loading ? "loader-loading" : "loader-hidden"} top-0 left-0 h-screen w-screen flex justify-center items-center`}>
        <div className="loader-background h-screen w-screen flex flex-col justify-center items-center">
          <div className="flex justify-center mt-8">
            {
              error ? 
              <div className="flex justify-center flex-col">
                {
                  <p className="text-fluo-effe">{errorMsg}</p>
                }
                {
                  !disableAction &&
                  <ActionButton importance={IActionButtonType.Primary} title={t("v2.riprova")} onClick={()=>{
                    history.replace("/")
                  }}
                  />
                }
              </div>
              :
              <InnerLoader/>
            }
          </div>
        </div>
      </div>
  );
}

export default withTranslation()(Loader);
