import { FC } from "react";
import { withTranslation } from "react-i18next";
import { isMobile } from "../../../utils/utils";
import ActionButton, { IActionButtonType } from "../../Buttons/ActionButton";
import { Text } from "../../Typography";

interface IConfigurationGridItem extends React.HtmlHTMLAttributes<HTMLElement> {
  title: string;
  isSelected: boolean;
  t?: any;
  onClick: () => void;
  img: any;
}

const ConfigurationGridItem: FC<IConfigurationGridItem> = ({ title, isSelected, onClick, img, ...props }: IConfigurationGridItem) => {
  const HEIGHT_CELL = !isMobile() ? 190 : 250
  return (
    <div className={`hover:cursor-pointer ${props.className}`} onClick={onClick}>
      <div style={{height:HEIGHT_CELL, overflow:"hidden"}} className={`gridItemImage rounded flex flex-col flex-1 justify-between items-center relative`}>
          <img src={img} style={{height:HEIGHT_CELL,zIndex:3}} className={`${isSelected ? "bg-hover-cell" : "bg-default-cell"} absolute w-full h-full top-0 left-0 p-4`}/>
          <div style={{height:HEIGHT_CELL}} className={`absolute top-0 left-0 w-full h-full transition-opacity opacity-0 ${isSelected && "opacity-30"} bg-fluo-effe`}></div>
      </div>
      <Text title={title} className="text-black-effe text-left font-bold my-2 text-textpopup uppercase"/>
    </div>
  );
};
export default withTranslation()(ConfigurationGridItem);
