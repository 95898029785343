import { Form, Select } from "antd";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import { TGeneral, TGeneralStore } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import {  actGeneral, actGeneratoreSetOptionals } from "../../../../redux/actions";
import Modal, { ModalSize } from "../../../../components/Modal";
import { useState } from "react";
import SelectableDetailGridItem from "../../../../components/GridItem/SelectableDetailGridItem";
import { TOptional, TOptionalDetailType, TOptionalStoreState } from "../../../../redux/reducers/optional";
import { OPTIONALS_PANNELLO_CONTROLLO, PLACEHOLDER_IMAGE, SLUG_OPTIONAL_STEAM_DIFFUSER_FINISHES, getLanguageForNSP } from "../../../../utils/consts";
import { useEffect } from "react";
import { getOptional } from "../../../../network/actions";
import { TGeneratoreModelStoreState } from "../../../../redux/reducers/generatoreestint";
import { getLocalization, Localization } from "../../../../utils/utils";
import { EGeneratoreType } from "../../../../redux/reducers/generatore";
import { IconFrecciaDown, IconInfo } from "../../../../components/Icon";
import tailwindMyConfig from "../../../../tailwind.my.config";
import ActionButton from "../../../../components/Buttons/ActionButton";
import { IActionButtonType } from "../../../../components/Buttons/ActionButton";
import AsyncSelect from "../../../../components/AsyncSelect";
import TextCell from "../../../../components/AsyncSelect/TextCell";
const { Option } = Select;

function Optional({ ...props }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const store: TGeneralStore = useStore();
  const finishesOptions = [{ id:"CH75000038 | PVD Matte Black Steam diffusers finishes", name:"PVD Matte Black" },
  { id:"CH75000039 | PVD Satin Nickel Steam diffusers finishes", name:"PVD Satin Nickel" },
  { id:"CH75000040 | PVD Polished Nickel Steam diffusers finishes", name:"PVD Polished Nickel" },
  { id:"CH75000041 | PVD Brushed Brass Steam diffusers finishes", name:"PVD Brushed Brass" }]
  const [tmpVariant, setTmpVariant] = useState<any>();

  const optional: TOptionalStoreState = useSelector(
    (state: TGeneral) => state.optional
  );
  const generatore_product: TGeneratoreModelStoreState= useSelector(
    (state: TGeneral) => state.generatore_product
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listOptionals, setListOptionals] = useState<TOptionalDetailType[]>([]);

  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});

  const openModal = (title: string, content: any, size: any) => {
    setModalData({ title, content, size });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
    setTmpVariant(undefined)
  };

  const getOptionalsOfProducts = async () => {
    setIsLoading(true)
    let optionals:TOptionalDetailType[] = []
    const optionals_of_product = generatore_product.generatore_product?.acf.optional_products_group.related_optional || []
    for (let index = 0; index < optionals_of_product.length; index++) {
      const opt: TOptional = optionals_of_product[index];
      try {
        const detailOptional = await getOptional(opt.ID)
        optionals.push(detailOptional)
      } catch (error) {
        setIsLoading(false)
      }
    }
    setIsLoading(false)

    //TODO: Check add LED
    //console.log("MOD PAN", store?.getState()?.panche?.panca_custom?.modello.id)

    setListOptionals(optionals)
  }

  useEffect(()=>{
    getOptionalsOfProducts();
  },[])

  const showExternalPanelIncluded: boolean = getLocalization()===Localization.AMERICA && store.getState()?.generatore?.tipologia===EGeneratoreType.Esterno
  return (
    <>
        <H3
            title={props.t("optional.titolo")}
            className={`text-left text-black-effe m-title ${showExternalPanelIncluded ? " mb-1" : " mb-4"}`}
            isLoading={isLoading}
        />
        {showExternalPanelIncluded
        && <Row><Col><p className="text-left text-wizard-effe mb-3 flex">
          <span>{"Our Steam generators always include a control panel that is supposed to be installed inside the steam room. In case you want to add an additional control panel outside the shower or for a commercial application select below the optional that fits your space"}</span></p></Col></Row>}
        
        <Row>
            <div className={"w-full"}>
              {listOptionals?.map((opt:TOptionalDetailType) => {
                  const indexOptionalSelected = optional?.optionals?.map(o => o.optional.id).indexOf(opt.id)
                  const isSelected = indexOptionalSelected !== -1
                  const disableRemove = store.getState()?.optional?.optionals?.find(o => opt.id===o.optional.id && o?.disableDelete)

                  /** 
                   * Nella lista optional nascondere Pannello Touch con sonda remotata per Nuvola Smart se selezionato Pannello touch per Nuvola Smart
                  */
                  const hasSelectedPannelloTouchConSondaRemotataForNSP = OPTIONALS_PANNELLO_CONTROLLO.ESTERNO?.[getLanguageForNSP()] == store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey === "panel_control")?.[0]?.optional?.id;
                  const hasSelectedPannelloTouchForNSP = OPTIONALS_PANNELLO_CONTROLLO.INTERNO?.[getLanguageForNSP()] == store?.getState()?.generatore_product?.optionalsForModel?.filter(o => o?.optKey === "panel_control")?.[0]?.optional?.id;
                  console.log({hasSelectedPannelloTouchConSondaRemotataForNSP,hasSelectedPannelloTouchForNSP})
                  
                  if((hasSelectedPannelloTouchForNSP && OPTIONALS_PANNELLO_CONTROLLO.ESTERNO?.[getLanguageForNSP()] == opt.id) || 
                  (hasSelectedPannelloTouchConSondaRemotataForNSP && OPTIONALS_PANNELLO_CONTROLLO.ESTERNO?.[getLanguageForNSP()] == opt.id) || 
                  (hasSelectedPannelloTouchConSondaRemotataForNSP && OPTIONALS_PANNELLO_CONTROLLO.INTERNO_OPTIONAL?.[getLanguageForNSP()] == opt.id)){
                    return null;
                  }

                  return (
                      <SelectableDetailGridItem
                          key={opt.id}
                          title={opt.title.rendered.replace(/(.*)-/g,"")}
                          isSelected={isSelected}
                          description={opt.description}
                          descriptionHtml={opt?.content?.rendered}
                          maxCharDescription={50}
                          className="mb-10"
                          image={opt._embedded?.["wp:featuredmedia"]?.[0]?.source_url || PLACEHOLDER_IMAGE}
                          // actionOnClick={()=>{
                          //   openModal(opt.title.rendered.replace(/(.*)-/g,""),()=>{
                          //     return(
                          //       <div dangerouslySetInnerHTML={{
                          //         __html: opt?.content?.rendered
                          //       }}></div>
                          //     )
                          //   },ModalSize.lg)
                          // }}
                          // actionDescription={props.t("general.maggiori_dettagli")}
                          buttonTitleSelect={props.t("general.aggiungi")}
                          onClick={disableRemove ? null : ()=>{
                            let _optionals = optional?.optionals || []

                            if(opt?.slug===SLUG_OPTIONAL_STEAM_DIFFUSER_FINISHES){
                              if(isSelected){
                                _optionals.splice(indexOptionalSelected!,1)
                                dispatch(actGeneratoreSetOptionals({
                                  optionals: _optionals
                              }))
                              }else{
                                openModal(opt.title.rendered.replace(/(.*)-/g,""),(modalProps:any)=>{
                                  return(
                                    <div>
                                      <h4>{"Select variation"}</h4>
                                      <div className="my-4 ">
  
                                      <AsyncSelect
                                          currValue={null}
                                          placeholder={"Select optional variation"}
                                          onChange={(val: any) => {
                                            setTmpVariant(val)
                                          }}
                                          onStyleLabel={(item: any) => {
                                            return <TextCell title={item.name} />;
                                          }}
                                          loadOptions={async (
                                            searchQuery: any,
                                            loadedOptions: any,
                                            { page }: any
                                          ) => {
  
                                            return {
                                              options: finishesOptions,
                                              hasMore: false,
                                              additional: {},
                                            };
                                          }}
                                        />
  
                                      </div>
                                      <ActionButton disabled={!!!modalProps?.tmpVariant?.name} title="Select" importance={IActionButtonType.Secondary} onClick={()=>{
                                          _optionals.push({optional: opt,quantity: 1, variant: modalProps?.tmpVariant?.id})
                                          closeModal();
                                          dispatch(actGeneratoreSetOptionals({
                                            optionals: _optionals
                                        }))
                                      }}/>
                                    </div>
                                  )
                                },ModalSize.lg)
                              }
                              
                          }else{
                              if(isSelected){
                                  _optionals.splice(indexOptionalSelected!,1)
                              }else if(!isSelected){
                                  _optionals.push({optional: opt,quantity: 1})
                              }
                              dispatch(actGeneratoreSetOptionals({
                                  optionals: _optionals
                              }))
                          }
 
                            
                          }}
                      />
                  )
              })}
            </div>
        </Row>

        <Modal
            title={modalData.title}
            onClose={() => closeModal()}
            visible={modalIsOpen}
            size={modalData.size}
            closeInline
        >
            {modalData.content && modalData.content({tmpVariant})}
        </Modal>
    </>
  );
}

export default connect((state:TGeneral, ownProps) => ({
  generatore: state.generatore,
  panche: state.panche
}), { actGeneratoreSetOptionals, actGeneral })(
  withTranslation()(Optional)
);
