import { Form } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import { TGeneral, TGeneralStore } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { actGeneratoreSetVoltaggio } from "../../../../redux/actions";
import AsyncSelect from "../../../../components/AsyncSelect";
import TextCell from "../../../../components/AsyncSelect/TextCell";
import { EVoltaggioType, getLabelEVoltaggioType, getVoltaggiExternal, getVoltaggiInternal, TVoltaggioStoreState } from "../../../../redux/reducers/voltaggio";
import { EGeneratoreType } from "../../../../redux/reducers/generatore";
import { SLUG_NUVOLA_SP_110, SLUG_NUVOLA_SP_140, SLUG_NUVOLA_SP_180, SLUG_NUVOLA_SP_250, SLUG_NUVOLA_SP_360, SLUG_NUVOLA_SP_90 } from "../../../../utils/consts";
import { StepErrorField } from "../..";

function Voltaggio({ ...props }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const voltaggio: TVoltaggioStoreState = useSelector(
    (state: TGeneral) => state.voltaggio
  );
  const store: TGeneralStore = useStore();

  return (
    <Form form={form} name="basic" layout={"vertical"} autoComplete={"off"}>
      <Row>
        <Col lg={12}>
          <Form.Item name="voltaggio" className={`${store.getState()?.general?.stepError?.fields?.find((s:StepErrorField) => s.id==="voltaggio")?.id && "error-field"}`}>
            <H3
              title={props.t("voltaggio.titolo")}
              className="text-left text-black-effe m-title"
            />
            <AsyncSelect
              currValue={voltaggio?.voltaggio ? {
                name: props.t(getLabelEVoltaggioType(voltaggio?.voltaggio as EVoltaggioType)),
                id: voltaggio?.voltaggio,
              } : null}
              placeholder={props.t("voltaggio.scegli_voltaggio")}
              onChange={(val: any) => {
                dispatch(
                    actGeneratoreSetVoltaggio({ voltaggio: val.id })
                );
              }}
              onStyleLabel={(item: any) => {
                return <TextCell title={item.name} />;
              }}
              loadOptions={async (
                searchQuery: any,
                loadedOptions: any,
                { page }: any
              ) => {
                var response: string[] = store?.getState()?.generatore.tipologia===EGeneratoreType.Esterno ? getVoltaggiExternal() : getVoltaggiInternal()
                if(
                store.getState()?.generatore_product?.generatore_model?.slug!==SLUG_NUVOLA_SP_90 &&  
                store.getState()?.generatore_product?.generatore_model?.slug!==SLUG_NUVOLA_SP_110 &&   
                store.getState()?.generatore_product?.generatore_model?.slug!==SLUG_NUVOLA_SP_140 &&  
                store.getState()?.generatore_product?.generatore_model?.slug!==SLUG_NUVOLA_SP_180 &&   
                store.getState()?.generatore_product?.generatore_model?.slug!==SLUG_NUVOLA_SP_250 && 
                store.getState()?.generatore_product?.generatore_model?.slug!==SLUG_NUVOLA_SP_360){
                  response = response.filter(g => g !== EVoltaggioType.v208_3ph)
                }
                return {
                  options: response?.
                  map((val: string) => {
                    return { id: val, name: props.t(getLabelEVoltaggioType(val as EVoltaggioType)) };
                  }),
                  hasMore: false,
                  additional: {},
                };
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default connect((state:TGeneral, ownProps) => ({
  general: state.general,
  generatore: state.generatore,
  generatore_product: state.generatore_product
}), { actGeneratoreSetVoltaggio })(
  withTranslation()(Voltaggio)
);
