import Action, { ActionTypes } from "../actionTypes";
import { TBetterFeaturedMediaType, TFeaturedMediaType } from "./media";
import { TListinoItemType } from "./porte_vetrate";

export enum EKitCoibentazioneModel{
  KIT_0_12 = "ha-55-01-0004-kit-coibentazione-fino-a-12-mq",
  KIT_12_15 = "ha-55-02-0002-kit-coibentazione-fino-a-15-mq",
  KIT_15_21 = "ha-55-03-0002-kit-coibentazione-fino-a-21-mq"
}
export type TOptional = {
  ID: string;
  post_title: string; 
}
export type TOptionalDetailType = {
    id: string;
    slug: string;
    name?: string;
    title: {
      rendered: string
    };
    content:{
      rendered: string;
    };
    acf: {
      item_relationship: TListinoItemType[];
      max_quantity_optional: number
    };
    _embedded: TFeaturedMediaType;
    better_featured_image: TBetterFeaturedMediaType;
    description: string; //TODO: Manca campo 
}
export type TOptionalQuantity = {
    optional: TOptionalDetailType;
    quantity: number;
    variant?: string;
    disableDelete?: boolean;
    optKey?: string;
}

export type TOptionalStoreState = {
    optionals?: TOptionalQuantity[];
    listNSPControlPanels?: TOptionalDetailType[];
    listNSPSteamDiffusors?: {
      [key: string]: {
        GLASS: TOptionalDetailType | undefined,
        CAMOUFLAGE: TOptionalDetailType | undefined,
        TRADITIONAL_CROMO: TOptionalDetailType | undefined,
        TRADITIONAL_NERO: TOptionalDetailType | undefined,
        TRADITIONAL_GUN: TOptionalDetailType | undefined
      }
    };
}
const initialState: TOptionalStoreState = {
    optionals: [],
    listNSPControlPanels: [],
    listNSPSteamDiffusors: {
      "1PZ":{
        GLASS: undefined,
        CAMOUFLAGE: undefined,
        TRADITIONAL_CROMO: undefined,
        TRADITIONAL_NERO: undefined,
        TRADITIONAL_GUN: undefined
      },
      "2PZ":{
        GLASS: undefined,
        CAMOUFLAGE: undefined,
        TRADITIONAL_CROMO: undefined,
        TRADITIONAL_NERO: undefined,
        TRADITIONAL_GUN: undefined
      },
      "4PZ":{
        GLASS: undefined,
        CAMOUFLAGE: undefined,
        TRADITIONAL_CROMO: undefined,
        TRADITIONAL_NERO: undefined,
        TRADITIONAL_GUN: undefined
      },
      "8PZ":{
        GLASS: undefined,
        CAMOUFLAGE: undefined,
        TRADITIONAL_CROMO: undefined,
        TRADITIONAL_NERO: undefined,
        TRADITIONAL_GUN: undefined
      }
    }
};


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetOptionals: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
