import { Form, InputNumber, Spin } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import { TGeneral, TGeneralStore } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { actGeneral, actPorteVetrateCustom, actPorteVetrateStandard, actResetPorteVetrateCustom, actResetPorteVetrateStandard } from "../../../../redux/actions";
import AsyncSelect from "../../../../components/AsyncSelect";
import TextCell from "../../../../components/AsyncSelect/TextCell";
import Modal, { ModalSize } from "../../../../components/Modal";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import SelectableDetailGridItem from "../../../../components/GridItem/SelectableDetailGridItem";
import { TVetrateStoreState } from "../../../../redux/reducers/vetrate";
import {
  EPortaStdCustomType,
  ESideCerniera,
  getLabelForESideCerniera,
  TListinoItemContentType,
  TMaterial,
  TMaterialDetails,
  TModelloDetailPortaType,
  TModelloPortaType,
  TPortaType as TPortaTypeDefault,
  TPorteVetrateCustomStoreState,
  TPorteVetrateStandardStoreState,
} from "../../../../redux/reducers/porte_vetrate";
import ConfigurationGridItem from "../../../../components/GridItem/ConfigurationGridItem";
import { getCategoryIdModelloPortaVetrate, getUnitOfMeasure, isVetroPortaVetroPortaModel, UnitOfMeasure } from "../../../../utils/utils";
import ActionButton, { IActionButtonType } from "../../../../components/Buttons/ActionButton";
import { LIMIT, MAX_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM, MAX_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM, MIN_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM, MIN_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM, MIN_DIM_LARGHEZZA_VETRATA_CUSTOM, PLACEHOLDER_IMAGE, SLUG_ADAPTA_CUSTOM, SLUG_PRODOTTO_SPAZIO, SLUG_PRODOTTO_SPAZIOFILO } from "../../../../utils/consts";
import { getItem, getMateriale, getModelloPorta, getPorte } from "../../../../network/actions";
import { useEffect } from "react";
import { TVanoDocciaStoreState } from "../../../../redux/reducers/vano_doccia";
import { StepConfiguratore, StepErrorField } from "../..";
import MsgError from "../../../../components/MsgError";
import { ConfigurationType } from "../../../../redux/reducers/scelta_configurazione";
import { IconAccordionClosed, IconAccordionOpened } from "../../../../components/Icon";

type TPortaType = TPortaTypeDefault & {disabled?: boolean}

function PorteVetrate({ ...props }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const store: TGeneralStore = useStore();
  const vano_doccia: TVanoDocciaStoreState = useSelector(
    (state: TGeneral) => state.vano_doccia
  );
  const vetrate: TVetrateStoreState = useSelector(
    (state: TGeneral) => state.vetrate
  );
  const porta_vetrata: TPorteVetrateStandardStoreState & TPorteVetrateCustomStoreState = useSelector(
    (state: TGeneral) => state.porta_vetrata
  );

  const [currentAccordion, setCurrentAccordion] = useState<number | null>(null);
  const [isLoadingStd, setIsLoadingStd] = useState<boolean>(true);
  const [isLoadingCtm, setIsLoadingCtm] = useState<boolean>(true);
  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});

  //Standard
  const [offsetPorteStandard, setOffsetPorteStandard] = useState<number>(0);
  const [hasMorePorteStandard, setHasMorePorteStandard] = useState<boolean>(true);
  const [listPorteStandard, setListPorteStandard] = useState<TPortaType[]>([]);
  //Custom
  const [offsetPorteCustom, setOffsetPorteCustom] = useState<number>(0);
  const [hasMorePorteCustom, setHasMorePorteCustom] = useState<boolean>(true);
  const [listPorteCustom, setListPorteCustom] = useState<TPortaType[]>([]);

  const [currentMaterialPorta, setCurrentMaterialPorta] = useState<TMaterial>();
  const [currentSideCernieraPorta, setCurrentSideCernieraPorta] = useState<ESideCerniera>();

  const [items, setItems] = useState<TListinoItemContentType[]>([]);
  const [isLoadingItems, setIsLoadingItems] = useState<boolean>(false);

  const [materials, setMaterials] = useState<TMaterialDetails[]>([]);


  const openModal = (title: string, content: any, size: any) => {
    setModalData({ title, content, size });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
    //reset currents
    setItems([]);
    setCurrentMaterialPorta(undefined)
    setCurrentSideCernieraPorta(undefined)
    dispatch(actGeneral({ stepError: undefined }))
  };

  useEffect(() => {
    getPorteStandardFromAPI()
    getPorteCustomFromAPI()
  }, [])

  const getUniqueMaterialsFromItems = (allItems: TListinoItemContentType[]) => {
    let _ = new Set()
    let _items: TListinoItemContentType[] = []
    allItems.forEach(it => {
      const idMaterial: any = it.acf?.materials?.[0]?.ID;
      if (idMaterial && idMaterial !== undefined && !_.has(idMaterial)) {
        _.add(idMaterial);
        _items.push(it)
      }
    });
    return _items
  }
  const getUniqueVersionsFromItems = (allItems: TListinoItemContentType[]) => {
    let _ = new Set()
    let _items: TListinoItemContentType[] = []
    allItems.forEach(it => {
      const idVersion = it.acf?.side_cerniera;
      if (idVersion && idVersion != undefined && !_.has(idVersion)) {
        _.add(idVersion);
        _items.push(it)
      }
    });
    return _items
  }

  const getPorteStandardFromAPI = async () => {
    try {
      setIsLoadingStd(true)
      const responsePorteVetrateStandardList: TPortaType[] = await getPorte(true, offsetPorteStandard, LIMIT) as TPortaType[]

      let porteStandard: TPortaType[] = []
      /**
       * Verify if porta has at least one vetrata
      */
      for (const porta of responsePorteVetrateStandardList) {
        if(store.getState()?.vetrate?.quantita === 2){
          let hasAtLeastOneVetrata = false;
          for (let index = 0; index < porta?.acf?.models_relationship.length; index++) {
            const model = porta?.acf?.models_relationship[index];
            try {
              const _modelPortaOrVetrata = await getModelloPorta(model.ID)
              if (_modelPortaOrVetrata.categoria_modello_pv?.includes(getCategoryIdModelloPortaVetrate(false))) {
                hasAtLeastOneVetrata = true;
              }
    
            } catch (error) { }
          }
          porteStandard.push({...porta, disabled: !hasAtLeastOneVetrata})
        }else{
          porteStandard.push({...porta})
        }

      }

      setListPorteStandard(porteStandard.map(d => {
        return ({ ...d, acf: { ...d.acf, tipologia_di_prodotto: EPortaStdCustomType.STANDARD } })
      }))
      setOffsetPorteStandard(offsetPorteStandard + LIMIT)
      setHasMorePorteStandard(porteStandard?.length > 0 && porteStandard?.length % LIMIT === 0)
    } catch (err) {
      //TODO: Alert
    } finally {
      setIsLoadingStd(false)
    }
  }
  const getPorteCustomFromAPI = async () => {
    try {
      setIsLoadingCtm(true)
      const data: any = await getPorte(false, offsetPorteCustom, LIMIT)
      setListPorteCustom(data)
      setOffsetPorteCustom(offsetPorteCustom + LIMIT)
      setHasMorePorteCustom(data?.length > 0 && data?.length % LIMIT === 0)
      setIsLoadingCtm(false)
    } catch (err) {
      //TODO: Alert
      setIsLoadingCtm(false)
    }
  }

  const areDimensionsOfPortaVetrataGreaterThanVano = (): boolean => {
    if (store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE) return false
    return areDimensionsOfPortaGreaterThanVano() || areDimensionsOfVetrataGreaterThanVano()
  }

  const areDimensionsOfPortaGreaterThanVano = (): boolean => {
    if (store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE) return false

    if (store.getState().porta_vetrata?.modello_porta && isVetroPortaVetroPortaModel(store.getState().porta_vetrata.modello_porta!)) return false
    if (store.getState()?.vetrate?.quantita === 1 || !isRequiredVetrata()) {
      return store.getState()?.porta_vetrata?.altezza_porta! > store.getState()?.vano_doccia?.altezza! ||
        store.getState()?.porta_vetrata?.larghezza_porta! > Math.max(store.getState()?.vano_doccia?.larghezza!, store.getState()?.vano_doccia?.profondita!)
    } else {
      const maxDimVano = Math.max(store.getState()?.vano_doccia?.larghezza || 0, store.getState()?.vano_doccia?.profondita || 0)
      const minDimVano = Math.min(store.getState()?.vano_doccia?.larghezza || 0, store.getState()?.vano_doccia?.profondita || 0)
      const existOneRightCase = (store.getState()!.porta_vetrata!.larghezza_porta! <= maxDimVano) || (store.getState()!.porta_vetrata!.larghezza_porta! <= minDimVano)

      return store.getState()?.porta_vetrata?.altezza_porta! > store.getState()?.vano_doccia?.altezza! ||
        !existOneRightCase
    }
  }
  const areDimensionsOfVetrataGreaterThanVano = (): boolean => {
    if (store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE) return false

    if (store.getState().porta_vetrata?.modello_porta && isVetroPortaVetroPortaModel(store.getState().porta_vetrata.modello_porta!)) return false
    if (store.getState()?.vetrate?.quantita === 1 || !isRequiredVetrata()) {
      return false
    } else {
      const maxDimVano = Math.max(store.getState()?.vano_doccia?.larghezza || 0, store.getState()?.vano_doccia?.profondita || 0)
      const minDimVano = Math.min(store.getState()?.vano_doccia?.larghezza || 0, store.getState()?.vano_doccia?.profondita || 0)
      const existOneRightCase = (store.getState()!.porta_vetrata!.larghezza_vetrata! <= minDimVano) || (store.getState()!.porta_vetrata!.larghezza_vetrata! <= maxDimVano)
      return store.getState()?.porta_vetrata?.altezza_vetrata! > store.getState()?.vano_doccia?.altezza! ||
        !existOneRightCase
    }
  }



  const isRequiredVetrata = () => store.getState()?.vetrate.quantita === 2 && !isVetroPortaVetroPortaModel(store.getState().porta_vetrata.modello_porta!)

  const isVetrataSectionVisible = () => store.getState()?.vetrate.quantita === 2 && !!store.getState()?.porta_vetrata?.modello_porta

  const onChangeModelloPorta = async (modello: TModelloDetailPortaType) => {
    setIsLoadingItems(true)
    dispatch(actPorteVetrateStandard({
      modello_porta: modello,
    }))

    getModelloPorta(modello.id).then(async (res: TModelloDetailPortaType) => {
      //Set items
      let items = []
      for (let indexItem = 0; indexItem < res.acf.item_relationship.length; indexItem++) {
        const item = res.acf.item_relationship[indexItem];
        try {
          const resItem: TListinoItemContentType = await getItem(item.ID)
          items.push(resItem)
        } catch (err) { }
      }
      setItems(items)

      //Materials
      let _materials: TMaterialDetails[] = []
      const unique_items_for_material = getUniqueMaterialsFromItems(items)
      const unique_items_for_version = getUniqueVersionsFromItems(items)

      for (let index = 0; index < unique_items_for_material.length; index++) {
        try {
          let m: TMaterialDetails = await getMateriale(unique_items_for_material[index]?.acf?.materials?.[0]?.ID)
          _materials.push(m)
        } catch (error) { }
      }
      setMaterials(_materials)

      //Se esiste un solo materiale lo pre-seleziono
      if (_materials.length === 1) {
        setCurrentMaterialPorta(unique_items_for_material?.[0]?.acf?.materials?.[0])
      }
      if (unique_items_for_version.length === 1) {
        setCurrentSideCernieraPorta(unique_items_for_version?.[0]?.acf?.side_cerniera)
      }


      setIsLoadingItems(false)
    }).catch((e: any) => {
      setIsLoadingItems(false)
    })
  }

  const areDimensionUnavailable = (): boolean => {
    return areDimensionPortaUnavailable() || areDimensionVetrataUnavailable()
  }

  const areDimensionPortaUnavailable = (): boolean => {
    if (store.getState().porta_vetrata?.modello_porta && isVetroPortaVetroPortaModel(store.getState().porta_vetrata.modello_porta!)) return false
    return (!!store.getState()?.porta_vetrata && (store.getState().porta_vetrata.altezza_porta! < MIN_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM || store.getState().porta_vetrata.altezza_porta! > MAX_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM || store.getState().porta_vetrata.larghezza_porta! < MIN_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM || store.getState().porta_vetrata.larghezza_porta! > MAX_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM))
  }
  const areDimensionVetrataUnavailable = (): boolean => {
    return (!!store.getState()?.porta_vetrata && isRequiredVetrata() && (store.getState().porta_vetrata.altezza_vetrata! < MIN_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM || store.getState().porta_vetrata.altezza_vetrata! > MAX_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM || store.getState().porta_vetrata.larghezza_vetrata! < MIN_DIM_LARGHEZZA_VETRATA_CUSTOM || store.getState().porta_vetrata.larghezza_vetrata! > MAX_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM))
  }

  const isDisabledProdottoPortaVetrata = (porta: TPortaType) => {
    if(!!porta?.disabled) return true
    // console.log(`isDisabledProdottoPortaVetrata ${porta?.slug} [L ${porta.acf?.min_larghezza_modello_porta} - A ${porta.acf?.min_altezza_modello_porta}]`)
    if (store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE) return false

    return porta.acf?.min_altezza_modello_porta === undefined ||
      Number(porta.acf?.min_altezza_modello_porta) > store.getState().vano_doccia.altezza! ||
      porta.acf?.min_larghezza_modello_porta === undefined ||
      Number(porta.acf?.min_larghezza_modello_porta) > Math.max(store.getState()?.vano_doccia?.larghezza!, store.getState()?.vano_doccia?.profondita!) ||
      (store.getState()?.vetrate?.quantita === 2 && (
        // (!porta.acf?.min_altezza_modello_vetrata && !porta.acf?.min_larghezza_modello_vetrata) ||
        // porta.acf?.min_altezza_modello_vetrata===undefined || 
        Number(porta.acf?.min_altezza_modello_vetrata) > store.getState().vano_doccia.altezza! ||
        // porta.acf?.min_larghezza_modello_vetrata===undefined || 
        Number(porta.acf?.min_larghezza_modello_vetrata) > Math.max(store.getState()?.vano_doccia?.larghezza!, store.getState()?.vano_doccia?.profondita!)
      )
      )
  }

  return (
    <div>
      <H3
        title={props.t("porte_vetrate.titolo")} className="text-left text-black-effe m-title  mb-4"
      />

      <Row>
        <Col lg={12}>
          <Accordion>
            <div className="">
              <Accordion.Toggle
                onClick={() => {
                  setCurrentAccordion(
                    currentAccordion === null || currentAccordion !== 0
                      ? 0
                      : null
                  );
                }}
                eventKey="0"
                className="w-full"
              >
                <div
                  className={`bg-fluo-effe flex  items-center relative py-2`}
                >
                  <span
                    className="text-white font-bold text-titlepopup pl-24"
                  >
                    {props.t("porte_vetrate.standard")}
                  </span>
                  <div className="absolute right-8">
                    {
                    isLoadingStd ? <Spin className="spin-white max-w-[20px] max-h-[20px]" /> :
                    
                    currentAccordion === 0 ?  (
                      <IconAccordionClosed/>
                    ) : (
                      <IconAccordionOpened/>
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey="0"
                className="">
                <div className="">
                  {listPorteStandard.map((porta: TPortaType, indexPorta: number) => {
                    const isSelected = porta_vetrata?.porta_prodotto?.id == porta.id;
                    return (
                      <SelectableDetailGridItem
                        key={porta.id}
                        title={porta.title.rendered}
                        subtitle={props.t(
                          "porte_vetrate.vetrate.modelli"
                        )}
                        classNameTitleDefault={`text-modal-effe`}
                        isSelected={isSelected}
                        description={(porta.acf.models_relationship || []).map((m: TModelloPortaType) => m.post_title).join(', ')}
                        disabled={isDisabledProdottoPortaVetrata(porta)}
                        className={`pt-12 ${indexPorta === listPorteStandard.length - 1 && "pb-12"}`}
                        image={porta._embedded?.["wp:featuredmedia"]?.[0]?.source_url || PLACEHOLDER_IMAGE}
                        onClick={async () => {
                          //Reset porte custom
                          dispatch(actResetPorteVetrateCustom())

                          if (!isSelected) {
                            dispatch(actPorteVetrateStandard({
                              porta_listino: undefined,
                              porta_prodotto: porta,
                              modello_porta: undefined,
                              modello_vetrata: undefined
                            }))

                            dispatch(actGeneral({ appLoading: true }))
                            let _portaModels: TModelloDetailPortaType[] = []
                            let _vetrataModels: TModelloDetailPortaType[] = []
                            for (let index = 0; index < porta?.acf?.models_relationship.length; index++) {
                              const model = porta?.acf?.models_relationship[index];
                              try {
                                const _modelPortaOrVetrata = await getModelloPorta(model.ID)
                                if (_modelPortaOrVetrata.categoria_modello_pv?.includes(getCategoryIdModelloPortaVetrate(true))) {
                                  _portaModels.push(_modelPortaOrVetrata)
                                } else {
                                  _vetrataModels.push(_modelPortaOrVetrata)
                                }

                              } catch (error) { }
                            }


                            //Filter Porte
                            _portaModels = _portaModels.map(coll => {
                              return ({
                                ...coll,
                                is_visible: store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE ||
                                  (Number(coll.acf?.standard_configuration_fields?.x_axis_door) <= (store.getState()?.vano_doccia?.larghezza! > store.getState()?.vano_doccia?.profondita! ? store.getState()?.vano_doccia?.larghezza! : store.getState()?.vano_doccia?.profondita!) &&
                                    Number(coll.acf?.standard_configuration_fields?.y_axis_door) <= store.getState()?.vano_doccia?.altezza!)
                              })
                            })

                            //Select 1st porta available
                            if (!store?.getState()?.porta_vetrata?.modello_porta && _portaModels.filter(p => p.is_visible).length > 0) {
                              onChangeModelloPorta(_portaModels.filter(p => p.is_visible)[0])
                            }

                            //Filter Vetrate
                            const getVetrateFiltered = (_listVetrateToFilter: TModelloDetailPortaType[]): TModelloDetailPortaType[] => {
                              return _listVetrateToFilter.map(coll => {
                                return ({
                                  ...coll,
                                  is_visible: store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE ||
                                    (Number(coll.acf?.standard_configuration_fields?.x_axis_door) <= (store.getState()?.vano_doccia?.larghezza! > store.getState()?.vano_doccia?.profondita! ? store.getState()?.vano_doccia?.larghezza! : store.getState()?.vano_doccia?.profondita!) &&
                                      Number(coll.acf?.standard_configuration_fields?.y_axis_door) <= store.getState()?.vano_doccia?.altezza!)
                                })
                              })
                            }
                            _vetrataModels = getVetrateFiltered(_vetrataModels)

                            //Fallback su altri modelli se vetrate non disponibili
                            if (!_vetrataModels.length && store.getState()?.vetrate?.quantita === 2) {
                              let other_models_relationship: TModelloPortaType[] = []
                              listPorteStandard.forEach(currentPorta => {
                                if (currentPorta.slug === SLUG_PRODOTTO_SPAZIO || currentPorta.slug === SLUG_PRODOTTO_SPAZIOFILO)
                                  other_models_relationship = other_models_relationship.concat(currentPorta.acf?.models_relationship)
                              });
                              // get details
                              for (let index = 0; index < other_models_relationship.length; index++) {
                                const model_fallback = other_models_relationship[index];
                                try {
                                  const _modelPortaOrVetrata = await getModelloPorta(model_fallback.ID)
                                  if (_modelPortaOrVetrata.categoria_modello_pv?.includes(getCategoryIdModelloPortaVetrate(false))) {
                                    _vetrataModels.push(_modelPortaOrVetrata)
                                  }

                                } catch (error) { }
                              }
                              // filter
                              _vetrataModels = getVetrateFiltered(_vetrataModels)
                            }

                            //Select 1st vetrata available
                            const qty_models_vetrata = _vetrataModels.filter(v => v.is_visible).length
                            if (store.getState()?.vetrate?.quantita === 2 && qty_models_vetrata > 0) {
                              dispatch(actPorteVetrateStandard({
                                modello_vetrata: _vetrataModels.filter(v => v.is_visible)[0]
                              }))
                            }

                            dispatch(actGeneral({ appLoading: false }))

                            openModal(porta.title.rendered, () => {

                              return (<div>
                                {
                                  _portaModels.filter(m => m.is_visible).length ?
                                    <Form form={form} name="basic" layout={"vertical"} autoComplete={"off"}>
                                      <Row>
                                        <Col lg={isVetrataSectionVisible() ? 6 : 12} md={isVetrataSectionVisible() ? 6 : 12} className="mb-8">
                                          <Form.Item name="modello_porta_std"
                                            className={`${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "modello_porta_std")?.id && "error-field"}`}>
                                            <AsyncSelect
                                              currValue={store.getState()?.porta_vetrata?.modello_porta ? {
                                                name: store.getState()?.porta_vetrata?.modello_porta?.title?.rendered,
                                                id: store.getState()?.porta_vetrata?.modello_porta?.id,
                                              } : null}
                                              noOptionsMessage={props.t("v2.portevetrate.error.dim_vano_maggiori")}
                                              placeholder={props.t("general.scegli_opzione")}
                                              onChange={(modello: TModelloDetailPortaType) => {
                                                onChangeModelloPorta(modello)
                                              }}
                                              onStyleLabel={(item: any) => {
                                                return <TextCell isSecondary title={item.name} disabled={!item.is_visible} />;
                                              }}
                                              loadOptions={async (
                                                searchQuery: any,
                                                loadedOptions: any,
                                                { page }: any
                                              ) => {
                                                console.log("CHECK DIM (PORTA)", _portaModels.map((r) => r.acf?.standard_configuration_fields), store.getState()?.vano_doccia)
                                                return {
                                                  options: _portaModels.filter(m => m.is_visible).map((coll: TModelloDetailPortaType) => {
                                                    return { name: coll.title.rendered, ...coll };
                                                  }),
                                                  hasMore: false,
                                                  additional: {},
                                                };
                                              }}
                                              isSecondary
                                            />
                                          </Form.Item>
                                        </Col>

                                        {isVetrataSectionVisible() &&
                                          <Col lg={6} md={6} className="mb-8">
                                            <Form.Item name="modello_vetrata_std"
                                              className={`${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "modello_vetrata_std")?.id && "error-field"}`}>
                                              <AsyncSelect
                                                currValue={store.getState()?.porta_vetrata?.modello_vetrata ? {
                                                  name: store.getState()?.porta_vetrata?.modello_vetrata?.title?.rendered,
                                                  id: store.getState()?.porta_vetrata?.modello_vetrata?.id,
                                                } : null}
                                                noOptionsMessage={props.t("v2.portevetrate.error.dim_vano_maggiori")}
                                                placeholder={props.t("v2.portevetrate.scegli_vetrata")}
                                                onChange={(modello: TModelloDetailPortaType) => {
                                                  dispatch(actPorteVetrateStandard({
                                                    modello_vetrata: modello
                                                  }))
                                                }}
                                                onStyleLabel={(item: any) => {
                                                  return <TextCell isSecondary title={item.name} disabled={!item.is_visible} />;
                                                }}
                                                loadOptions={async (
                                                  searchQuery: any,
                                                  loadedOptions: any,
                                                  { page }: any
                                                ) => {
                                                  const response: TModelloDetailPortaType[] = _vetrataModels
                                                  console.log("VETRATE", response)
                                                  console.log("CHECK DIM (VETRATA)", response.map((r) => r.acf?.standard_configuration_fields), store.getState()?.vano_doccia)
                                                  return {
                                                    options: response.filter(m => m.is_visible).map((coll: TModelloDetailPortaType) => {
                                                      return { name: coll.title.rendered, ...coll };
                                                    }),
                                                    hasMore: false,
                                                    additional: {},
                                                  };
                                                }}
                                                isSecondary
                                              />
                                            </Form.Item>
                                          </Col>
                                        }
                                      </Row>
                                    </Form>
                                    :
                                    <p className="text-center">
                                      {props.t("v2.no_models", { prodotto: porta.title.rendered, dimensioni: `${store.getState()?.vano_doccia?.larghezza} x ${store.getState()?.vano_doccia?.profondita} x ${store.getState()?.vano_doccia?.altezza}h` })}
                                    </p>
                                }
                              </div>)
                            }, isRequiredVetrata() ? ModalSize.xl : ModalSize.lg)
                          } else {
                            dispatch(actResetPorteVetrateStandard())
                          }
                        }}
                        classNameImage={""}
                      />
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </div>

            <div className="mt-2">
              <Accordion.Toggle
                onClick={() => {
                  setCurrentAccordion(
                    currentAccordion === null || currentAccordion !== 1
                      ? 1
                      : null
                  );
                }}
                eventKey="1"
                className="w-full"
              >
                <div
                  className={`bg-fluo-effe flex items-center relative py-2`}
                >
                  <span
                    className="text-white font-bold text-titlepopup pl-24"
                  >
                    {props.t("porte_vetrate.su_misura")}
                  </span>
                  <div className="absolute right-8">
                    {currentAccordion === 1 ? (
                      <IconAccordionClosed/>
                    ) : (
                      <IconAccordionOpened/>
                    )}
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey="1"
                className=""
              >
                <div className="">
                  {listPorteCustom?.filter(p => 
                  (store?.getState()?.vetrate?.quantita===1 && p?.slug===SLUG_ADAPTA_CUSTOM) || 
                  p?.slug!==SLUG_ADAPTA_CUSTOM )?.map((coll: TPortaType, indexPorta: number) => {
                    const isSelected = porta_vetrata?.porta_prodotto?.id == coll.id;
                    return (
                      <SelectableDetailGridItem
                        key={coll.id}
                        title={coll.title?.rendered}
                        subtitle={props.t(
                          "porte_vetrate.vetrate.materiali_caratteristiche"
                        )}
                        classNameTitleDefault={`text-modal-effe`}
                        isSelected={isSelected}
                        description={coll.content?.rendered?.replace(/(<([^>]+)>)/ig, '')}
                        maxCharDescription={80}
                        className={`pt-12 ${indexPorta === listPorteCustom.length - 1 && "pb-12"}`}
                        image={coll._embedded?.["wp:featuredmedia"]?.[0]?.source_url || PLACEHOLDER_IMAGE}
                        buttonTitleSelect={props.t("general.configura")}
                        onClick={async () => {
                          //Reset porte standard
                          dispatch(actResetPorteVetrateStandard())

                          if (!isSelected) {
                            dispatch(actGeneral({ appLoading: true }))
                            dispatch(actPorteVetrateCustom({
                              porta_prodotto: coll,
                              altezza_porta: coll?.slug===SLUG_ADAPTA_CUSTOM ? 200 : undefined
                            }))
    

                            //Scarico dettaglio modelli
                            let _portaModels: TModelloDetailPortaType[] = []
                            let _vetrataModels: TModelloDetailPortaType[] = []
                            for (let index = 0; index < coll?.acf?.models_relationship?.length; index++) {
                              const model = coll?.acf?.models_relationship[index];
                              try {
                                const modelType = await getModelloPorta(model.ID)
                                if (modelType.categoria_modello_pv?.includes(getCategoryIdModelloPortaVetrate(true))) {
                                  _portaModels.push(modelType)
                                } else if (isRequiredVetrata() && modelType.categoria_modello_pv?.includes(getCategoryIdModelloPortaVetrate(false))) {
                                  _vetrataModels.push(modelType)
                                }
                              } catch (error) { }
                            }

                            //Scarico dettaglio materiali
                            let _materials: TMaterialDetails[] = []
                            for (let index = 0; index < coll?.acf?.custom_configuration_fields?.material_door_custom?.length; index++) {
                              const material = coll?.acf?.custom_configuration_fields?.material_door_custom[index];
                              try {
                                let m: TMaterialDetails = await getMateriale(material.ID)
                                _materials.push(m)
                              } catch (error) { }
                            }


                            dispatch(actGeneral({ appLoading: false }))

                            // Opening custom doors modal
                            openModal(
                              coll.title?.rendered,
                              () => {
                                console.log("TODO: Handle Adapta")
                                return (
                                  <div>
                                    <div>
                                      <Row className="justify-around">
                                        {/** PORTA */}
                                        <Col xs={12} sm={12} md={isRequiredVetrata() ? 6 : 12} lg={isRequiredVetrata() ? 6 : 12} className="px-0 mx-0">
                                          <Row>
                                            <Col>
                                              <H3 title={props.t("porte_vetrate.porte.titolo")} className="text-verde-effe font-bold text-titleSectionGenerator" />
                                            </Col>
                                          </Row>
                                          <Row className={""}>
                                            {_portaModels.map((it: TModelloDetailPortaType, itIndex) => {
                                              const isModelSelected = store.getState()?.porta_vetrata?.modello_porta?.id === it.id;
                                              return (
                                                <Col key={it.id} sm={6} md={6} lg={6}>
                                                  <ConfigurationGridItem
                                                    isSelected={isModelSelected}
                                                    title={it.title.rendered}
                                                    className={`mb-4 xs:pr-0 ${isRequiredVetrata() ? "lg:pr-3" : "pr-0"}`}
                                                    img={it?._embedded?.["wp:featuredmedia"]?.[0]?.source_url || PLACEHOLDER_IMAGE}
                                                    onClick={() => {
                                                      dispatch(actPorteVetrateCustom({
                                                        modello_porta: isModelSelected ? undefined : it,
                                                        ...(isVetroPortaVetroPortaModel(it) ? { modello_vetrata: undefined } : {})
                                                      }))

                                                    }}
                                                  />
                                                </Col>
                                              )
                                            })}
                                          </Row>

                                          <div className="mt-4">

                                            <Form
                                              name="basic"
                                              layout={"vertical"}
                                              autoComplete={"off"}
                                              className="w-full"
                                            >
                                              <Row>
                                                <Col xs={12} className={`${isRequiredVetrata() ? "px-0 mx-0" : ""}`}>
                                                  <p className="text-verde-effe font-bold text-titleSectionGenerator">
                                                    {`${props.t("porte_vetrate.dim_porta")} (cm)`}
                                                  </p>
                                                </Col>
                                                <Col xs={isRequiredVetrata() ? 12 : 6} className={`${isRequiredVetrata() ? "px-0 mx-0" : ""} mb-2`}>
                                                  <div className="flex items-center w-full secondary">
                                                    <Form.Item name="larghezza_porta"
                                                      className={`flex-1 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "larghezza_porta")?.id && "error-field"}`}>
                                                      <InputNumber
                                                        placeholder={props.t("porte_vetrate.larghezza")}
                                                        value={store.getState()?.porta_vetrata?.larghezza_porta}
                                                        onChange={(
                                                          num: number
                                                        ) => {
                                                          dispatch(actPorteVetrateCustom({
                                                            larghezza_porta: num
                                                          }))
                                                        }}
                                                      />
                                                    </Form.Item>
                                                    {/* <span className="unit">
                                                            {getUnitOfMeasure()}
                                                        </span> */}
                                                  </div>
                                                </Col>
                                                {console.log("store.getState()?.porta_vetrata?.altezza_porta",store.getState()?.porta_vetrata?.altezza_porta)}
                                                <Col xs={isRequiredVetrata() ? 12 : 6} className={`${isRequiredVetrata() ? "px-0 mx-0" : ""}`}>
                                                  <div className="flex items-center w-full secondary">
                                                    <Form.Item name="altezza_porta"
                                                      className={`flex-1 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "altezza_porta")?.id && "error-field"}`}>
                                                      <InputNumber
                                                        placeholder={props.t("porte_vetrate.altezza")}
                                                        key={"altezza_porta"}
                                                        defaultValue={store.getState()?.porta_vetrata?.altezza_porta}
                                                        value={store.getState()?.porta_vetrata?.altezza_porta}
                                                        disabled={store.getState()?.porta_vetrata?.porta_prodotto?.slug===SLUG_ADAPTA_CUSTOM}
                                                        onChange={(
                                                          num: number
                                                        ) => {
                                                          dispatch(actPorteVetrateCustom({
                                                            altezza_porta: num
                                                          })
                                                        )
                                                        }}
                                                      />
                                                      <span className="text-xs inline-block mt-2">
                                                        {props?.t("v10.adapta_altezza_porta_2m")}
                                                        <a className="ml-1 text-verde-effe" target="_blank" href="https://www.effe.it/contatti/contattaci/">{"Link"}</a>
                                                        </span>
                                                    </Form.Item>
                                                    
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          </div>

                                          <div className="mt-4">
                                            <Row>

                                              <Col lg={isRequiredVetrata() ? 12 : 6} className={`mb-2 ${isRequiredVetrata() ? "px-0 mx-0" : ""}`}>
                                                <p className="text-verde-effe font-bold text-titleSectionGenerator">
                                                  {props.t("porte_vetrate.materiale_profilo")}
                                                </p>
                                                <div className="">
                                                  <Form.Item name="materiale_ctm"
                                                    className={`flex-1 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "materiale_ctm")?.id && "error-field"}`}>
                                                    <AsyncSelect
                                                      currValue={store.getState()?.porta_vetrata?.materiale ? {
                                                        name: store.getState()?.porta_vetrata?.materiale?.title?.rendered,
                                                        id: store.getState()?.porta_vetrata?.materiale?.id,
                                                      } : null}
                                                      // noOptionsMessage={props.t("v2.portevetrate.error.dim_vano_maggiori")}
                                                      placeholder={props.t("general.scegli_opzione")}
                                                      onChange={(material: TMaterialDetails) => {
                                                        dispatch(actPorteVetrateCustom({
                                                          materiale: material
                                                        }))
                                                      }}
                                                      onStyleLabel={(item: any) => {
                                                        return <TextCell isSecondary title={item.name} />;
                                                      }}
                                                      loadOptions={async (
                                                        searchQuery: any,
                                                        loadedOptions: any,
                                                        { page }: any
                                                      ) => {
                                                        return {
                                                          options: _materials.map((material: TMaterialDetails) => {
                                                            return { name: material.title.rendered, ...material };
                                                          }),
                                                          hasMore: false,
                                                          additional: {},
                                                        };
                                                      }}
                                                      isSecondary
                                                    />
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                              <Col lg={isRequiredVetrata() ? 12 : 6} className={`${isRequiredVetrata() ? "px-0 mx-0" : ""}`}>
                                                <p className="text-verde-effe font-bold text-titleSectionGenerator">
                                                  {props.t("porte_vetrate.versione_cerniera")}
                                                </p>
                                                <div className="">
                                                  <Form.Item name="versione_ctm"
                                                    className={`flex-1 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "versione_ctm")?.id && "error-field"}`}>
                                                    <AsyncSelect
                                                      currValue={currentSideCernieraPorta ? {
                                                        name: props.t(getLabelForESideCerniera(store.getState()?.porta_vetrata?.versione)),
                                                        id: store.getState()?.porta_vetrata?.versione,
                                                      } : null}
                                                      // noOptionsMessage={props.t("v2.portevetrate.error.dim_vano_maggiori")}
                                                      placeholder={props.t("general.scegli_opzione")}
                                                      onChange={(cerniera: any) => {
                                                        dispatch(actPorteVetrateCustom({
                                                          versione: cerniera?.id
                                                        }))
                                                      }}
                                                      onStyleLabel={(item: any) => {
                                                        return <TextCell isSecondary title={item.name} />;
                                                      }}
                                                      loadOptions={async (
                                                        searchQuery: any,
                                                        loadedOptions: any,
                                                        { page }: any
                                                      ) => {
                                                        const versions = [ESideCerniera.DX, ESideCerniera.SX]
                                                        return {
                                                          options: versions.map((it: ESideCerniera) => {
                                                            return { name: props.t(getLabelForESideCerniera(it)), id: it };
                                                          }),
                                                          hasMore: false,
                                                          additional: {},
                                                        };
                                                      }}
                                                      isSecondary
                                                    />
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>

                                        {/** VETRATA */}
                                        {isRequiredVetrata() &&
                                          <Col xs={12} sm={12} md={6} lg={6} className="px-0 mx-0 xs:mt-4 md:mt-0">
                                            <Row>
                                              <Col className="px-0 mx-0">
                                                <H3 title={props.t("porte_vetrate.vetrate.titolo")} className="text-verde-effe font-bold text-titleSectionGenerator" />
                                              </Col>
                                            </Row>
                                            <Row>
                                              {_vetrataModels.map((it: TModelloDetailPortaType) => {
                                                const isModelSelected = store.getState()?.porta_vetrata?.modello_vetrata?.id === it.id;
                                                return (
                                                  <Col key={it.id} lg={6} className="px-0 mx-0">
                                                    <ConfigurationGridItem
                                                      isSelected={isModelSelected}
                                                      title={it.title.rendered}
                                                      className={`mb-4 xs:pr-0 lg:pr-3`}
                                                      img={it?._embedded?.["wp:featuredmedia"]?.[0]?.source_url || PLACEHOLDER_IMAGE}
                                                      onClick={() => {
                                                        dispatch(actPorteVetrateCustom({
                                                          modello_vetrata: isModelSelected ? undefined : it
                                                        }))
                                                      }}
                                                    />
                                                  </Col>
                                                )
                                              })}
                                            </Row>

                                            <div className="mt-4">
                                              <p className="text-verde-effe font-bold text-titleSectionGenerator">
                                                {`${props.t("porte_vetrate.dim_vetrata")} (cm)`}
                                              </p>
                                              <Form
                                                name="basic"
                                                layout={"vertical"}
                                                autoComplete={"off"}
                                                className="w-full"
                                              >
                                                <Row>
                                                  <Col xs={12} className="px-0 mx-0 mb-2">
                                                    <div className="flex items-center w-full secondary">
                                                      <Form.Item name="larghezza_vetrata"
                                                        className={`flex-1 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "larghezza_vetrata")?.id && "error-field"}`}>
                                                        <InputNumber
                                                          placeholder={props.t("porte_vetrate.larghezza")}
                                                          value={store.getState()?.porta_vetrata?.larghezza_vetrata}
                                                          onChange={(
                                                            num: number
                                                          ) => {
                                                            dispatch(actPorteVetrateCustom({
                                                              larghezza_vetrata: num
                                                            }))
                                                          }}
                                                        />
                                                      </Form.Item>
                                                      {/* <span className="unit">
                                                              {getUnitOfMeasure()}
                                                          </span> */}
                                                    </div>
                                                  </Col>
                                                  <Col xs={12} className="px-0 mx-0">
                                                    <div className="flex items-center w-full secondary">
                                                      <Form.Item name="altezza_vetrata"
                                                        className={`flex-1 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "altezza_vetrata")?.id && "error-field"}`}>
                                                        <InputNumber
                                                          placeholder={props.t("porte_vetrate.altezza")}
                                                          value={store.getState()?.porta_vetrata?.altezza_vetrata}
                                                          onChange={(
                                                            num: number
                                                          ) => {
                                                            dispatch(actPorteVetrateCustom({
                                                              altezza_vetrata: num
                                                            }))
                                                          }}
                                                        />
                                                      </Form.Item>
                                                      {/* <span className="unit">
                                                              {getUnitOfMeasure()}
                                                            </span> */}
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Form>
                                            </div>
                                          </Col>
                                        }
                                      </Row>
                                    </div>


                                    {
                                      store.getState()?.general?.stepError?.step === StepConfiguratore.PorteVetrate &&
                                      <Row>
                                        <Col className="mt-8">
                                          <MsgError message={store.getState()?.general?.stepError?.msg} />
                                        </Col>
                                      </Row>
                                    }


                                    <div className="mt-4 flex justify-center">
                                      <ActionButton
                                        importance={IActionButtonType.Secondary} title={props.t("general.seleziona")} onClick={() => {
                                          if (!store.getState()?.porta_vetrata?.modello_porta) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: props.t("v3.error.scegli_porta"),
                                                fields: []
                                              }
                                            }))
                                            return;
                                          }
                                          if ((!store.getState()?.porta_vetrata?.modello_vetrata && isRequiredVetrata())) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: props.t("v3.error.scegli_vetrata"),
                                                fields: []
                                              }
                                            }))
                                            return;
                                          }
                                          else if (!store.getState()?.porta_vetrata?.materiale) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"),
                                                fields: [{ id: "materiale_ctm", msg: "" }]
                                              }
                                            }))
                                            return;
                                          }
                                          else if (!store.getState()?.porta_vetrata?.versione) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"),
                                                fields: [{ id: "versione_ctm", msg: "" }]
                                              }
                                            }))
                                            return;
                                          }
                                          else if (!store.getState()?.porta_vetrata?.altezza_porta || !store.getState()?.porta_vetrata?.larghezza_porta) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"),
                                                fields: [{ id: "larghezza_porta", msg: "" }, { id: "altezza_porta", msg: "" }]
                                              }
                                            }))
                                            return;
                                          }
                                          else if ((isRequiredVetrata() && (!store.getState()?.porta_vetrata?.altezza_vetrata || !store.getState()?.porta_vetrata?.larghezza_vetrata))) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"),
                                                fields: [{ id: "larghezza_vetrata", msg: "" }, { id: "altezza_vetrata", msg: "" }]
                                              }
                                            }))
                                            return;
                                          }
                                          else if (areDimensionsOfPortaVetrataGreaterThanVano()) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: `${props.t("porte_vetrate.errors.dimensioni_maggiori_vano")}: ${store.getState()?.vano_doccia?.larghezza} x ${store.getState()?.vano_doccia?.profondita} x ${store.getState()?.vano_doccia?.altezza} h`,
                                                fields: [{ id: "larghezza_porta", msg: "" }, { id: "altezza_porta", msg: "" }
                                                  , { id: "larghezza_vetrata", msg: "" }, { id: "altezza_vetrata", msg: "" }]
                                              }
                                            }))
                                            return;
                                          }
                                          else if (areDimensionUnavailable()) {
                                            dispatch(actGeneral({
                                              stepError: {
                                                step: StepConfiguratore.PorteVetrate, msg: props.t(getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI ? "porte_vetrate.errors.dimensioni_maggiori_limite" : "porte_vetrate.errors.dimensioni_maggiori_limite_ft"),
                                                fields: [{ id: "larghezza_porta", msg: "" }, { id: "altezza_porta", msg: "" }
                                                  , { id: "larghezza_vetrata", msg: "" }, { id: "altezza_vetrata", msg: "" }]
                                              }
                                            }))
                                            return;
                                          }
                                          closeModal()
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              },
                              ModalSize.xl
                            );
                          } else {
                            dispatch(actResetPorteVetrateCustom())
                          }

                        }}
                        classNameImage={""}
                      />
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </Col>
      </Row>

      <Modal
        title={modalData.title}
        onClose={() => {
          dispatch(actResetPorteVetrateCustom())
          dispatch(actResetPorteVetrateStandard())
          closeModal()
        }}
        visible={modalIsOpen}
        size={modalData.size}
        closeInline
      >
        {modalData.content && modalData.content()}
        {(store.getState()?.porta_vetrata?.modello_porta &&
          store.getState()?.porta_vetrata?.porta_prodotto?.acf?.tipologia_di_prodotto === EPortaStdCustomType.STANDARD) &&
          <Row>
            <Col className="" lg={isVetrataSectionVisible() ? 6 : 12} md={isVetrataSectionVisible() ? 6 : 12}>
              <div>
                <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t('porte_vetrate.popup.caratteristiche')}</p>
                <div className="text-black-effe font-regular text-titleSectionGenerator" dangerouslySetInnerHTML={{
                  __html: store.getState()?.porta_vetrata?.modello_porta?.content?.rendered || ""
                }}>
                </div>
              </div>

              <div className="mt-4">
                <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t('porte_vetrate.materiale')}</p>
                {
                  isLoadingItems ?
                    <><Spin /></>
                    :
                    <div className="">
                      <Form.Item name="materiale"
                        className={`${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "materiale")?.id && "error-field"}`}>
                        {
                          <AsyncSelect
                            currValue={currentMaterialPorta ? {
                              name: currentMaterialPorta.post_title,
                              id: currentMaterialPorta.ID,
                            } : null}
                            // noOptionsMessage={props.t("v2.portevetrate.error.dim_vano_maggiori")}
                            placeholder={props.t("general.scegli_opzione")}
                            onChange={(material: TListinoItemContentType) => {
                              setCurrentMaterialPorta(material.acf.materials?.[0])
                            }}
                            onStyleLabel={(item: any) => {
                              return <TextCell title={item.name} disabled={false} isSecondary />;
                            }}
                            loadOptions={async (
                              searchQuery: any,
                              loadedOptions: any,
                              { page }: any
                            ) => {
                              const materials = getUniqueMaterialsFromItems(items)
                              return {
                                options: materials.map((it: TListinoItemContentType) => {
                                  return { name: it.acf.materials?.[0]?.post_title, ...it };
                                }),
                                hasMore: false,
                                additional: {},
                              };
                            }}
                            isSecondary
                          />
                        }
                      </Form.Item>
                    </div>
                }
              </div>

              {
                getUniqueVersionsFromItems(items).length !== 0 &&
                <div className="mt-4">
                  <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t('porte_vetrate.versione')}</p>
                  {
                    isLoadingItems ?
                      <><Spin /></>
                      :
                      <div className="">
                        <Form.Item name="version"
                          className={`${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "version")?.id && "error-field"}`}>
                          {
                            <AsyncSelect
                              currValue={currentSideCernieraPorta ? {
                                name: props.t(getLabelForESideCerniera(currentSideCernieraPorta)),
                                id: currentSideCernieraPorta,
                              } : null}
                              // noOptionsMessage={props.t("v2.portevetrate.error.dim_vano_maggiori")}
                              placeholder={props.t("general.scegli_opzione")}
                              onChange={(cerniera: TListinoItemContentType) => {
                                setCurrentSideCernieraPorta(cerniera.acf.side_cerniera)
                              }}
                              onStyleLabel={(item: any) => {
                                return <TextCell title={item.name} disabled={false} isSecondary />;
                              }}
                              loadOptions={async (
                                searchQuery: any,
                                loadedOptions: any,
                                { page }: any
                              ) => {
                                const versions = getUniqueVersionsFromItems(items)
                                return {
                                  options: versions.map((it: TListinoItemContentType) => {
                                    return { name: props.t(getLabelForESideCerniera(it.acf.side_cerniera)), ...it };
                                  }),
                                  hasMore: false,
                                  additional: {},
                                };
                              }}
                              isSecondary
                            />
                          }
                        </Form.Item>
                      </div>
                  }
                </div>
              }
            </Col>

            {
              isVetrataSectionVisible() &&
              <Col lg={6} md={6} className="">
                <div className="xs:mt-4 lg:mt-0">
                  <p className="text-verde-effe font-bold text-titleSectionGenerator">{props.t('porte_vetrate.popup.dim_vetrata')}</p>
                  <p className="text-black-effe font-regular text-titleSectionGenerator">{
                    !store.getState()?.porta_vetrata?.modello_vetrata?.acf?.standard_configuration_fields?.x_axis_door ||
                    !store.getState()?.porta_vetrata?.modello_vetrata?.acf?.standard_configuration_fields?.y_axis_door ?
                    props.t("v10.porte_senza_vetrate_disponibili")
                    :
                    `${getUnitOfMeasure()} ${store.getState()?.porta_vetrata?.modello_vetrata?.acf?.standard_configuration_fields?.x_axis_door} x ${store.getState()?.porta_vetrata?.modello_vetrata?.acf?.standard_configuration_fields?.y_axis_door}h`
                    
                  }</p>
                </div>
              </Col>
            }

            {
              store.getState()?.general?.stepError?.step === StepConfiguratore.PorteVetrate &&
              <Row>
                <Col className="mt-8">
                  <MsgError message={store.getState()?.general?.stepError?.msg} />
                </Col>
              </Row>
            }

            <Col lg={12} className="text-center pt-4">
              <ActionButton importance={IActionButtonType.Secondary}
                title={props.t("general.seleziona")}
                disabled={isLoadingItems}
                onClick={() => {
                  if (!store.getState()?.porta_vetrata?.modello_porta) {
                    dispatch(actGeneral({ stepError: { step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"), fields: [{ id: "modello_porta_std", msg: "" }] } }))
                    return;
                  }
                  if ((store.getState()?.vetrate?.quantita == 2 && !store.getState()?.porta_vetrata?.modello_vetrata)) {
                    dispatch(actGeneral({ stepError: { step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"), fields: [{ id: "modello_vetrata_std", msg: "" }] } }))
                    return;
                  }
                  if (!currentMaterialPorta) {
                    dispatch(actGeneral({ stepError: { step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"), fields: [{ id: "materiale", msg: "" }] } }))
                    return;
                  }
                  if ((!currentSideCernieraPorta && getUniqueVersionsFromItems(items).length !== 0)) {
                    dispatch(actGeneral({ stepError: { step: StepConfiguratore.PorteVetrate, msg: props.t("general.campi_mancanti"), fields: [{ id: "version", msg: "" }] } }))
                    return;
                  }



                  const _porta_listino = items.filter((it: TListinoItemContentType) =>
                    (it.acf.side_cerniera === currentSideCernieraPorta || getUniqueVersionsFromItems(items).length === 0) &&
                    it.acf.materials?.[0]?.post_name === currentMaterialPorta?.post_name)
                  [0]
                  /**
                   * la voce di listino del telaio è quella senza materiali e, se presente la cerniera prendo quella voce, altrimenti la prima voce che trovo.
                   */
                  const telaio = items.filter(i => !i?.acf?.materials && i?.acf?.side_cerniera === currentSideCernieraPorta)?.[0] || items.filter(i => !i?.acf?.materials && !i?.acf?.side_cerniera)?.[0]
                  console.log({ items, telaio })

                  dispatch(actPorteVetrateStandard({
                    porta_listino: [_porta_listino].concat(telaio ? [telaio] : [])
                  }))
                  closeModal()
                }} style={{ paddingTop: 10, paddingBottom: 10 }} />
            </Col>
          </Row>}

      </Modal>

      {
        (store.getState()?.general?.stepError?.step === StepConfiguratore.PorteVetrate &&
          store.getState()?.general?.stepError?.fields?.find(i => i.id === "general")) &&
        <Row>
          <Col className="mt-8">
            <MsgError message={store.getState()?.general?.stepError?.fields?.find(i => i.id === "general")?.msg} />
          </Col>
        </Row>
      }
    </div>
  );
}

export default connect((state: TGeneral, ownProps) => ({
  general: state.general,
  vano_doccia: state.vano_doccia,
  porta_vetrata: state.porta_vetrata,
  vetrate: state.vetrate
}), { actPorteVetrateStandard, actGeneral })(
  withTranslation()(PorteVetrate)
);
