import { FC, useEffect } from "react";
import { Text } from "../../Typography";
import { Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Select } from "antd";
import { IconEdit } from "../../Icon";
import tailwindMyConfig from "../../../tailwind.my.config";
import {  isMobile } from "../../../utils/utils";
const { Option } = Select;

interface IRiepilogoGridItem extends React.HtmlHTMLAttributes<HTMLElement> {
  title: string;
  subtitle?: string;
  description?: string;
  descriptionHtml?: any;
  descriptionItems?: any[];
  maxCharDescription?: number;
  onClick?: any;
  actionDescription?: any;
  actionOnClick?: any;
  isQuote?: boolean;
  t?: any;
  disclaimer?: string;
}

const RiepilogoGridItem: FC<IRiepilogoGridItem> = ({ title, subtitle, description, descriptionItems, descriptionHtml, maxCharDescription, onClick, disclaimer, actionDescription, actionOnClick, isQuote, ...props }: IRiepilogoGridItem) => {
    const _heightCell = isMobile() ? "auto" : "auto";

    useEffect(()=>{
    },[
        descriptionItems
    ])
    return (
    <div style={{height: _heightCell, overflow: "hidden", margin:"0 auto", ...props.style}} className={`flex flex-col flex-1 justify-between items-center ${props.className}`}>
        <div className="flex h-full w-full border-t	border-fluo-effe border-solid pt-4">
            
            <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                <div className={`h-full flex justify-between flex-col`}>
                    <div className="h-full">
                        <div className={`flex justify-between items-center w-full`} >
                            <div className="flex">
                                <span className={`text-fluo-effe text-titleOverview mb-0 font-bold`}>{title}</span>
                                {
                                    onClick &&
                                    <button className="ml-3" onClick={onClick}>
                                        <IconEdit fill={tailwindMyConfig.theme.extend.colors["fluo-effe"]}
                                        style={{width:25,height:25}}
                                        />
                                    </button>
                                }
                            </div>
                            {(subtitle) && <p style={{minWidth: 100}} className={`text-subtitle-cell text-right md:min-w-unset text-fluo-effe mb-0 font-bold text-titleSectionGenerator`}>{String(subtitle).toUpperCase()}</p>}
                        </div>
                        {
                            disclaimer ? <span className="block text-xs font-light">{disclaimer}</span> : null
                        }
                        <div className={`flex block w-full overflow-scroll ${!description ? "flex-col" : "items-center pt-2"}`} style={{marginBottom:"2%"}}>
                            {description ? 
                            <Text title={maxCharDescription ? description.substr(0,maxCharDescription-3)+"..." : description} className={`text-grigio-effe font-400`} style={{fontSize:12}}/> : 
                            descriptionItems ? descriptionItems.map((ditem:any)=>ditem) : 
                            null}
                            {descriptionHtml && <div dangerouslySetInnerHTML={{__html: descriptionHtml}}></div>}
                        </div>
                        <div className="block w-full mb-2">
                            {
                                actionDescription ? 
                                <button onClick={actionOnClick}>
                                    <Text title={actionDescription} className={`text-grigio-effe font-400 underline`} style={{fontSize:12}}/>
                                </button>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    </div>
  );
};
export default withTranslation()(RiepilogoGridItem);
