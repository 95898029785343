module.exports = {
  purge: ["./src/**/*.{js,jsx,ts,tsx}", "./public/index.html"],
  darkMode: false, // or 'media' or 'class'
  important: true,
  theme: {
    screens: {
      'xs': '0px',
      // => @media (min-width: 0px) { ... } 

      'sm': '640px',
      // => @media (min-width: 640px) { ... } 
  
      'md': '768px',
      // => @media (min-width: 768px) { ... }
  
      'lg': '1024px',
      // => @media (min-width: 1024px) { ... }
  
      'xl': '1280px',
      // => @media (min-width: 1280px) { ... }
    },
    minWidth: {},
    extend: {
      colors: {
        "azzurro-effe": "#B3FCE8",
        "fluo-effe": "#00FFBA", 
        "verde-effe": "#00E6A8",
        "verde-medioscuro-effe": "#00996f", //noname
        "verde-scuro-effe": "#406464",
        "giallo-effe": "#EEF9C1",
        "grigio-effe": "#707070",
        "grigio-chiaro-effe": "#ededed",
        "grigio-old-effe": "#5C5C5C",
        "grigio-scuro-effe": "#5C5C5C",
        "bianco-effe": "#333333", //noname
        "warning-effe": "#EF4C4C",
        "warning-input-effe":"#FD657D",
        "black-effe":"#2B2E34",
        "loader-effe":"#363636",
        "wizard-effe":"#B1B1B1",
        "subtitle-effe":"#9A9A9A",
        "modal-effe":"#6C6C6C",
        "modal-effe-light":"#e1e1e1",
        "hover-cell":"#E5FFF8",
        "default-cell":"#F5F5F5",
        "divider":"#DDDDDD",
        "menu-selected":"rgba(0,255,186,.04)"
      },
      fontSize: {
        h1: 70,
        h2: 50,
        h3: 20,
        h3mobile: 18,
        h4: 27,
        h5: 25,
        text: 22,
        menu: 30,
        titlepopup: 20,
        textpopup: 12,
        titleCard: 16,
        titleSectionGenerator: 18,
        titleOverview: 28,
        titlePrice: 26
      },
      fontWeight: {
        h1: 900,
        h2: 900,
        h3: 900,
        h4: 900,
        text: 400,
        menu: 300,
      },
      fontFamily:{
        regular:"avertastd-regularuploadedfile !important",
        bold:"avertastd-bolduploaded_file !important",
        light: "avertastd-lightuploaded_file !important",
        thin: "avertastd-thinuploaded_file !important"
      },
      margin:{
        title: "0 13px 22px 13px",
        subtitle: "0 13px 22px 13px"
      },
      borderWidth:{
        'half': ".5px",
      },
      minHeight:{
        '55': 55
      },
      keyframes: {
        "fade-in-down": {
          "0%": {
            opacity: "0"
          },
          "100%": {
            opacity: "1"
          },
        },
        "fade-out-down": {
          from: {
            opacity: "1"
          },
          to: {
            opacity: "0"
          },
        },
      },
      animation: {
        "fade-in-down": "fade-in-down 0.5s ease-out",
        'fade-out-down': 'fade-out-down 0.5s ease-out',
      },
      maxHeight: {
        '30-screen':"30vh",
        '40-screen':"40vh",
        'half-screen':"50vh"
      },
      maxWidth: {
        'full':"100%"
      },
      height:{
        '70-screen': "70%"
      }
    },
  },
  variants: {
    extend: {
      cursor: ['hover', 'focus'],
    },
  },
  plugins: [],
};
