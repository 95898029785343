import Action, { ActionTypes } from "../actionTypes";


export enum ConfigurationType{
    STANDARD = "STANDARD",
    PORTE_VETRATE = "PORTE_VETRATE"
}

export type TSceltaConfigurazioneStoreState = {
    type?: ConfigurationType;
}
const initialState: TSceltaConfigurazioneStoreState = {
    type: undefined
};


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionSceltaConfigurazioneSetData: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
