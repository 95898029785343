
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { getLangFromWPLocale } from './utils/storage';

const defaultLng = 'en';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getLangFromWPLocale(),
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}/it.json'
    },
    fallbackLng: defaultLng,
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }
  })

export default i18n