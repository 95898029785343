import Action, { ActionTypes } from "../actionTypes";

export enum EVoltaggioType{
    v208 = "208",
    v208_3ph = "208_3ph",
    V240 = "240",

}

export type TVoltaggioStoreState = {
    voltaggio?: EVoltaggioType;
}
const initialState: TVoltaggioStoreState = {
    voltaggio: undefined
};

export const getLabelEVoltaggioType = (k:EVoltaggioType) => {
  const key = k
  if(key===EVoltaggioType.v208) return "voltaggio.v208"
  else if(key===EVoltaggioType.v208_3ph) return "voltaggio.v208_3ph"
  else if(key===EVoltaggioType.V240) return "voltaggio.v240"
  else return ""
}

export const getVoltaggiExternal = () : EVoltaggioType[] => {
  return [EVoltaggioType.v208,EVoltaggioType.V240]
}
export const getVoltaggiInternal = () : EVoltaggioType[] => {
  return [EVoltaggioType.v208,EVoltaggioType.V240]
}


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetVoltaggio: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
