import { getCmFromFtAndInch } from "../../utils/utils";
import Action, { ActionTypes } from "../actionTypes";

export enum EGeneratoreType{
    Interno = "interno",
    Esterno = "esterno"
}

export type TGeneratoreStoreState = {
    tipologia?: EGeneratoreType;
}
const initialState: TGeneratoreStoreState = {
    tipologia: undefined
};

export const getLabelEGeneratoreType = (k:EGeneratoreType) => {
  const key = String(k).toLowerCase()
  if(key===EGeneratoreType.Interno) return "generatore.interno"
  else if(key===EGeneratoreType.Esterno) return "generatore.esterno"
  else return ""
}

export const getMKitCoibentazione = (larghezza:number, profondita: number, altezza: number, larghezza_porta: number, altezza_porta: number, 
  larghezza_vetrata: number, altezza_vetrata: number, round?: boolean): number => {
  const larghezza_m = larghezza/100
  const altezza_m = altezza!/100
  const profondita_m = profondita!/100
  //porta
  const larghezza_porta_m = larghezza_porta/100
  const altezza_porta_m = altezza_porta/100
  //vetrata
  const larghezza_vetrata_m = larghezza_vetrata/100
  const altezza_vetrata_m = altezza_vetrata/100

  const roundDimension = (dim: number) => !round ? dim : Math.ceil(dim)
  const s = ((larghezza_m * altezza_m) * 2) + ((profondita_m * altezza_m) * 2) + (larghezza_m * profondita_m) - (altezza_porta_m * larghezza_porta_m) - (altezza_vetrata_m * larghezza_vetrata_m)
  return roundDimension(s)
}     


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetData: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
