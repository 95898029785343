import { Spin } from "antd";
import { FC, useRef } from "react";
import { withTranslation } from "react-i18next";
import tailwindMyConfig from "../../tailwind.my.config";
import { getLocalization, getValute, Localization } from "../../utils/utils";
import { IconClose } from "../Icon";
import { Text } from "../Typography";
import Lottie from 'react-lottie';
import ic_download from "./../../assets/lottie/download.json"
interface IRiepilogoItem {
  title: any|any[];
  subItems?: {title:string, price: string, valute:string, quantity?: number, label_quantity?: string} [];
  onDelete?: null|(() => void);
  priceUnit?: number|string;
  units?: number|string;
  isMultipleUnits?: boolean;
  isLoadingPrice?: boolean;
  separator?: boolean;
  bold?: boolean;
  valute?: string;
  t?: any;
  image?: string;
  className?: string;
  classNameMainItem?: string;
  classNameImage?: string;
  actionDescription?: any;
  actionOnClick?: any;
}

const RiepilogoItem: FC<IRiepilogoItem> = ({
  title,
  subItems,
  onDelete,
  priceUnit,
  units,
  isMultipleUnits,
  isLoadingPrice,
  valute,
  separator,
  image,
  className,
  classNameMainItem,
  classNameImage,
  actionDescription,
  actionOnClick,
  bold,
  t,
  ...props
}: IRiepilogoItem) => {

  const icDownloadRef:any = useRef();
  const defaultDownloadOptions = {
    loop: false,
    autoplay: false, 
    animationData: ic_download,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const WIDTH_ACTION = 30
  return (
    <div className={`flex flex-col ${className}`}>
      {separator && <hr className="bg-divider w-full mt-0 mb-2 border-0" style={{height:.5, marginLeft: WIDTH_ACTION}}/>}
      <div className={`flex items-center justify-between pb-1 ${classNameMainItem}`}>
        <div className="flex justify-between items-center">
          { 
            onDelete ?
            <button
              onClick={() => onDelete && onDelete()}
            >
              <div className="flex items-center" style={{ width: WIDTH_ACTION, minWidth:WIDTH_ACTION, height: WIDTH_ACTION }}>
                <IconClose
                  fill={tailwindMyConfig.theme.extend.colors["fluo-effe"]}
                  className="disable-animation"
                />
              </div>
            </button>
            :
            <div style={{ width: WIDTH_ACTION, minWidth:WIDTH_ACTION,height: WIDTH_ACTION }}></div>
          }
          <span className={` mb-0 ml-0 text-black-effe text-titleSectionGenerator ${bold ? "font-bold" : "font-regular"} `}>{title}</span>
        </div>
        <div className="flex justify-between items-center">
          {(isLoadingPrice ? <div className="justify-center"><Spin size={"small"}/></div> : 
            (priceUnit ? 
              <div className="flex flex-col" style={{minWidth:100}}>
                <span className={`${bold ? "font-bold" : "font-regular"} text-right ${bold ? "text-black-effe" : "text-subtitle-effe"}`}>{`${priceUnit} ${valute || getValute()}`}</span> 
                {isMultipleUnits && <span className="font-regular text-subtitle-effe text-wizard text-right">{`${isMultipleUnits ? `${units ? `(${units} ${t("riepilogo.prezzo_per_unita")})` : ""}` : ""}`}</span>}
              </div>
            : ""))}
        </div>
      </div>
      {
        (subItems && subItems.length) ?
          <div className="flex flex-col mt-4">
              {
                subItems.map((sI,sIindex) => {
                  return(
                    <div key={sIindex} className={`flex items-center justify-between py-2 ${sIindex<subItems.length-1 && "border-b border-solid border-divider"}`} style={{marginLeft: WIDTH_ACTION}}>
                      <span className={`mb-0 ml-0 text-black-effe text-titleSectionGenerator font-regular`}>{sI.title}</span>
                      <span style={{minWidth:100}} className="font-regular	text-right text-titleSectionGenerator text-subtitle-effe">{`${sI.price} ${valute || getValute()}`}</span>
                    </div>
                  )
                })
              }
          </div>
        :
        null
      }
      {
        actionDescription &&
        <div className="flex w-full mb-2">
            <div style={{ width: WIDTH_ACTION, minWidth:WIDTH_ACTION,height: WIDTH_ACTION }}></div>
            <button onClick={actionOnClick}  onMouseEnter={() => {
                  //@ts-ignore
                  icDownloadRef?.current?.play()
                }} onMouseLeave={() => {
                  //@ts-ignore
                  icDownloadRef?.current?.stop()
                }} className="flex items-center mt-2">
                <div>
                  <Lottie  ref={icDownloadRef} options={defaultDownloadOptions} height={35} width={20}  
                  isStopped={true}
                  />
                </div>
                <Text  title={actionDescription} className={`ml-2 text-fluo-effe font-regular uppercase mb-0`} style={{fontSize:12}}/>
            </button>
        </div> 
      }

      {
        image && 
        <img src={image} className={`object-contain w-2/5 text-left ${classNameImage}`} style={{maxHeight:300}}/>
      }
    </div>
  );
};
export default withTranslation()(RiepilogoItem);
