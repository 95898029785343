import { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import ActionButton, { IActionButtonType } from "../../Buttons/ActionButton";
import { IconSelected } from "../../Icon";
import { Text } from "../../Typography";
import { motion } from "framer-motion"
import Image from "../../Image";

interface ISelectableGridItem extends React.HtmlHTMLAttributes<HTMLElement> {
  title: string;
  isSelected: boolean;
  isLoading?: boolean;
  titleAction: string;
  img: string;
  onClick: () => void;
  disabled?: boolean;
  t?: any;
  htmlDecode?: boolean;
  imgStyle?: any;
}

const SelectableGridItem: FC<ISelectableGridItem> = ({ title, isSelected, htmlDecode, titleAction, img, imgStyle, isLoading, onClick, disabled, ...props }: ISelectableGridItem) => {

  return (
    <div className="">
        <div className="flex flex-col">
        <div className="flex flex-row items-center">
            <IconSelected isSelected={isSelected}  className={`transition-opacity ${isSelected ? "opacity-1 visible" : "opacity-0 invisible w-0 hidden "}`}/>          
            {
              htmlDecode ? 
              <div className="text-black-effe text-left font-bold text-titleCard" style={{zIndex:2}} dangerouslySetInnerHTML={{
                __html: title}
              }></div>
              :
              <Text title={title} className="text-black-effe text-left font-bold text-titleCard" style={{zIndex:2}}/>
            }
          </div>
          <ActionButton 
            importance={IActionButtonType.Primary} 
            title={isSelected ? props.t("general.deseleziona") : titleAction} 
            onClick={onClick} 
            isLoading={isLoading} 
            className={`${isSelected ? "xs:ml-7 md:ml-10" : "ml-0"} action-selectable-grid-item`}
            disabled={isLoading||disabled}
            icon
            isSelected={isSelected}/> 

        </div>
        <Image img={img} imgStyle={imgStyle ? imgStyle : {}} classNameWrapper={"my-4"}/>
    </div>
  );
};
export default withTranslation()(SelectableGridItem);
