import { Form, Input, FormInstance, Spin, InputNumber, message, notification } from "antd";
import { Row, Col, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import { TGeneral, TGeneralStore } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  actGeneratoreSetRiepilogo, actResetStore, actGeneratoreSetOptionals,
  actResetPorteVetrateStandard, actResetPorteVetrateCustom, actGeneratoreSetAccessori, actPanche
} from "../../../../redux/actions";
import { useEffect, useState } from "react";
import {
  EVoltaggioType,
  getLabelEVoltaggioType,
} from "../../../../redux/reducers/voltaggio";
import { TCustomerInfo, TRiepilogoPayload, TRiepilogoPayloadData, TRiepilogoStoreState } from "../../../../redux/reducers/riepilogo";
import FormButton from "../../../../components/Buttons/FormButton";
import RiepilogoGridItem from "../../../../components/GridItem/RiepilogoGridItem";
import { IconCopy, IconDownload, IconFrecciaDown, IconFrecciaUp, IconLink, IconRestart, IconSend } from "../../../../components/Icon";
import tailwindMyConfig from "../../../../tailwind.my.config";
import RiepilogoSimpleGridItem from "../../../../components/GridItem/RiepilogoSimpleGridItem";
import { formatPriceForContext, fromMCubiToFeetCubi, getCmFromFtAndInch, getLanguage, getCrmLanguage, getLocalization, getUnitOfMeasure, getUnitOfMeasureMCubiLabel, getValute, isCustomPortaVetrata, isVetroPortaVetroPortaModel, Localization, parseDimension, toPrice, uuidv4, capitalize, toPricePancheCustom, getIdLightHammam } from "./../../../../utils/utils"
import { useRef } from "react";
import { PLACEHOLDER_IMAGE, SLUG_TUTTOVETRO_CONTOUR, SLUG_TUTTOVETRO_INSIDE, SLUG_NUVOLA_SP_250, SLUG_NUVOLA_SP_360, SLUG_NUVOLA_SP_110, SLUG_NUVOLA_SP_140, SLUG_NUVOLA_SP_180, SLUG_OPTIONAL_STEAM_DIFFUSER_FINISHES } from "../../../../utils/consts";
import { EKitCoibentazioneModel, TOptionalDetailType, TOptionalQuantity } from "../../../../redux/reducers/optional";
import { TAccessorioQuantity } from "../../../../redux/reducers/accessori";
import RiepilogoItem from "../../../../components/RiepilogoItem";
import { getLabelForESideCerniera, getPricesCustomPortaVetrata, TListinoItemContentType, TListinoItemType } from "../../../../redux/reducers/porte_vetrate";
import { addRiepilogo, getItem, getKitCoibentazioneWithSlug } from "../../../../network/actions";
import { CrmLanguage, getAuthInfo, getLangFromWPLocale, Language } from "../../../../utils/storage";
import { ImgType, StepConfiguratore } from "../..";
import { getVolume, TGalleryItem } from "../../../../redux/reducers/generatoreestint";
import { getLabelERivestimentoVanoDoccia, getLabelEStrutturaVanoDoccia } from "../../../../redux/reducers/struttura_vano_doccia";
import { TPancaCustomConfigurationQuantity, TPancaCustomConfigurationType, TPancaQuantity } from "../../../../redux/reducers/panche";
import Modal, { ModalSize } from "../../../../components/Modal";
import currency from "currency.js";
//@ts-ignore
import HubspotForm from 'react-hubspot-form'
import { EGeneratoreType, getMKitCoibentazione } from "../../../../redux/reducers/generatore";
import smoothscroll from 'smoothscroll-polyfill';
import ActionButton, { IActionButtonType } from "../../../../components/Buttons/ActionButton";
import InnerLoader from "../../../../components/InnerLoader";
import { ConfigurationType } from "../../../../redux/reducers/scelta_configurazione";
import moment from "moment";
import Overview from "../../../Overview";
import ReactDOMServer from "react-dom/server";

interface IRiepilogo {
  mainInternalImg: string;
  layerInternalImgs: ImgType[];
  mainExternalImg: string;
  layerExternalImgs: ImgType[];
  header: any;
  imgWizard: any;
  onGoToStep: any;
  t?: any;
  onScrollBody: (top: number) => void;
}

function Riepilogo({ mainInternalImg, layerInternalImgs, mainExternalImg, layerExternalImgs, onScrollBody, ...props }: IRiepilogo) {
  const dispatch = useDispatch();
  const form: React.Ref<FormInstance<any>> | undefined = useRef(null);
  const riepilogo: TRiepilogoStoreState = useSelector(
    (state: TGeneral) => state.riepilogo
  );
  const store: TGeneralStore = useStore();
  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(true);

  //URL QUOTE
  const [urlQuote, setUrlQuote] = useState<string>();
  const refUrlQuote = useRef(urlQuote);
  const [uuidQuote, setUuidQuote] = useState<string>();
  const [idQuote, setIdQuote] = useState<string>();
  const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
  const [quoteIsGenerated, setQuoteIsGenerated] = useState<boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<TCustomerInfo | null>(null);

  window.HTMLElement.prototype.scrollIntoView = function () { };

  const ID_FORM_USA = "5577cf01-cc64-416d-80dc-28e0659b0a3e"
  const ID_FORM_ITA = "41d4ac33-5d51-4baf-bdd6-58f441345b45"
  const ID_FORM_ENG = "2a4e813e-79cc-4806-9ae1-fcb151c4e148"
  const ID_FORM_DE = "2b66a453-080a-477b-8c12-1ec89fefb027"
  const ID_FORM_ES = "08367b21-cbf2-40dc-850a-2d50f3445a59"
  const ID_FORM_FR = "11c5bdd0-afe5-4218-ba01-8f8488fab23d"

  useEffect(() => {
    if (refUrlQuote?.current) refUrlQuote.current = urlQuote
  }, [urlQuote])

  const formId = getLocalization() === Localization.AMERICA ? ID_FORM_USA :
    (getCrmLanguage() === CrmLanguage.IT || getCrmLanguage() === CrmLanguage.IT_EXPORT) ? ID_FORM_ITA :
      getCrmLanguage() === CrmLanguage.EN ? ID_FORM_ENG :
        getCrmLanguage() === CrmLanguage.DE ? ID_FORM_DE :
          getCrmLanguage() === CrmLanguage.ES ? ID_FORM_ES :
            getCrmLanguage() === CrmLanguage.FR ? ID_FORM_FR :
              ID_FORM_ENG;

  //Generatore
  const [listinoGeneratore, setListinoGeneratore] = useState<Map<string, TListinoItemContentType[]>>(new Map())
  const [totalGeneratore, setTotalGeneratore] = useState<number>(0);
  //Optionals
  const [listinoOptionals, setListinoOptionals] = useState<Map<string, Array<TListinoItemContentType>>>(new Map())
  const [totalOptionals, setTotalOptionals] = useState<number>(0);
  //Porte & Vetrate
  const [listinoPorteVetrate, setListinoPorteVetrate] = useState<TListinoItemContentType[]>([])
  const [listinoPorteVetrateCustom, setListinoPorteVetrateCustom] = useState<{ [key: string]: TListinoItemContentType[] }>({})
  const [totalPorteVetrate, setTotalPorteVetrate] = useState<number>(0);
  const [isLoadingPorteVetrate, setIsLoadingPorteVetrate] = useState<boolean>();
  //Panche
  const [totalPanche, setTotalPanche] = useState<number>(0);
  //Accessori
  const [listinoAccessori, setListinoAccessori] = useState<Map<string, Array<TListinoItemContentType>>>(new Map())
  const [totalAccessori, setTotalAccessori] = useState<number>(0);

  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});

  const MAX_PERC_DISCOUNT = 50

  const getTotal = () => {
    // console.log({totalGeneratore,totalOptionals,totalPorteVetrate,totalPanche,totalAccessori})
    return totalGeneratore + totalOptionals + totalPorteVetrate + totalPanche + totalAccessori
  }
  const getTotalDiscount = () => {
    const perc_discount = store.getState()?.riepilogo?.perc_sconto || 0
    const total_pre_discount = getTotal()
    const total = total_pre_discount - ((total_pre_discount * perc_discount) / 100)
    console.log(`Total: ${total} - discount ${perc_discount}% = ${total} => ${Math.round(total)}`)
    return Math.round(total)
  }

  const isConfigurazioneTypeOnlyPorteVetrate = (): boolean => {
    return store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE
  }

  const initGeneratore = async () => {
    let _total = 0;
    let _mapItemRelated: Map<string, TListinoItemContentType[]> = new Map()

    if (isConfigurazioneTypeOnlyPorteVetrate()) {
      return 0
    }

    for (let indexIt = 0; indexIt < (store?.getState()?.generatore_product?.generatore_model?.acf?.item_related || []).length; indexIt++) {
      const item = store.getState()!.generatore_product!.generatore_model!.acf.item_related[indexIt];
      try {
        let _listinoItem: TListinoItemContentType = await getItem(item.ID)
        /**
         * Per EU prendo tutte le voci di listino
         * Per US prendo il generatore con il voltagigo corretto oppure le voci non collegate al voltaggio (es. controcassa)
         */
        console.log("Slug: ", _listinoItem?.acf?.descrizione?.toLowerCase())
        console.log("Same Volt: ", store.getState()?.voltaggio?.voltaggio?.toString().substr(0, 3) === _listinoItem?.acf?.voltaggio)
        console.log("Same P: ", _listinoItem?.acf?.descrizione?.toLowerCase()?.search(store.getState()?.voltaggio?.voltaggio === EVoltaggioType.v208_3ph ? "3p" : "1p"))

        if (getLocalization() !== Localization.AMERICA ||
          !_listinoItem?.acf?.voltaggio || // per voci di listino != al generatore
          (_listinoItem?.acf?.voltaggio && store.getState()?.generatore?.tipologia === EGeneratoreType.Interno && store.getState()?.voltaggio?.voltaggio?.toString() === _listinoItem?.acf?.voltaggio) || // per generatori interni
          ( // per generatori esterni
            _listinoItem?.acf?.voltaggio &&
            store.getState()?.generatore?.tipologia === EGeneratoreType.Esterno &&
            store.getState()?.voltaggio?.voltaggio?.toString().substr(0, 3) === _listinoItem?.acf?.voltaggio &&
            _listinoItem?.acf?.descrizione?.toLowerCase()?.search(store.getState()?.voltaggio?.voltaggio === EVoltaggioType.v208_3ph ? "3p" : "1p") != -1
          )
        ) {
          const model_slug = store.getState()?.generatore_product?.generatore_model?.slug;
          const is_steam_diffusor = _listinoItem.slug.includes("steam-diffuser-counterbox-nuvola-smart-power");
          let _values: TListinoItemContentType[] = []
          if ((model_slug === SLUG_NUVOLA_SP_250 || model_slug === SLUG_NUVOLA_SP_360) && is_steam_diffusor) {
            _values = [_listinoItem, _listinoItem, _listinoItem, _listinoItem]
          } else if ((model_slug === SLUG_NUVOLA_SP_110 || model_slug === SLUG_NUVOLA_SP_140 || model_slug === SLUG_NUVOLA_SP_180) && is_steam_diffusor) {
            _values = [_listinoItem, _listinoItem]
          } else {
            _values = [_listinoItem]
          }
          _mapItemRelated.set(item.ID, _values)
          _total += toPrice(_listinoItem.acf)
        }
      } catch (error) { }
    }
    if ((getLocalization() === Localization.EUROPA && store.getState()?.struttura_vano_doccia?.kit_coibentazione) ||
      (getLocalization() === Localization.AMERICA && store.getState()?.struttura_vano_doccia?.kit_coibentazione && store.getState()?.struttura_vano_doccia?.add_kit_coibentazione_to_quote)) {
      const _larghezza = getLocalization() === Localization.EUROPA ? store.getState()?.vano_doccia?.larghezza! : getCmFromFtAndInch(store.getState()?.vano_doccia?.larghezza!, store.getState()?.vano_doccia?.larghezza_inc!)
      const _profondita = getLocalization() === Localization.EUROPA ? store.getState()?.vano_doccia?.profondita! : getCmFromFtAndInch(store.getState()?.vano_doccia?.profondita!, store.getState()?.vano_doccia?.profondita_inc!)
      const _altezza = getLocalization() === Localization.EUROPA ? store.getState()?.vano_doccia?.altezza! : getCmFromFtAndInch(store.getState()?.vano_doccia?.altezza!, store.getState()?.vano_doccia?.altezza_inc!)

      const _larghezza_porta = Number(store.getState()?.porta_vetrata.modello_porta?.acf?.standard_configuration_fields?.x_axis_door || store.getState()?.porta_vetrata?.larghezza_porta)
      const _altezza_porta = Number(store.getState()?.porta_vetrata.modello_porta?.acf?.standard_configuration_fields?.y_axis_door || store.getState()?.porta_vetrata?.altezza_porta)

      const _larghezza_vetrata = Number(store.getState()?.porta_vetrata.modello_vetrata?.acf?.standard_configuration_fields?.x_axis_door || store.getState()?.porta_vetrata?.larghezza_vetrata)
      const _altezza_vetrata = Number(store.getState()?.porta_vetrata.modello_vetrata?.acf?.standard_configuration_fields?.y_axis_door || store.getState()?.porta_vetrata?.altezza_vetrata)

      const metri_kit_coibentazione = getMKitCoibentazione(_larghezza, _profondita, _altezza,
        _larghezza_porta || 0,
        _altezza_porta || 0,
        _larghezza_vetrata || 0,
        _altezza_vetrata || 0,
        getLocalization() !== Localization.AMERICA);
      console.log(`Metri kit coibentazione: ${metri_kit_coibentazione}`, {
        _larghezza, _profondita, _altezza,
        _larghezza_porta,
        _altezza_porta,
        _larghezza_vetrata,
        _altezza_vetrata
      })
      let kits: TOptionalDetailType[] = []
      if (getLocalization() !== Localization.AMERICA) {
        if (metri_kit_coibentazione <= 21) { //Base case
          const _kit = await getKitCoibentazioneWithSlug(metri_kit_coibentazione <= 12 ? EKitCoibentazioneModel.KIT_0_12 : metri_kit_coibentazione > 12 && metri_kit_coibentazione <= 15 ? EKitCoibentazioneModel.KIT_12_15 : EKitCoibentazioneModel.KIT_15_21)
          kits.push(_kit[0])
        }
        else if (metri_kit_coibentazione <= 24) {
          //12+12
          const kit_coibentazione_12 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_0_12)
          kits.push(kit_coibentazione_12[0])
          kits.push(kit_coibentazione_12[0])
        } else if (metri_kit_coibentazione <= 27) {
          //12+15
          const kit_coibentazione_12 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_0_12)
          const kit_coibentazione_15 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_12_15)
          kits.push(kit_coibentazione_12[0])
          kits.push(kit_coibentazione_15[0])
        }
        else if (metri_kit_coibentazione <= 30) {
          //15+15
          const kit_coibentazione_15 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_12_15)
          kits.push(kit_coibentazione_15[0])
          kits.push(kit_coibentazione_15[0])
        } else if (metri_kit_coibentazione <= 36) {
          //15+21
          const kit_coibentazione_15 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_12_15)
          const kit_coibentazione_21 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_15_21)
          kits.push(kit_coibentazione_15[0])
          kits.push(kit_coibentazione_21[0])
        } else {
          //21+21 or more
          const kit_coibentazione_21 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_15_21)
          kits.push(kit_coibentazione_21[0])
          kits.push(kit_coibentazione_21[0])
        }
      } else {
        /**
         * Per US:
         * up to 128 SQFT => 11.89 m2 ~~ 12 m2
         * up to 192 SQFT => 17.84 m2 ~~ 18 m2
         * up to 288 SQFT => 26.76 m2 ~~ 27 m2
         * 
         * 23,78 (12, 12) NO
         * 29,73 (12, 18)
         * 38,65 (12, 27)
         * 44,6 (18, 27)
         */

        if (metri_kit_coibentazione <= 26.76) { //Base case
          const _kit = await getKitCoibentazioneWithSlug(metri_kit_coibentazione <= 11.89 ? EKitCoibentazioneModel.KIT_0_12 : metri_kit_coibentazione > 11.89 && metri_kit_coibentazione <= 17.84 ? EKitCoibentazioneModel.KIT_12_15 : EKitCoibentazioneModel.KIT_15_21)
          kits.push(_kit[0])
        }
        else if (metri_kit_coibentazione <= 29.73) {
          //12+18
          const kit_coibentazione_12 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_0_12)
          const kit_coibentazione_18 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_12_15)
          kits.push(kit_coibentazione_12[0])
          kits.push(kit_coibentazione_18[0])
        } else if (metri_kit_coibentazione <= 38.65) {
          //12+27
          const kit_coibentazione_12 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_0_12)
          const kit_coibentazione_27 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_15_21)
          kits.push(kit_coibentazione_12[0])
          kits.push(kit_coibentazione_27[0])
        } else {
          //18+27 or more
          const kit_coibentazione_18 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_12_15)
          const kit_coibentazione_27 = await getKitCoibentazioneWithSlug(EKitCoibentazioneModel.KIT_15_21)
          kits.push(kit_coibentazione_18[0])
          kits.push(kit_coibentazione_27[0])
        }
      }

      console.log("KITS", kits)

      //3 casi entro i 21 m
      if (kits.length) {
        for (let index = 0; index < kits.length; index++) {
          try {
            const item_id = kits[index]?.acf?.item_relationship?.[0]?.ID;
            let _listinoItem: TListinoItemContentType = await getItem(item_id)
            if (_mapItemRelated.has(item_id)) {
              _mapItemRelated.set(item_id, _mapItemRelated.get(item_id)!.concat([_listinoItem]))
            } else {
              _mapItemRelated.set(item_id, [_listinoItem])
            }

            _total += toPrice(_listinoItem.acf)
          } catch (error) { }
        }
      }
    }

    for (const optionalForProduct of (store?.getState()?.generatore_product?.optionalsForModel||[])) {
      for (const item_relationship of optionalForProduct.optional.acf.item_relationship) {
        const item_id = item_relationship.ID;
        let _listinoItem: TListinoItemContentType = await getItem(item_id)
        _listinoItem = {..._listinoItem, title: {
          ..._listinoItem.title,
          rendered: `${_listinoItem.title.rendered}`
        }}
        console.log("OptionalForProduct",optionalForProduct.optional.name , item_id)
        if (_mapItemRelated.has(item_id)) {
          _mapItemRelated.set(item_id, _mapItemRelated.get(item_id)!.concat([_listinoItem]))
        } else {
          _mapItemRelated.set(item_id, [_listinoItem])
        }
        _total += toPrice(_listinoItem.acf)
      }
    }

    console.log({_mapItemRelated})
    setListinoGeneratore(_mapItemRelated)
    setTotalGeneratore(_total)
  }
  const initPorteVetrate = async () => {
    setIsLoadingPorteVetrate(true)

    //Selezionata porta standard
    if (store!.getState()!.porta_vetrata?.porta_listino) {
      let _total = 0;
      let _list: TListinoItemContentType[] = []

      //Viene selezionata esattamente la voce di listino della porta
      const _pListinoItems = store!.getState()!.porta_vetrata?.porta_listino || []
      for (let pListIndex = 0; pListIndex < _pListinoItems.length; pListIndex++) {
        const _pListinoItem = _pListinoItems[pListIndex];
        _total += toPrice(_pListinoItem?.acf)
        _list.push(_pListinoItem)
      }

      //Vetrata
      for (let indexIt = 0; indexIt < (store!.getState()?.porta_vetrata?.modello_vetrata?.acf?.item_relationship || []).length; indexIt++) {
        const item = store!.getState()?.porta_vetrata?.modello_vetrata!.acf!.item_relationship[indexIt];
        try {
          let _listinoItem = await getItem(item.ID)
          _list.push(_listinoItem)
          _total += toPrice(_listinoItem.acf)
        } catch (error) { }
      }

      setListinoPorteVetrate(_list)
      setTotalPorteVetrate(_total)
    }
    //Selezionata porta custom
    else if (store!.getState()!.porta_vetrata?.porta_prodotto) {
      let _total = 0;

      const pricingPortaVetrata = getPricesCustomPortaVetrata(store!.getState()!.porta_vetrata)
      console.log("pricingPortaVetrata", pricingPortaVetrata)
      Object.values(pricingPortaVetrata).forEach(pricingPortaVetrataItem => {
        _total += pricingPortaVetrataItem.map(a => toPrice(a.acf)).reduce((a, b) => a + b, 0)
      });
      setListinoPorteVetrateCustom(pricingPortaVetrata)
      setTotalPorteVetrate(_total)
    }

    setIsLoadingPorteVetrate(false)
  }
  const initOptionals = async () => {
    let _total = 0;
    let _mapOptionals: Map<string, Array<TListinoItemContentType>> = new Map()
    if (Array.isArray(store?.getState()?.optional?.optionals)) {
      for (let index = 0; index < store.getState()!.optional!.optionals!.length; index++) {
        const optional = store.getState()!.optional!.optionals![index];
        let _listinoItems: Array<TListinoItemContentType> = []
        for (let indexIt = 0; indexIt < (optional?.optional?.acf?.item_relationship || []).length; indexIt++) {
          const item = optional?.optional?.acf?.item_relationship[indexIt];
          try {
            let _listinoItem = await getItem(item.ID)
            _listinoItems.push(_listinoItem)
            _total += toPrice(_listinoItem.acf)
          } catch (error) { }
        }
        _mapOptionals.set(optional?.optional?.id, _listinoItems)
      }
    }
    setListinoOptionals(_mapOptionals)
    setTotalOptionals(_total)
  }
  const initPanchine = async () => {
    let _total = 0;
    if (store!.getState()!.panche?.panche && store!.getState()!.panche?.panche?.length) {
      store!.getState()!.panche!.panche!.forEach(p => {
        _total += ((p.quantity || 1) * toPrice(p.item_listino?.acf))
      });
    } else if (store!.getState()!.panche?.panca_custom) {
      store!.getState()!.panche?.panca_custom?.configurations.forEach(c => {
        _total += ((c.quantity || 1) * toPricePancheCustom(c.panca_custom!.price_euro))
      });
    }
    setTotalPanche(_total)
  }
  const initAccessories = async () => {
    let _total = 0;
    let _mapAccessori: Map<string, Array<TListinoItemContentType>> = new Map()
    if (Array.isArray(store?.getState()?.accessori?.accessori)) {
      for (let index = 0; index < store.getState()!.accessori!.accessori!.length; index++) {
        const accessorio = store.getState()!.accessori!.accessori![index];
        let _listinoItems: Array<TListinoItemContentType> = []
        for (let indexIt = 0; indexIt < (accessorio?.accessorio?.acf?.item_relationship || []).length; indexIt++) {
          const item = accessorio?.accessorio?.acf?.item_relationship[indexIt];
          try {
            let _listinoItem = await getItem(item.ID)
            _listinoItems.push({ ..._listinoItem, multipli_for_quantity_sku: item?.multipli_for_quantity_sku, label_quantity: item?.label_quantity })
            let sub_total = (toPrice(_listinoItem.acf) * (item?.multipli_for_quantity_sku ? (item?.ID === getIdLightHammam() ? accessorio.quantity / 100 : accessorio.quantity) : 1))
            _total += sub_total
          } catch (error) { }
        }
        _mapAccessori.set(accessorio?.accessorio?.id, _listinoItems)
      }
    }
    setListinoAccessori(_mapAccessori)
    console.log("TOTAL_ACC", _total)
    setTotalAccessori(_total)
  }

  const initRiepilogo = async () => {
    console.log(`RIEPILOGO CON PREZZI DI LISTINO ${getAuthInfo()?.crm_language}`)
    setIsLoadingPrices(true)
    await initGeneratore()
    await initOptionals()
    await initPorteVetrate() //no US
    await initPanchine() //no US
    await initAccessories()
    setIsLoadingPrices(false)
  }

  useEffect(() => {
    initRiepilogo()

    smoothscroll.polyfill();

    // LINK
    const uuid_quote = uuidv4();
    setUuidQuote(uuid_quote)
  }, [])



  useEffect(() => {
    initOptionals()
  }, [store?.getState()?.optional])
  useEffect(() => {
    initPorteVetrate()
  }, [store!.getState()?.porta_vetrata])
  useEffect(() => {
    initPanchine()
  }, [store!.getState()?.panche])
  useEffect(() => {
    initAccessories()
  }, [store!.getState()?.accessori])


  const openModal = (title: string, content: any, size: any, fullScreen: boolean) => {
    setModalData({ title, content, size, fullScreen });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
  };


  const getMainImageGeneratore = (): string => {
    return store?.getState()?.generatore_product?.generatore_product?.acf?.steam_image?.url || ""
  }
  const getTitleGeneratore = () => {
    return props.t("v3.generatore.riepilogo.title")//store?.getState()?.generatore_product?.generatore_product?.title?.rendered?.replace("#038;","")||""
  }
  const getTitleOptional = (opt: TOptionalQuantity): TListinoItemContentType[] => {
    return listinoOptionals.get(opt.optional?.id)?.map(t => {
      return {...t, title: { ...t.title, rendered: opt?.optional?.slug===SLUG_OPTIONAL_STEAM_DIFFUSER_FINISHES ? (opt?.variant||"")  : t?.title?.rendered}}
    }) || []
  }
  const getMainImageOptional = (): string[] => {
    return store!.getState()!.optional?.optionals?.map(optional => optional?.optional?._embedded?.["wp:featuredmedia"]?.[0].source_url) || []
  }
  const getTitleAccessorio = (acc: TAccessorioQuantity): TListinoItemContentType[] => {
    return listinoAccessori.get(acc.accessorio?.id)?.map(t => t) || []
  }
  const getMainImageAccessori = (): string[] => {
    return store!.getState()!.accessori?.accessori?.map(a => a.accessorio?._embedded?.["wp:featuredmedia"]?.[0].source_url) || []
  }
  const getMainImagePanche = () => {
    return (store!.getState()!.panche?.panca_custom ?
      store!.getState()!.panche?.panca_custom?.configurations?.map(c => c?.panca_custom?.img) :
      store!.getState()!.panche?.panche?.map(p => p.panca?._embedded?.["wp:featuredmedia"]?.[0]?.source_url))
  }
  const getTitlePortaCustom = (c: TPancaCustomConfigurationType | undefined) => {
    return `${c?.id} | ${c?.name} ${c?.dimensioni ? `(${c?.dimensioni})` : ""}`
  }
  const getPricePancaCustom = (c: TPancaCustomConfigurationQuantity) => {
    return toPricePancheCustom(c?.panca_custom!.price_euro)
  }
  const getPricePancaStd = (p: TPancaQuantity) => {
    return toPrice(p?.item_listino!.acf)
  }
  const getPricePortaVetrata = (pv: TListinoItemContentType) => {
    return toPrice(pv.acf)
  }
  const getMainImagePortaVetrata = (): string[] => {
    const _imgPorta = store!.getState()!.porta_vetrata?.porta_prodotto?._embedded?.["wp:featuredmedia"]?.[0]?.source_url || ""
    const _imgVetrata = store!.getState()!.porta_vetrata?.modello_vetrata?._embedded?.["wp:featuredmedia"]?.[0]?.source_url
    return [_imgPorta].concat(_imgVetrata ? [_imgVetrata] : [])
  }
  const getDimensioneBoxDoccia = () => {
    return `${(`${store.getState()?.vano_doccia?.larghezza || 0}`)}${getUnitOfMeasure().toLowerCase()} x ${(`${store.getState()?.vano_doccia?.profondita || 0}`)}${getUnitOfMeasure().toLowerCase()} x ${(`${store.getState()?.vano_doccia?.altezza || 0}`)}${getUnitOfMeasure().toLowerCase()}`
  }
  const getVolumeBoxDocciaCm = () => {
    const m3 = getVolume(store.getState()!.vano_doccia);
    return getLocalization() === Localization.AMERICA ? Math.round(fromMCubiToFeetCubi(m3)) : m3
  }
  const getVolumeBoxDoccia = () => {
    return `${getVolumeBoxDocciaCm()} ${props.t(getUnitOfMeasureMCubiLabel())}`
  }
  const getStrutturaVano = () => {
    return props.t(getLabelEStrutturaVanoDoccia(store.getState()?.struttura_vano_doccia.struttura!))
  }
  const getRivestimentoVano = () => {
    return props.t(getLabelERivestimentoVanoDoccia(store.getState()?.struttura_vano_doccia.rivestimento!))
  }
  const getKitCoibentazione = () => {
    return (!!store.getState()?.struttura_vano_doccia.kit_coibentazione ? "YES" : "NO")
  }
  const getAddKitCoibentazioneToQuote = () => {
    return (!!store.getState()?.struttura_vano_doccia.add_kit_coibentazione_to_quote ? "YES" : "NO")
  }
  const getVoltaggio = () => {
    return props.t(getLabelEVoltaggioType(store.getState()?.voltaggio.voltaggio!))
  }

  const getDetailCustomPorta = () => {
    if (isCustomPortaVetrata(store!.getState()!.porta_vetrata?.porta_prodotto?.slug || "")) {
      let _blocks = [
        `${store!.getState()!.porta_vetrata?.materiale?.title?.rendered}`,
        `${props.t("porte_vetrate.dim_porta")}: ${store!.getState()!.porta_vetrata?.larghezza_porta} x ${store!.getState()!.porta_vetrata?.altezza_porta} h`
      ]
      if (store!.getState()!.porta_vetrata?.versione) {
        _blocks.push(`${props.t(getLabelForESideCerniera(store!.getState()!.porta_vetrata?.versione))}`)
      }
      if (store.getState()?.vetrate?.quantita === 2 && !isVetroPortaVetroPortaModel(store.getState().porta_vetrata.modello_porta!)) {
        _blocks.push(`${props.t("porte_vetrate.dim_vetrata")}: ${store!.getState()!.porta_vetrata?.larghezza_vetrata} x ${store!.getState()!.porta_vetrata?.altezza_vetrata} h`)
      }
      if (store!.getState()!.porta_vetrata?.porta_prodotto?.slug === SLUG_TUTTOVETRO_INSIDE) {
        _blocks.push(props.t("v2.price_compreso_controtelaio_incasso"))
      }

      return _blocks;
    } else {
      return []
    }
  }

  async function writeDataToClipboard(text: string) {
    try {
      let result = await navigator.clipboard.writeText(text);
      notification["success"]({
        message: props.t("v2.link_copiato")
      })
    } catch (error) {
      console.log(`Error copy to clip`, error)
    }
  }



  // Hacky equivalent of $().change()
  // http://youmightnotneedjquery.com/#trigger_native
  const jqueryChange = (elem: any) => {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('change', true, false)
    elem.dispatchEvent(event)
  }

  const setInputValue = (form: any, inputName: any, value: any) => {
    const input = form?.[inputName]
    if (input) {
      input.value = value
      jqueryChange(input)
    }
  }
  const getInputValue = (form: any, inputName: any) => {
    const input = form?.[inputName]
    return input?.value;
  }

  useEffect(() => {
    window.addEventListener('message', handlerSubmitForm);
    window.addEventListener('message', handlerBeforeSubmitForm);
    return () => {
      window.removeEventListener('message', handlerSubmitForm);
      window.removeEventListener('message', handlerBeforeSubmitForm);
    }
  }, [])

  function handlerBeforeSubmitForm(event: any) {
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
      console.log("[HUBSPOT] onFormSubmit")
      //@ts-ignore
      const form = document.forms[`hsForm_${formId}`]
      if (form) {
        setCustomerInfo({
          firstname: getInputValue(form, "firstname"),
          lastname: getInputValue(form, "lastname"),
          company_name: getInputValue(form, "0-2/name"),
          city: getInputValue(form, "city"),
          country: getInputValue(form, "country"),
          riferimento_nome_del_progetto: getInputValue(form, "riferimento_nome_del_progetto"),
          commenti_per_l_acquirente: getInputValue(form, "commenti_per_l_acquirente")
        })
      }
    }
  }

  const goToPrevStep = (onOk: any) => {
    openModal(props.t("general.attenzione"),
      () => {
        return (<div className="flex flex-col items-center">
          <p className="text-left">
            {props.t("v3.torna_step")}
          </p>
          <ActionButton
            importance={IActionButtonType.Secondary}
            title={props.t("v3.si_torna_step")} onClick={() => {
              onOk && onOk()
              closeModal()
            }} className="mt-4" />
        </div>)
      }, ModalSize.lg, false)
  }

  useEffect(() => {
    if (formIsSubmitted) {
      submitQuote()
    }
  }, [formIsSubmitted])

  const getIdQuote = (company_name: string | undefined, first_name: string | undefined, last_name: string | undefined, riferimento_nome_del_progetto: string | undefined) => {
    return encodeURIComponent(`${moment().format("YYMMDD")}-${company_name ? `${company_name}` : `${first_name}-${last_name}`}${riferimento_nome_del_progetto ? `-${riferimento_nome_del_progetto}` : ""}-${moment().format("HHmm")}`)
  }
  const getUrlQuote = (id_quote: string) => {
    const _host = window.location.hostname === "localhost" ? `${window.location.hostname}:3000` : window.location.hostname
    return `${window.location.protocol}//${_host}/overview/${id_quote}`
  }

  const submitQuote = async () => {
    try {
      let link_quote = ""
      let id_quote = getIdQuote(customerInfo?.company_name, customerInfo?.firstname, customerInfo?.lastname, customerInfo?.riferimento_nome_del_progetto)
      if (!quoteIsGenerated) {
        const payload: TRiepilogoPayload = {
          uuid: uuidQuote!,
          link: id_quote,
          data: {
            generatore: {
              subtotal: `${formatPriceForContext(totalGeneratore)}`,
              images: [getMainImageGeneratore()] || [],
              name: getTitleGeneratore(),
              model_items:
                Array.from(listinoGeneratore.values()).sort((a, b) => toPrice(b?.[0]?.acf) - toPrice(a?.[0]?.acf)).map(its => {
                  const it = its[0]
                  return ({
                    name: it?.title?.rendered?.replaceAll("#038;", ""),
                    price: `${formatPriceForContext(toPrice(it?.acf) * (its.length || 1))}`,
                    units: its.length
                  })
                })
            },
            optionals: {
              subtotal: `${formatPriceForContext(totalOptionals)}`,
              images: getMainImageOptional() || [],
              optionals: store.getState()?.optional?.optionals?.map(opt => {
                return ({
                  name: opt.optional?.title?.rendered,
                  price: ``,
                  subitems: (getTitleOptional(opt) || []).map((z) => {
                    return (
                      {
                        name: z?.title?.rendered,
                        price: `${formatPriceForContext(toPrice(z.acf))}`
                      }
                    )
                  })
                })
              })
            },
            porte_vetrate: {
              subtotal: `${formatPriceForContext(totalPorteVetrate)}`,
              images: getMainImagePortaVetrata() || [],
              isCustom: isCustomPortaVetrata(store!.getState()!.porta_vetrata?.porta_prodotto?.slug || ""),
              custom_extra_info: getDetailCustomPorta(),
              numParetiVetrata: store!.getState()!.vetrate.quantita,
              custom_items: Object.keys(listinoPorteVetrateCustom).map((keyPvCustom, indexPv) => {
                return ({
                  title: listinoPorteVetrateCustom?.[keyPvCustom]?.[0]?.title?.rendered,
                  subItems: getSubItemsPortaVetrataCustom(keyPvCustom)
                })
              }),
              model_items: isCustomPortaVetrata(store!.getState()!.porta_vetrata?.porta_prodotto?.slug || "") ? [] : listinoPorteVetrate.sort((a, b) => getPricePortaVetrata(b) - getPricePortaVetrata(a)).map(pv => {
                return ({
                  name: pv.title?.rendered,
                  price: `${formatPriceForContext(getPricePortaVetrata(pv))}`,
                })
              })
            },
            panche: {
              subtotal: `${formatPriceForContext(totalPanche)}`,
              images: getMainImagePanche() || [],
              panche: store!.getState()!.panche?.panca_custom ?
                store!.getState()!.panche?.panca_custom?.configurations.map(c => {
                  return ({
                    name: getTitlePortaCustom(c.panca_custom),
                    units: c.quantity,
                    price: `${formatPriceForContext(getPricePancaCustom(c) * (c.quantity || 1))}`
                  })
                })
                :
                store!.getState()!.panche?.panche?.map(p => {
                  return ({
                    name: p?.item_listino?.title?.rendered,
                    unit: p?.quantity,
                    price: formatPriceForContext(getPricePancaStd(p) * (p?.quantity || 1))
                  })
                })
            },
            accessori: {
              subtotal: `${formatPriceForContext(totalAccessori)}`,
              images: getMainImageAccessori() || [],
              accessori: store.getState()?.accessori?.accessori?.map(a => {
                return ({
                  name: a.accessorio?.title?.rendered,
                  price: ``, //${formatPriceForContext(getPriceForAccessorio(a))}
                  quantity: a.quantity || 1,
                  subitems: (getTitleAccessorio(a) || []).map((z) => {
                    return (
                      {
                        name: z?.title?.rendered,
                        price: `${formatPriceForContext(toPrice(z.acf) * (z.multipli_for_quantity_sku ? (a.quantity / 100) : 1))}`,
                        multipli_for_quantity_sku: z.multipli_for_quantity_sku,
                        label_quantity: z.multipli_for_quantity_sku && z.label_quantity ? z.label_quantity : ""
                      }
                    )
                  })
                })
              })
            },
            info_tecniche: {
              dimensione_box: isConfigurazioneTypeOnlyPorteVetrate() ? undefined : getDimensioneBoxDoccia(),
              volume: isConfigurazioneTypeOnlyPorteVetrate() ? undefined : getVolumeBoxDoccia(),
              volt: isConfigurazioneTypeOnlyPorteVetrate() ? undefined : getVoltaggio(),
              kit_coibentazione: isConfigurazioneTypeOnlyPorteVetrate() ? undefined : getKitCoibentazione(),
              add_kit_coibentazione_to_quote: isConfigurazioneTypeOnlyPorteVetrate() ? undefined : getAddKitCoibentazioneToQuote(),
              rivestimento: isConfigurazioneTypeOnlyPorteVetrate() ? undefined : getRivestimentoVano(),
              struttura: isConfigurazioneTypeOnlyPorteVetrate() ? undefined : getStrutturaVano()
            }
          } as TRiepilogoPayloadData,
          type_configuration: store.getState()?.scelta_configurazione?.type,
          language: getLangFromWPLocale() || "en",
          localization: getLocalization(),
          price: getTotal(),
          price_discounted: getTotalDiscount(),
          percentual_discount: store.getState()?.riepilogo?.perc_sconto || 0,
          valute: getValute(),
          timestamp: new Date().getTime(),
          customer_info: customerInfo,
          version: "4", //rework May 2022 (configurazione solo porte e vetrate)
          mainInternalImg,
          layerInternalImgs,
          mainExternalImg,
          layerExternalImgs

        }
        await addRiepilogo(id_quote, payload)
        setIdQuote(id_quote)
        link_quote = getUrlQuote(id_quote)
        setUrlQuote(link_quote)

        setQuoteIsGenerated(true)
        writeDataToClipboard(link_quote!)
      } else {
        writeDataToClipboard(urlQuote!)
      }
      document.getElementById("riepilogo-view")?.scrollTo({
        top: document.getElementById("form-costi")?.offsetTop,
        behavior: 'smooth',
      })

    } catch (error) {
      notification["error"]({
        message: props.t("") //TODO:
      })
    }
  }

  function handlerSubmitForm(event: any) {
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      setFormIsSubmitted(true);
    }
  }

  let infoLabels: any[] = []
  let values: any[] = []
  let infoOnClicks: any[] = []

  if (!isConfigurazioneTypeOnlyPorteVetrate()) {
    infoLabels = [props.t("v2.dim_box_doccia"), props.t("v2.volume"), props.t("vanodoccia_struttura.rivestimento.title")]
    values = [getDimensioneBoxDoccia(), getVolumeBoxDoccia(), getRivestimentoVano()]
    infoOnClicks = [() => props.onGoToStep(StepConfiguratore.VanoDoccia), () => props.onGoToStep(StepConfiguratore.VanoDoccia),
    () => props.onGoToStep(StepConfiguratore.StrutturaVanoDoccia)]
    if (getLocalization() === Localization.EUROPA) {
      infoLabels.push(props.t("vanodoccia_struttura.struttura.title"))
      values.push(getStrutturaVano())
      infoOnClicks.push(() => props.onGoToStep(StepConfiguratore.StrutturaVanoDoccia))
    }
    if (getLocalization() === Localization.AMERICA) {
      infoLabels = infoLabels.concat([props.t("vanodoccia_struttura.kit_coibentazione.modal.title"), props.t("voltaggio.titolo")])
      values = values.concat([getKitCoibentazione(), getVoltaggio()])
      infoOnClicks = infoOnClicks.concat([() => props.onGoToStep(StepConfiguratore.StrutturaVanoDoccia), () => props.onGoToStep(StepConfiguratore.Voltaggio)])
    }
  }


  if (isCustomPortaVetrata(store!.getState()!.porta_vetrata?.porta_prodotto?.slug || "")) {

    infoLabels = infoLabels.concat([`${props.t("porte_vetrate.titolo")}`])
    values = values.concat([getDetailCustomPorta().join(" - ")])
    infoOnClicks = infoOnClicks.concat([() => props.onGoToStep(StepConfiguratore.PorteVetrate)])
  }

  const getSubItemsPortaVetrataCustom = (key: string): { title: string, price: string, valute: string }[] => {
    return listinoPorteVetrateCustom[key]?.map(it => {
      return ({
        valute: getValute(),
        price: formatPriceForContext(toPrice(it.acf)),
        title: `${it.acf?.sku} | ${it.title.rendered}`.split("-")?.[0].toUpperCase()
      })
    })
  }

  return (
    <Row className="overflow-scroll h-full" id="riepilogo-view"
      onScroll={(e: any) => {
        onScrollBody(e.target?.scrollTop)
      }}>
      <Col lg={6} className="xs:pb-0 lg:pb-48">
        <div>
          {props?.header()}
          <H3
            title={props.t("v3.riepilogo_prodotti")}
            className="text-left text-black-effe m-title mt-2"
          />


          <Row>
            <Col lg={12}>
              {
                !isConfigurazioneTypeOnlyPorteVetrate() &&
                <div className="mt-2">
                  <RiepilogoGridItem
                    title={getTitleGeneratore()}
                    disclaimer={!store.getState()?.struttura_vano_doccia?.kit_coibentazione ? props.t("v8.disclaimer_no_kit_coibentazione") : null}
                    subtitle={totalGeneratore ? `${formatPriceForContext(totalGeneratore)} ${getValute()}` : ""}
                    descriptionItems={
                      isLoadingPrices ?
                        [<div className="flex justify-start"><InnerLoader small /></div>]
                        :
                        Array.from(listinoGeneratore.values()).sort((a: TListinoItemContentType[], b: TListinoItemContentType[]) => {
                          return toPrice(b[0].acf) - toPrice(a[0].acf)
                        }).map((its: TListinoItemContentType[], indexIt) => {
                          const it = its[0]
                          return (
                            <RiepilogoItem
                              className={indexIt === 0 ? "mt-4" : "mt-2"}
                              key={it?.id}
                              separator={indexIt > 1}
                              bold={true}
                              title={it?.title?.rendered?.replaceAll("#038;", "")?.toUpperCase()}
                              priceUnit={formatPriceForContext(toPrice(it.acf) * its.length)}
                              units={its.length}
                              isMultipleUnits={its.length > 1}
                              isLoadingPrice={!listinoGeneratore.has(it?.id)}
                              image={indexIt === 0 ? getMainImageGeneratore() : ""}
                              actionDescription={indexIt === 0 && props.t("riepilogo.download_documentazione")}
                              actionOnClick={() => {
                                if (indexIt !== 0) return;
                                const _documents = store!.getState()!.generatore_product!.generatore_product?.acf.documents_related_collection_group
                                if (_documents && Array.isArray(_documents)) {
                                  openModal(`${props.t("riepilogo.download_documentazione")} - ${getTitleGeneratore()}` || props.t("riepilogo.download_documentazione"),
                                    () => {
                                      return (<div className="flex flex-col">
                                        {_documents.map(d => {
                                          return (<div className="flex mb-2">
                                            <a className="text-fluo-effe underline flex" href={d.resource_url} target="_blank">
                                              <IconLink fill={tailwindMyConfig.theme.extend.colors["fluo-effe"]} className="mr-2 disable-animation" />
                                              <span>{d.document_title}</span>
                                            </a>
                                          </div>)
                                        })}
                                      </div>)
                                    }, ModalSize.lg, false)
                                }


                              }}
                            />
                          )
                        }).concat(
                          store!.getState()!.struttura_vano_doccia.add_kit_coibentazione_to_quote === false ?
                            [
                              <p style={{ paddingLeft: 30 }} className="mb-0 pt-2 w-full text-xs text-wizard-effe text-left" >
                                {"We recommend to insulate your walls before the waterproofing. Several different brands have solutions for Steam Shower/Steam Room. Check the best solution with your tile contractor.The steam generator has been sized assuming that you’re installing a thermal insulation on your walls"}
                              </p>
                            ]
                            : []
                        )
                    }

                    onClick={() => {
                      goToPrevStep(() => props.onGoToStep(StepConfiguratore.GeneratoreEstInt))
                    }}

                  />
                </div>
              }
              {
                (store!.getState()!.optional?.optionals || []).length > 0 &&
                <div className="mt-5">
                  <RiepilogoGridItem
                    title={props.t("riepilogo.optional")}
                    subtitle={totalOptionals ? `${formatPriceForContext(totalOptionals)} ${getValute()}` : ""}
                    // images={getMainImageOptional() || [PLACEHOLDER_IMAGE]}
                    onClick={() => {
                      goToPrevStep(() => props.onGoToStep(StepConfiguratore.Optional))
                    }}
                    descriptionItems={store!.getState()!.optional!.optionals!.map((opt: TOptionalQuantity, indexIt) => {
                      return (
                        <RiepilogoItem
                          className={`py-3 ${indexIt === 0 && "mt-2"}`}
                          key={opt?.optional?.id}
                          title={opt.optional?.title?.rendered}
                          bold
                          subItems={(getTitleOptional(opt) || []).map((z, indexOptList) => {
                            return (
                              {
                                title: z?.title?.rendered,
                                price: formatPriceForContext(toPrice(z.acf)),
                                valute: getValute()
                              }
                            )
                          })}
                          isLoadingPrice={!listinoOptionals.has(opt.optional?.id)}
                          onDelete={(formIsSubmitted || !!opt.disableDelete) ? null : () => {
                            const indexOptionalSelected = store!.getState()?.optional?.optionals?.map(o => o.optional.id).indexOf(opt.optional.id)
                            let _optionals = store!.getState()?.optional?.optionals || []
                            if (indexOptionalSelected !== -1) {
                              _optionals.splice(indexOptionalSelected!, 1)
                              dispatch(actGeneratoreSetOptionals({
                                optionals: _optionals
                              }))
                            }
                          }} />
                      )
                    })} />
                </div>
              }

              {
                (store!.getState()!.porta_vetrata?.porta_listino || store!.getState()!.porta_vetrata?.porta_prodotto) &&
                <div className="mt-2">
                  <RiepilogoGridItem
                    title={props.t("riepilogo.porte_vetrate")}
                    subtitle={totalPorteVetrate ? `${formatPriceForContext(totalPorteVetrate)} ${getValute()}` : ""}
                    onClick={() => {
                      goToPrevStep(() => props.onGoToStep(StepConfiguratore.PorteVetrate))
                    }}
                    descriptionItems={
                      isLoadingPorteVetrate ?
                        [<div className="flex justify-start"><InnerLoader small /></div>]
                        :
                        store!.getState()!.porta_vetrata?.porta_listino ? [
                          listinoPorteVetrate.sort((pvA, pvB) => {
                            return (getPricePortaVetrata(pvB) - getPricePortaVetrata(pvA))
                          }).map((pv, indexPv) => {
                            return (
                              <RiepilogoItem
                                className={`${indexPv === 0 && "mt-3"}`}
                                classNameMainItem={"py-3"}
                                key={pv.id}
                                bold={true}
                                separator={indexPv > 0}
                                title={pv?.title?.rendered}
                                priceUnit={formatPriceForContext(getPricePortaVetrata(pv))}
                                isLoadingPrice={listinoPorteVetrate.map(pvcurr => pvcurr?.id).indexOf(pv?.id) === -1}
                              />
                            )
                          })
                        ] : store!.getState()!.porta_vetrata?.porta_prodotto ? [
                          Object.keys(listinoPorteVetrateCustom).map((keyPvCustom, indexPv) => {
                            return (
                              <RiepilogoItem
                                className={`${indexPv === 0 && "mt-3"}`}
                                classNameMainItem={"py-3"}
                                key={listinoPorteVetrateCustom?.[keyPvCustom]?.[0].id}
                                bold
                                // separator={listinoPorteVetrateCustom[keyPvCustom].length > 1}
                                title={listinoPorteVetrateCustom?.[keyPvCustom]?.[0]?.title?.rendered}
                                subItems={getSubItemsPortaVetrataCustom(keyPvCustom)}
                              />
                            )
                          })
                        ] : []
                    } />
                </div>
              }

              {//La custom ha i prezzi solo in euro
                ((store!.getState()!.panche?.panche || []).length > 0 || store!.getState()!.panche?.panca_custom) &&
                <div className="mt-2">
                  <RiepilogoGridItem
                    title={props.t("riepilogo.panche")}
                    subtitle={store!.getState()!.panche?.panca_custom ? `${formatPriceForContext(totalPanche)} €` : (totalPanche ? `${formatPriceForContext(totalPanche)} ${getValute()}` : "")}
                    // images={getMainImagePanche()  || [PLACEHOLDER_IMAGE]}
                    onClick={() => {
                      goToPrevStep(() => props.onGoToStep(StepConfiguratore.Panche))
                    }}
                    descriptionItems={
                      store!.getState()!.panche?.panca_custom ?
                        store!.getState()!.panche?.panca_custom?.configurations.map((c, indexPan) => {
                          return (
                            <RiepilogoItem
                              key={c?.panca_custom?.id}
                              title={getTitlePortaCustom(c?.panca_custom)}
                              units={c?.quantity}
                              bold
                              separator={indexPan > 0}
                              className={`${indexPan === 0 && "mt-3"}`}
                              classNameMainItem={"py-3"}
                              priceUnit={formatPriceForContext(getPricePancaCustom(c) * (c?.quantity || 1))}
                              isMultipleUnits
                              onDelete={formIsSubmitted ? null : () => {
                                const indexPancaSelected = store!.getState()?.panche?.panca_custom?.configurations?.map(currP => currP.panca_custom?.id).indexOf(c.panca_custom?.id)
                                let _configurations = store!.getState()?.panche?.panca_custom?.configurations || []
                                if (indexPancaSelected !== -1) {
                                  if (_configurations.length > 1) {
                                    _configurations.splice(indexPancaSelected!, 1)
                                    dispatch(actPanche({
                                      panca_custom: {
                                        ...store!.getState()!.panche!.panca_custom!,
                                        configurations: _configurations
                                      }
                                    }))
                                  } else {
                                    dispatch(actPanche({
                                      panca_custom: undefined
                                    }))
                                  }
                                }

                              }} />
                          )
                        })
                        :
                        store!.getState()!.panche?.panche?.map((p, indexPan) => {
                          return (
                            <RiepilogoItem
                              key={p?.panca?.id}
                              title={p?.item_listino?.title?.rendered || ""}
                              isMultipleUnits
                              bold
                              separator={indexPan > 0}
                              className={`${indexPan === 0 && "mt-3"}`}
                              classNameMainItem={"py-3"}
                              units={p?.quantity}
                              priceUnit={formatPriceForContext(getPricePancaStd(p) * (p?.quantity || 1))}
                              onDelete={formIsSubmitted ? null : () => {
                                let indexSelected = store!.getState()?.panche?.panche?.map(currP => currP.panca?.id).indexOf(p.panca?.id)
                                let _panche = store!.getState()?.panche?.panche || []
                                if (indexSelected !== -1) {
                                  _panche.splice(indexSelected!, 1)
                                  dispatch(actPanche({
                                    panche: _panche
                                  }))
                                }
                              }} />
                          )
                        })
                    } />
                </div>
              }


              {
                (store!.getState()!.accessori?.accessori || []).length > 0 &&
                <div className="mt-2">
                  <RiepilogoGridItem
                    title={props.t("riepilogo.accessori")}
                    subtitle={totalAccessori ? `${formatPriceForContext(totalAccessori)} ${getValute()}` : ""}
                    // images={getMainImageAccessori() || [PLACEHOLDER_IMAGE]}
                    onClick={() => {
                      goToPrevStep(() => props.onGoToStep(StepConfiguratore.Accessori))
                    }}
                    descriptionItems={store!.getState()!.accessori!.accessori!.map((acc: TAccessorioQuantity, indexIt) => {
                      return (
                        <RiepilogoItem
                          key={acc?.accessorio?.id}
                          title={acc.accessorio?.title?.rendered || ""}
                          bold
                          units={acc.quantity}
                          isMultipleUnits={acc.quantity > 1 && !acc.accessorio?.acf?.item_relationship?.filter(i => i?.multipli_for_quantity_sku === true).length}
                          className={`py-3 ${indexIt === 0 && "mt-2"}`}
                          subItems={
                            (getTitleAccessorio(acc) || []).map((z, indexOptList) => {
                              console.log(">>>", z.slug)
                              return (
                                {
                                  title: `${z?.title?.rendered}${z.label_quantity ? ` (${acc.quantity} ${z.label_quantity})` : ""}`,
                                  price: formatPriceForContext(toPrice(z.acf) * (z.multipli_for_quantity_sku ? (acc.quantity / 100) : 1)),
                                  valute: getValute(),
                                  quantity: z.multipli_for_quantity_sku ? acc.quantity : 1,
                                  label_quantity: undefined//z.label_quantity
                                }
                              )
                            })
                          }
                          // priceUnit={formatPriceForContext((getPriceForAccessorio(acc) || 1) )}
                          isLoadingPrice={!listinoAccessori.has(acc.accessorio?.id)}
                          onDelete={formIsSubmitted ? null : () => {
                            const indexAccessorioSelected = store!.getState()?.accessori?.accessori?.map(a => a.accessorio.id).indexOf(acc.accessorio.id)
                            let _accessori = store!.getState()?.accessori?.accessori || []
                            if (indexAccessorioSelected !== -1) {
                              _accessori.splice(indexAccessorioSelected!, 1)
                              dispatch(actGeneratoreSetAccessori({
                                accessori: _accessori
                              }))
                            }
                          }} />
                      )
                    })} />
                </div>
              }


            </Col>
          </Row>

          <Modal
            title={modalData.title}
            onClose={() => closeModal()}
            visible={modalIsOpen}
            size={modalData.size}
            closeInline
            contentClassName={modalData.fullScreen ? "pdf-viewer" : ""}
            fullscreen={modalData.fullScreen}
          >
            {modalData.content && modalData.content()}
          </Modal>
        </div>
      </Col>
      <Col lg={6} className="pb-48">
        {props?.imgWizard()}
        <div>
          <H3
            title={props.t("riepilogo.info_tecniche.titolo")}
            className="text-left text-black-effe m-title mt-12 px-0 mx-0"
          />

          <div className="mt-8">
            {
              infoLabels.map((label, indexLabel) => {
                return (
                  <RiepilogoSimpleGridItem
                    title={label}
                    description={values[indexLabel]}
                    onClick={() => {
                      goToPrevStep(() => infoOnClicks[indexLabel]())
                    }}
                    className={`py-4 ${indexLabel < infoLabels.length - 1 && "border-b-half border-solid border-divider"}`}
                  />
                )
              })
            }
          </div>

          <div id="form-costi" className="bg-modal-effe">
            <div className="p-12">
              <div className="mb-12">
                <p className="mb-0 text-center uppercase text-fluo-effe font-bold text-titlePrice">{props.t("v3.riepilogo_costi")}</p>
                <hr className="h-px w-1/5 mx-auto bg-fluo-effe border-0" />
              </div>

              <div>
                {
                  getLocalization() !== Localization.AMERICA &&
                  <Row className="py-2 border-b border-fluo-effe border-dotted">
                    <Col xs={8} className="px-0 mx-0">
                      <p className="mb-0 font-light text-left text-fluo-effe text-text">{props.t("riepilogo.tot_prezzo_listino")}</p>
                    </Col>
                    <Col xs={4} className="px-0 mx-0">
                      <p className={`mb-0 font-regular text-right text-fluo-effe text-text ${(store.getState()?.riepilogo?.perc_sconto) && "line-through"}`}>{`${formatPriceForContext(getTotal())} ${getValute()}`}</p>
                    </Col>
                  </Row>
                }
                {
                  (getLocalization() !== Localization.AMERICA && (!formIsSubmitted || (formIsSubmitted && store.getState()?.riepilogo?.perc_sconto))) &&
                  <Row className="py-2 border-b border-fluo-effe border-solid">
                    <Col xs={8} className="px-0 mx-0">
                      <p className="mb-0 font-light text-left text-fluo-effe text-text">{props.t("riepilogo.perc_sconto")}</p>
                    </Col>
                    <Col xs={4} className=" px-0 mx-0 text-right">
                      {
                        !formIsSubmitted ?
                          <Form.Item name="discount" className="flex text-right items-center discount-input">
                            <InputNumber
                              disabled={formIsSubmitted}
                              max={MAX_PERC_DISCOUNT}
                              min={0}
                              parser={(value) => parseDimension(value)}
                              value={store.getState()?.riepilogo?.perc_sconto || undefined}
                              onKeyUp={(v: any) => {
                                if (Number(v?.target?.value) > MAX_PERC_DISCOUNT) {
                                  message.warning(props.t("v2.perc_sconto_alta"));
                                  dispatch(actGeneratoreSetRiepilogo({
                                    perc_sconto: undefined
                                  }))
                                }
                              }}
                              onChange={(num: number) => {
                                dispatch(actGeneratoreSetRiepilogo({
                                  perc_sconto: num <= MAX_PERC_DISCOUNT ? num : undefined
                                }))
                              }}
                              style={{ maxWidth: 60, height: "100%", textAlign: "right" }}
                            />
                            <span className="ml-1 mb-0 font-regular text-right text-fluo-effe text-text">%</span>
                          </Form.Item>
                          :
                          <p className="mb-0 font-light text-right text-fluo-effe text-text">{`${store.getState()?.riepilogo?.perc_sconto} %`}</p>

                      }
                    </Col>
                  </Row>
                }
                {
                  //isLoadingPrices
                }
                <Row className="py-2">
                  <Col xs={8} className="px-0 mx-0">
                    <p className="mb-0 capitalize font-bold text-left text-fluo-effe text-text">{getLocalization() !== Localization.AMERICA ? `${capitalize(props.t("riepilogo.prezzo_finale"))}` : "Total suggested retail price"}</p>
                    <p className="mb-0 font-light text-left text-fluo-effe text-textpopup">{`(*${props.t("v2.prezzo_suggerito_compila_form")})`}</p>
                  </Col>
                  <Col xs={4} className="px-0 mx-0">
                    <p className="mb-0 font-bold text-right text-fluo-effe text-text">{`${formatPriceForContext(getTotalDiscount())} ${getValute()}`}</p>
                  </Col>
                </Row>

              </div>

              {
                !formIsSubmitted &&
                <div className="my-12">
                  <p className="mb-0 text-center uppercase text-fluo-effe font-bold text-titlePrice">{props.t("v2.info_personal")}</p>
                  <hr className="h-px w-1/5 mx-auto bg-fluo-effe border-0" />
                </div>
              }

              <div>
                <HubspotForm region="na1"
                  formId={formId}
                  portalId='2300511'
                  onReady={() => {
                    console.log("[HUBSPOT] onReady")
                    try {
                      const script = document.createElement('script');
                      script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
                      let jqueryScript = document.getElementsByTagName('script');
                      let src = Array.from(jqueryScript).filter(item => item.src === script.src)
                      if (src.length === 0) {
                        document.body.appendChild(script)
                      }
                    } catch (error) {
                      console.log(error)
                    }


                    //@ts-ignore
                    const form = document.forms[`hsForm_${formId}`]
                    if (form) {
                      setInputValue(form, 'lingua', getLanguage())
                      const _email = getAuthInfo()?.email
                      if (_email) {
                        setInputValue(form, 'email_contact_owner', _email)
                      }
                    }
                  }}
                  onSubmit={($form: any) => {
                    //@ts-ignore
                    const form = document.forms[`hsForm_${formId}`]
                    const final_url_quote = getUrlQuote(getIdQuote(getInputValue(form, "0-2/name"), getInputValue(form, "firstname"),
                      getInputValue(form, "lastname"), getInputValue(form, "riferimento_nome_del_progetto")))
                    console.log(`HS onFormSubmit ${formId}`)
                    if (form) {
                      setInputValue(form, 'configurator___deal_name', `${store?.getState()?.generatore_product?.generatore_model?.title?.rendered || `${store?.getState()?.porta_vetrata?.modello_porta?.title?.rendered}${store?.getState()?.porta_vetrata?.modello_vetrata?.title?.rendered ? `, ${store?.getState()?.porta_vetrata?.modello_vetrata?.title?.rendered}` : ""}`} | ${getInputValue(form, "0-2/name") ? getInputValue(form, "0-2/name") : `${getInputValue(form, "lastname")} ${getInputValue(form, "firstname")}`}  | ${getInputValue(form, "riferimento_nome_del_progetto")}`)
                      setInputValue(form, 'amount___configuratore', `${getTotalDiscount()}`)
                      const info_tecniche = `<ul>${infoLabels?.map((info, index) => {
                        return (`<li><strong>${info}: </strong><span>${values?.[index]}</span></li>`)
                      }).join("")}</ul>`
                      setInputValue(form, 'info_tecniche', info_tecniche)
                      setInputValue(form, 'configurazione_hammam', final_url_quote)
                    }
                  }}
                  key={"formhs"}
                />
              </div>

              <div className="flex justify-center items-center">
                <button className={`flex flex-col items-center mr-4 ${!formIsSubmitted && "hover:cursor-not-allowed"}`}
                  disabled={!formIsSubmitted} onClick={() => {
                    if (!isLoadingPrices) {
                      if (formIsSubmitted) {
                        writeDataToClipboard(urlQuote!)
                      } else {
                        // document.getElementById("riepilogo-view")?.scrollTo({
                        //   top: 0,
                        //   behavior: 'smooth',
                        // })
                      }
                    } else {
                      notification["warning"]({
                        message: "Just a moment..."
                      })
                    }
                  }}>
                  <IconCopy fill={formIsSubmitted ? tailwindMyConfig.theme.extend.colors["fluo-effe"] : tailwindMyConfig.theme.extend.colors["subtitle-effe"]} />
                  <span className={`mt-4 font-bold ${formIsSubmitted ? "text-fluo-effe" : "text-subtitle-effe"} text-titleSectionGenerator`}>{props.t("v2.ottieni_link")}</span>
                </button>
                <button className="flex  flex-col items-center mx-4" onClick={() => {
                  openModal(props.t("v3.ricomincia"),
                    () => {
                      return (<div className="flex flex-col items-center">
                        <p className="text-left">
                          {props.t("v3.ricomincia.descrizione")}
                        </p>
                        <ActionButton
                          importance={IActionButtonType.Secondary}
                          title={props.t("v3.ricomincia.ok")} onClick={() => {
                            window.location.reload()
                            closeModal()
                          }} className="mt-4" />
                      </div>)
                    }, ModalSize.lg, false)
                }}>
                  <IconRestart fill={tailwindMyConfig.theme.extend.colors["fluo-effe"]} />
                  <span className="mt-4 font-bold text-fluo-effe text-titleSectionGenerator">{props.t("v3.ricomincia")}</span>
                </button>
                <button className={`flex flex-col items-center ml-4 ${!formIsSubmitted && "hover:cursor-not-allowed"}`}
                  disabled={!formIsSubmitted}
                  onClick={async () => {
                    if (!isLoadingPrices) {
                      if (formIsSubmitted) {
                        window.open(`/download-pdf/${idQuote}`)
                      }
                    }
                  }}>
                  <IconDownload fill={formIsSubmitted ? tailwindMyConfig.theme.extend.colors["fluo-effe"] : tailwindMyConfig.theme.extend.colors["subtitle-effe"]} />
                  <span className={`mt-4 font-bold ${formIsSubmitted ? "text-fluo-effe" : "text-subtitle-effe"} text-titleSectionGenerator`}>{props.t("v5.download_pdf")}</span>
                </button>
              </div>
            </div>
          </div>

          {
            getLocalization() === Localization.AMERICA &&
            <div className="mt-16 pb-8 text-right">
              <hr className="bg-fluo-effe mb-12" />
              <p className="mb-0 font-bold">Perfect Wellness Group Inc.</p>
              <p className="mb-0 font-thin">225 Broadway, 44th floor</p>
              <p className="mb-0 font-thin">10007 New York, NY</p>
              <p className="mb-0 font-thin">O : +1 (917) 671.0300</p>
            </div>
          }
        </div>
      </Col>
    </Row>);
}

export default connect((state: TGeneral, ownProps) => ({
  scelta_configurazione: state.scelta_configurazione,
  optional: state.optional,
  porta_vetrata: state.porta_vetrata,
  accessori: state.accessori,
  panche: state.panche,
  riepilogo: state.riepilogo,
  general: state.general
}), { actGeneratoreSetRiepilogo, actResetStore, actGeneratoreSetOptionals, actResetPorteVetrateStandard, actResetPorteVetrateCustom, actGeneratoreSetAccessori, actPanche })(
  withTranslation()(Riepilogo)
);
