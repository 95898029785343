import { Form } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3, Text } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import { TGeneral } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { actGeneratoreSetData, actGeneratoreSetModel } from "../../../../redux/actions";
import AsyncSelect from "../../../../components/AsyncSelect";
import TextCell from "../../../../components/AsyncSelect/TextCell";
import { getLabelEGeneratoreType, TGeneratoreStoreState, EGeneratoreType } from "../../../../redux/reducers/generatore";
import Modal, { ModalSize } from "../../../../components/Modal";
import { useRef, useState } from "react";
import img_differenza_int from "./../../../../assets/images/diff_int.png";
import img_differenza_est from "./../../../../assets/images/diff_ext.png";
import img_differenza_int_defr from "./../../../../assets/images/gen-int-defr.png";
import img_differenza_est_defr from "./../../../../assets/images/gen-ext-defr.png";
import img_differenza_int_es from "./../../../../assets/images/gen-int-es.png";
import img_differenza_est_es from "./../../../../assets/images/gen-ext-es.png";

import Subtitle from "../../../../components/Typography/Subtitle";
import Image from "../../../../components/Image";
import { StepConfiguratore, StepErrorField } from "../..";
import MsgError from "../../../../components/MsgError";
import { getCrmLanguage, getLanguage } from "../../../../utils/utils";
import { CrmLanguage } from "../../../../utils/storage";

function Generatore({ ...props }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const store = useStore();
  const generatore: TGeneratoreStoreState = useSelector(
    (state: TGeneral) => state.generatore
  );

  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [stepModal, setStepModal] = useState<number>(0);
  const stepModalRef = useRef(stepModal);

  const openModal = (title: string, content: any, size: any) => {
    setModalData({ title, content, size });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
  };

  return (
    <Form form={form} name="basic" layout={"vertical"} autoComplete={"off"}>
      <H3
        title={props.t("generatore.titolo")}
        className="text-left text-black-effe m-title mb-0"
      />
      <button 
        className="link"
        onClick={()=>openModal("",
          ()=>{
              const getImgInt = () => {
                switch (getCrmLanguage()) {
                  case CrmLanguage.DE:
                  case CrmLanguage.FR:
                    return img_differenza_int_defr
                  case CrmLanguage.ES:
                    return img_differenza_int_es
                  default:
                    return img_differenza_int
                }
              }
              const getImgExt = () => {
                switch (getCrmLanguage()) {
                  case CrmLanguage.DE:
                  case CrmLanguage.FR:
                    return img_differenza_est_defr
                  case CrmLanguage.ES:
                    return img_differenza_est_es
                  default:
                    return img_differenza_est
                }
              }
              return(
                <div className="h-full relative flex xs:flex-col lg:flex-row">
                  <div  className="lg:w-1/4 relative  xs:hidden lg:block">
                    <span style={{fontSize:24}} className="uppercase absolute top-10 left-0 font-bold text-fluo-effe">{props.t(stepModalRef.current===0 ? "generatore.interno_ext" : "generatore.esterno_ext")}</span>
                  </div>
                  <div  className="xs:w-full lg:w-1/2">
                    <Image img={stepModalRef.current===0 ? getImgInt() : getImgExt()} classNameImage="w-full h-full object-contain px-8" imgStyle={{maxWidth:770,maxHeight:700}}/>
                  </div>
                  <div className="lg:w-1/4  relative flex xs:w-full xs:justify-end">
                    <button style={{fontSize:24}} className="xs:relative lg:absolute xs:bottom-0 xs:mt-8 lg:mt-0 lg:bottom-10 right-0 font-light text-fluo-effe" onClick={()=>{
                      setStepModal(_stepModal => {
                        stepModalRef.current = _stepModal===0 ? 1 : 0
                        return _stepModal===0 ? 1 : 0
                      })
                    }}>
                      <span className={`labelModalIntExt uppercase text-left px-2 xs:hidden lg:block ${stepModal===1?"interno":"esterno"}`}>{props.t(stepModalRef.current===1 ? "generatore.interno_ext" : "generatore.esterno_ext")}</span>
                      <svg style={{width:"80%", float:"right"}} xmlns="http://www.w3.org/2000/svg" width="142.104" height="13.9" viewBox="0 0 142.104 13.9">
                        <path id="Tracciato_1363" data-name="Tracciato 1363" d="M2902.368-1405.912h134.8" transform="translate(-2902.368 1413.265)" fill="none" stroke="#00ffba" strokeWidth="1"/>
                        <path id="Tracciato_1364" data-name="Tracciato 1364" d="M3047.93-1398.929l6.6-6.6-6.6-6.6" transform="translate(-2913.129 1412.475)" fill="none" stroke="#00ffba" strokeWidth="1"/>
                      </svg>
                    </button>
                  </div>
              </div>)
          },ModalSize.xl)}>
          <Subtitle title={props.t('generatore.modal.title')}/>
      </button>
      <Row>
        <Col md={12} lg={12}>
          <Form.Item name="tipologia" className={`${store.getState()?.general?.stepError?.fields?.find((s:StepErrorField) => s.id==="tipologia")?.id && "error-field"}`}>
            <AsyncSelect
              currValue={generatore?.tipologia ? {
                name: props.t(getLabelEGeneratoreType(generatore?.tipologia as EGeneratoreType)),
                id: generatore?.tipologia,
              } : null}
              placeholder={props.t("general.scegli_opzione")}
              onChange={(val: any) => {
                dispatch(
                    actGeneratoreSetData({ tipologia: val.id })
                );
                //Reset modello scelto
                if(generatore?.tipologia !== val.id)
                  dispatch(
                    actGeneratoreSetModel({ 
                      generatore_product: undefined,
                      generatore_model: undefined
                    })
              );
              }}
              onStyleLabel={(item: any) => {
                return <TextCell title={item.name} />;
              }}
              loadOptions={async (
                searchQuery: any,
                loadedOptions: any,
                { page }: any
              ) => {
                const response: string[] = Object.values(EGeneratoreType)
                return {
                  options: response.map((val: string) => {
                    return { id: val, name: props.t(getLabelEGeneratoreType(val as EGeneratoreType)) };
                  }),
                  hasMore: false,
                  additional: {},
                };
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {
        store.getState()?.general?.stepError?.step===StepConfiguratore.Generatore &&
        <Row>
          <Col className="mt-8">
            <MsgError message={store.getState()?.general?.stepError?.msg}/>
          </Col>
        </Row>
      }

      <Modal
        title={modalData.title}
        onClose={() => closeModal()}
        visible={modalIsOpen}
        size={modalData.size}
        fullscreen
        contentClassName={`modal-gray`}
      >
        {modalData.content && modalData.content()}
      </Modal>
    </Form>
  );
}

export default connect((state:TGeneral, ownProps) => ({
  general: state.general
}), { actGeneratoreSetData, actGeneratoreSetModel })(
  withTranslation()(Generatore)
);
