import { Form, InputNumber } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3, Text } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import {
  TGeneral, TGeneralStore
} from "../../../../redux/reducers";
import {
    TVanoDocciaStoreState,
  } from "../../../../redux/reducers/vano_doccia";
import { useDispatch, useSelector } from "react-redux";
import { getLocalization, getSecondaryUnitOfMeasure, getUnitOfMeasure, Localization, parseDimension } from "../../../../utils/utils";
import { actVanoDocciaSetData, actGeneratoreSetModel, actGeneratoreSetData, actGeneral } from "../../../../redux/actions";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "../../../../components/Modal";
import { TGeneralStoreState } from "../../../../redux/reducers/general";
import MsgError from "../../../../components/MsgError";
import { StepConfiguratore } from "../..";

function VanoDoccia({ ...props }) {
  const dispatch = useDispatch();
  const store: TGeneralStore = useStore();
  const [form] = Form.useForm();
  const vano_doccia: TVanoDocciaStoreState = useSelector((state: TGeneral) => state.vano_doccia);
  const general: TGeneralStoreState = useSelector((state: TGeneral) => state.general);
  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});

  useEffect(() => {
    form.setFieldsValue(vano_doccia)
    dispatch(actGeneratoreSetModel({
        generatore_product: undefined,
        generatore_model: undefined
    }))
  }, [vano_doccia]);

  const openModal = (title: string, content: any, size: any) => {
    setModalData({ title, content, size });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
  };

  return (
    <Form
      form={form}
      name="basic"
      layout={"vertical"}
      autoComplete={"off"}
    >
      <H3
        title={props.t("vanodoccia.titolo")}
        className="text-left text-black-effe m-title"
      />
      {
      getLocalization() === Localization.AMERICA ? 
      <Row className="justify-between">        
        <Col md={12} lg={12} className="">
          <p className="form-label">{props.t("vanodoccia.form.larghezza")}</p>
          <div  className="flex justify-between">
            <Form.Item
              name="larghezza"
              className={`pr-2 ${store.getState()?.general?.stepError?.fields?.find(s => s.id==="larghezza")?.id && "error-field"}`}
            >
              <div className="flex items-center container-input-child">
              <InputNumber
                  min={0}
                  parser={(value) => parseDimension(value)}
                  value={vano_doccia.larghezza}
                  onChange={(num:number) => {
                    dispatch(actVanoDocciaSetData({
                      larghezza: num
                    }));
                  }}
              />
                <span className="unit">
                  {getUnitOfMeasure()}
                </span>
              </div>
            </Form.Item>
            <Form.Item
              name="larghezza"
              className={`pl-2 ${store.getState()?.general?.stepError?.fields?.find(s => s.id==="larghezza")?.id && "error-field"}`}
            >
              <div className="flex items-center container-input-child">
              <InputNumber
                  max={11}
                  min={0}
                  precision={0}
                  value={vano_doccia.larghezza_inc}
                  onChange={(num:number) => {
                    dispatch(actVanoDocciaSetData({
                      larghezza_inc: num
                    }));
                  }}
              />
                <span className="unit">
                  {getSecondaryUnitOfMeasure()}
                </span>
              </div>
            </Form.Item>
          </div>
        </Col>


        <Col md={12} lg={12} className="relative">
          <p className="form-label">{props.t("vanodoccia.form.profondita")}</p>
          <div  className="flex justify-between">
            <Form.Item
              name="profondita"
              className={`pr-2 ${store.getState()?.general?.stepError?.fields?.find(s => s.id==="profondita")?.id && "error-field"}`}
            >
              <div className="flex items-center container-input-child">
                <InputNumber
                    min={0}
                    parser={(value) => parseDimension(value)}
                    value={vano_doccia.profondita}
                    onChange={(num:number) => {
                      dispatch(actVanoDocciaSetData({
                        profondita: num
                      }));
                    }}
                />
                <span className="unit">
                  {getUnitOfMeasure()}
                </span>
              </div>
              
            </Form.Item>
            <Form.Item
              name="profondita"
              className={`pl-2 ${store.getState()?.general?.stepError?.fields?.find(s => s.id==="profondita")?.id && "error-field"}`}
            >
              <div className="flex items-center container-input-child">
                <InputNumber
                    max={11}
                    min={0}
                    precision={0}
                    value={vano_doccia.profondita_inc}
                    onChange={(num:number) => {
                      dispatch(actVanoDocciaSetData({
                        profondita_inc: num
                      }));
                    }}
                />
                <span className="unit">
                  {getSecondaryUnitOfMeasure()}
                </span>
              </div>
            </Form.Item>
          </div>
        </Col>


        <Col md={12} lg={12} className="relative">
          <p className="form-label">{props.t("vanodoccia.form.altezza")}</p>
          <div  className="flex justify-between">
            <Form.Item
              name="altezza"
              className={`pr-2 ${store.getState()?.general?.stepError?.fields?.find(s => s.id==="altezza")?.id && "error-field"}`}
            >
              <div className="flex items-center container-input-child">
                  <InputNumber
                      min={0}
                      parser={(value) => parseDimension(value)}
                      value={vano_doccia.altezza}
                      onChange={(num:number) => {
                        dispatch(actVanoDocciaSetData({
                          altezza: num
                        }));
                      }}
                  />
                <span className="unit">
                  {getUnitOfMeasure()}
                </span>
              </div>
              
            </Form.Item>
            <Form.Item
              name="altezza"
              className={`pl-2 ${store.getState()?.general?.stepError?.fields?.find(s => s.id==="altezza")?.id && "error-field"}`}
            >
              <div className="flex items-center container-input-child">
                <InputNumber
                      max={11}
                      min={0}
                      precision={0}
                      value={vano_doccia.altezza_inc}
                      onChange={(num:number) => {
                        dispatch(actVanoDocciaSetData({
                          altezza_inc: num
                        }));
                      }}
                  />
                <span className="unit">
                  {getSecondaryUnitOfMeasure()}
                </span>
              </div>
              
            </Form.Item>
          </div>
        </Col>

      </Row>
       
       :

       <Row className="justify-between">   
          <Col xs={6} sm={6} md={6} lg={6} className="relative">
            <div className="flex items-center container-input">
              <Form.Item
                  name="larghezza"
                  className={`${store.getState()?.general?.stepError?.fields?.find(s => s.id==="larghezza")?.id && "error-field"}`}
                >
                  <InputNumber
                      min={0}
                      parser={(value) => parseDimension(value)}
                      value={vano_doccia.larghezza}
                      placeholder={props.t("vanodoccia.form.larghezza")}
                      onChange={(num:number) => {
                        dispatch(actVanoDocciaSetData({
                          larghezza: num
                        }));
                      }}
                  />
                </Form.Item>
                <span className="unit">
                  {getUnitOfMeasure()}
                </span>
            </div>
          </Col>

          <Col xs={6} sm={6} md={6} lg={6} className="relative">
            <div className="flex items-center container-input">
              <Form.Item
                name="profondita"
                className={`${store.getState()?.general?.stepError?.fields?.find(s => s.id==="profondita")?.id && "error-field"}`}
              >
                <InputNumber
                    min={0}
                    parser={(value) => parseDimension(value)}
                    value={vano_doccia.profondita}
                    placeholder={props.t("vanodoccia.form.profondita")}
                    onChange={(num:number) => {
                      dispatch(actVanoDocciaSetData({
                        profondita: num
                      }));
                    }}
                />
              </Form.Item>
              <span className="unit">
                {getUnitOfMeasure()}
              </span>
            </div>
          </Col>

          <Col xs={6} sm={6} md={6} lg={6} className="relative">
            <div className="flex items-center">
              <Form.Item
                name="altezza"
                className={`${store.getState()?.general?.stepError?.fields?.find(s => s.id==="altezza")?.id && "error-field"}`}
              >
                <InputNumber
                    min={0}
                    placeholder={props.t("vanodoccia.form.altezza")}
                    parser={(value) => parseDimension(value)}
                    value={vano_doccia.altezza}
                    onChange={(num:number) => {
                      dispatch(actVanoDocciaSetData({
                        altezza: num
                      }));
                    }}
                />
              </Form.Item>
              <span className="unit">
                {getUnitOfMeasure()}
              </span>
            </div>
          </Col>
       </Row> 

      }

      {/* <Row className="justify-center my-2">
          <button onClick={()=>openModal(props.t("vanodoccia.come_misurare"),()=>{
              return <div className="text-center">{props.t("vanodoccia.come_misurare.content")}</div>
          },null)}>
          <Text title={props.t('vanodoccia.come_misurare')} className="underline text-xl text-center text-black-effe font-light"/>
          </button>
      </Row> */}
      {
        store.getState()?.general?.stepError?.step===StepConfiguratore.VanoDoccia &&
        <Row>
          <Col className="mt-8">
            <MsgError message={store.getState()?.general?.stepError?.msg}/>
          </Col>
        </Row>
      }

      <Modal
        title={modalData.title}
        onClose={() => closeModal()}
        visible={modalIsOpen}
        size={modalData.size}
      >
        {modalData.content && modalData.content()}
      </Modal>
    </Form>
  );
}

export default connect((state:TGeneral, ownProps) => ({
  general: state.general,
  vano_doccia: state.vano_doccia,
  generatore_product: state.generatore_product
}), { actVanoDocciaSetData, actGeneratoreSetModel, actGeneral })(
  withTranslation()(VanoDoccia)
);
