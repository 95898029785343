import axios, { AxiosInstance } from "axios";
export const BASE_WP_URL = "https://www.effe.it"
export const API_URL = `${BASE_WP_URL}/wp-json/wp`;

export const BASE_DMA_URL = "https://9wpy6jtirh.execute-api.eu-central-1.amazonaws.com"
export const API_DMA_URL = `${BASE_DMA_URL}/prod/api`;

const isTokenExpired = (error: any) => {
  return error.response?.status == 401;
};

const refreshToken = async (): Promise<any> => {
//   const session = await Auth.currentSession();
//   const new_access_token = session.getAccessToken().getJwtToken();
//   setAccessToken(new_access_token);
  return ""//new_access_token;
};


const NetworkClient = (dma_api: boolean = false): AxiosInstance => {
  const getBaseURL = () => !dma_api ? `${API_URL}/v2` : `${API_DMA_URL}/v1/`;
  const getBearerToken = (token: string | null) => `Bearer ${token}`;
  // @ts-ignore
  const _networkClient: any = axios.create({ baseURL: getBaseURL(), "Content-Type": "application/json"});

  const formatResponse = (resp:any) => {
    return resp
  }

  _networkClient.interceptors.request.use(
    (request: any) => {
    //   request.headers.Authorization = getBearerToken(getAccessToken());
      return request;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  _networkClient.interceptors.response.use(
    (response: any) => {
      return formatResponse(response)
    },
    async (error: any) => {
      console.log("isTokenExpired", isTokenExpired(error));
      if (isTokenExpired(error)) {
        // try {
        //   const new_access_token = await refreshToken();
        //   const newHeader = {
        //     'Content-Type': 'application/json',
        //     'Authorization': getBearerToken(
        //       new_access_token
        //     )
        //   }
        //   error.config.headers = newHeader;
        //   error.config.baseURL = getBaseURL();
        // } catch (errRefresh) {
        //   return logoutAndGoHome(errRefresh);
        // }
      } else return Promise.reject(error);
    }
  );
  return _networkClient;
};

const logoutAndGoHome = (err: any) => {
  console.log("Refresh Token Scaduta -> Force Logout", err);
  window.location.href = "/";
  return Promise.reject(err);
};

export default NetworkClient;
