import { FC } from "react";
import { withTranslation } from "react-i18next";
import { PLACEHOLDER_IMAGE } from "../../../utils/consts";
import { isMobile } from "../../../utils/utils";
import ActionButton, { IActionButtonType } from "../../Buttons/ActionButton";
import { Text } from "../../Typography";

interface IPlusGridItem extends React.HtmlHTMLAttributes<HTMLElement> {
  title: string;
  descrizione?: string;
  descrizioneHtml?: string;
  dimensioni: string;
  img?: string;
  quantity: number;
  overlay?: boolean;
  onPlus?: () => void;
  onMinus?: () => void;
  actionTitle?: string;
  actionOnClick?: any;
  t?: any;
}

const PlusGridItem: FC<IPlusGridItem> = ({ title, descrizione, descrizioneHtml, dimensioni, img, quantity, onPlus, onMinus, overlay, t,
  actionTitle, actionOnClick, ...props }: IPlusGridItem) => {
  const HEIGHT_CELL = !isMobile() ? 190 : 250
  return (
    <div className={`${props.className}`}>   
      <Text title={title} className={`text-black-effe text-left font-regular text-titleCard mb-0 min-h-55`}/> 
      <Text title={`${dimensioni ? `(${dimensioni})` : ""}`} className={`text-black-effe text-left font-regular text-textpopup mb-0`}/> 
      <div style={{height:HEIGHT_CELL, overflow:"hidden"}} className={`gridItemImage justify-between items-center`}>
          <img src={img || PLACEHOLDER_IMAGE} style={isMobile() ? {} : {height:HEIGHT_CELL, objectFit:"cover"}} className=""/>
      </div>
      <div style={{maxWidth:125}} className="mx-auto">
        <ActionButton isVisibleQuantity quantity={quantity}
            onPlus={()=>{
                onPlus && onPlus()
            }}
            onMinus={()=>{
                (onMinus && quantity>0) && onMinus()
            }}
            disableHover
            className="px-3 mx-0 pb-1 w-full"
            importance={IActionButtonType.Secondary} title={t("general.aggiungi")}/>
      </div>
    </div>
  );
};
export default withTranslation()(PlusGridItem);
