import { getCategoryIdPorta, getLanguage, getCategoryIdOptional, getCategoryIdAccessori, getCategoryIdPanche } from "../utils/utils";

export const endpoint = {
    generatori_interni: (start: number, limit: number) => `products?lang=${getLanguage()}&_embed&categorie_prodotti=30&offset=${start}&per_page=${limit}`,
    generatori_esterni: (start: number, limit: number) => `products?lang=${getLanguage()}&_embed&categorie_prodotti=31&offset=${start}&per_page=${limit}`,
    kit_coibentazione: (slug:string) => `optional?lang=${getLanguage()}&slug=${slug}`,
    modelli_di_prodotto: (id_prodotto: string) => `products/${id_prodotto}?lang=${getLanguage()}`,
    modello_di_prodotto: (id_modello: string) => `models/${id_modello}?lang=${getLanguage()}`,
    optionals: (start: number, limit: number) => `optional?categoria_optional=${getCategoryIdOptional()}&lang=${getLanguage()}&_embed&offset=${start}&per_page=${limit}`,
    optional: (id_optional: string) => `optional/${id_optional}?lang=${getLanguage()}&_embed`,
    accessori: (start: number, limit: number) => `optional?categoria_optional=${getCategoryIdAccessori()}&lang=${getLanguage()}&_embed&offset=${start}&per_page=${limit}`,
    panche: (start: number, limit: number) => `optional?categoria_optional=${getCategoryIdPanche()}&lang=${getLanguage()}&_embed&offset=${start}&per_page=${limit}`,
    prodotti_porte_standard: (start: number, limit: number) => `products-porte-vetrate?categoria_prodotto_pv=${getCategoryIdPorta(true)}&lang=${getLanguage()}&_embed&offset=${start}&per_page=${limit}`,
    prodotti_porte_custom: (start: number, limit: number) => `products-porte-vetrate?categoria_prodotto_pv=${getCategoryIdPorta(false)}&lang=${getLanguage()}&_embed&offset=${start}&per_page=${limit}`,
    porta: (id_porta: string) => `products-porte-vetrate/${id_porta}?lang=${getLanguage()}`,
    model_porta: (id_product_porta: string) => `models-porte-vetrate/${id_product_porta}?lang=${getLanguage()}&_embed`,
    item: (id_item: string) => `item/${id_item}?lang=${getLanguage()}&_embed`,
    materiale: (id_materiale: string) => `materiale_prodotto/${id_materiale}?lang=${getLanguage()}&_embed`,
    quotes: (id?: string) => `quotes${id ? `/${id}` : ``}`
};
