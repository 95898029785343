import { ImgType } from "../../controller/Configuratore";
import Action, { ActionTypes } from "../actionTypes";
import { TListinoItemContentType } from "./porte_vetrate";
import { ConfigurationType } from "./scelta_configurazione";

export type TRiepilogoRequest = {
  id: string;
  data: TRiepilogoPayload;
}
export type TRiepilogoPayload = {
  uuid: string;
  link: string;
  data: TRiepilogoPayloadData;
  type_configuration: ConfigurationType | undefined;
  language: string;
  localization: string;
  price: number;
  price_discounted: number;
  percentual_discount: number;
  valute: string;
  timestamp: number;
  customer_info: TCustomerInfo | null;
  version: string;
  mainInternalImg: string;
  layerInternalImgs: ImgType[];
  mainExternalImg: string;
  layerExternalImgs: ImgType[];
}

export type TCustomerInfo = {
  firstname?: string;
  lastname?: string;
  company_name?: string;
  city: string;
  country: string;
  commenti_per_l_acquirente?: string;
  riferimento_nome_del_progetto: string;
}

export type TRiepilogoPayloadData = {
  generatore: {
    name: string;
    images: string[];
    subtotal: string;
    model_items: {
      name: string;
      price: string;
      units?: number;
    }[]
  },
  optionals: {
    subtotal: string;
    images: string[];
    optionals: {
      name: string,
      price: string,
      subitems: {
        name: string;
        price: string;
      }[]
    }[],
  },
  panche: {
    subtotal: string;
    images: string[];
    panche: {
      name: string,
      price: string,
      units?: number;
    }[],
  },
  porte_vetrate: {
    subtotal: string;
    images: string[];
    isCustom: boolean;
    numParetiVetrata: number;
    custom_extra_info: string[];
    model_items: {
      name: string,
      price: string
    }[];
    custom_items: { title: string; subItems: { title: string; price: string; valute: string; }[]; }[]
  }
  accessori: {
    subtotal: string;
    images: string[];
    accessori: {
      name: string,
      price: string,
      quantity?: number;
      subitems: {
        name: string;
        price: string;
        multipli_for_quantity_sku: boolean;
        label_quantity: string;
      }[]
    }[],
  },
  info_tecniche: {
    dimensione_box: string;
    volume: string;
    struttura: string;
    rivestimento: string;
    volt: string;
    kit_coibentazione: string;
    add_kit_coibentazione_to_quote: string;
  }
}

export enum EB2Type {
  B2B = "B2B",
  B2C = "B2C"
}

export type TFormRichiestaPreventivo = {
  nome?: string;
  cognome?: string;
  email?: string;
  telefono?: string;
  provincia?: string;
  indirizzo?: string;
}

export type TRiepilogoStoreState = {
  b2type?: EB2Type;
  formRichiestaPreventivo?: TFormRichiestaPreventivo;
  perc_sconto?: number;
}
const initialState: TRiepilogoStoreState = {
  b2type: undefined,
  formRichiestaPreventivo: undefined,
  perc_sconto: undefined
};


export default function (state = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneratoreSetRiepilogo: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
