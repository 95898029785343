import { Form } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect, useStore } from "react-redux";
import { TGeneral } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { actResetPorteVetrateCustom, actResetPorteVetrateStandard, actVetrateSetData } from "../../../../redux/actions";
import AsyncSelect from "../../../../components/AsyncSelect";
import TextCell from "../../../../components/AsyncSelect/TextCell";
import { TVetrateStoreState } from "../../../../redux/reducers/vetrate";
import { StepConfiguratore, StepErrorField } from "../..";
import MsgError from "../../../../components/MsgError";

function Vetrate({ ...props }) {
  const store = useStore();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const vetrate: TVetrateStoreState = useSelector(
    (state: TGeneral) => state.vetrate
  );

  return (
    <Form form={form} name="basic" layout={"vertical"} autoComplete={"off"}>
                  <H3
              title={props.t("vetrate.titolo")}
              className="text-left text-black-effe m-title "
            />
      <Row>
        <Col lg={12}>
          <Form.Item name="vetrata" className={`${store.getState()?.general?.stepError?.fields?.find((s:StepErrorField) => s.id==="vetrata")?.id && "error-field"}`}>
            <AsyncSelect
              currValue={vetrate?.quantita ? {
                name: vetrate?.quantita,
                id: vetrate?.quantita,
              } : null}
              placeholder={props.t("vetrate.seleziona")}
              onChange={(val: any) => {
                dispatch(
                    actVetrateSetData({ quantita: val.id })
                );
                dispatch(
                  actResetPorteVetrateCustom()
                );
                dispatch(
                  actResetPorteVetrateStandard()
                );
              }}
              onStyleLabel={(item: any) => {
                return <TextCell title={item.name} />;
              }}
              loadOptions={async (
                searchQuery: any,
                loadedOptions: any,
                { page }: any
              ) => {
                const response: number[] = [1,2]
                return {
                  options: response.map((val: number) => {
                    return { id: val, name: val };
                  }),
                  hasMore: false,
                  additional: {},
                };
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {
        store.getState()?.general?.stepError?.step===StepConfiguratore.Vetrate &&
        <Row>
          <Col className="mt-8">
            <MsgError message={store.getState()?.general?.stepError?.msg}/>
          </Col>
        </Row>
      }
    </Form>
  );
}

export default connect((state:TGeneral, ownProps) => ({
  general: state.general
}), { actVetrateSetData, actResetPorteVetrateCustom, actResetPorteVetrateStandard })(
  withTranslation()(Vetrate)
);
