import { combineReducers } from "redux";
import { ActionTypes } from "../actionTypes";
import accessori, { TAccessoriStoreState } from "./accessori";
import general, { TGeneralStoreState } from "./general";
import generatore, { TGeneratoreStoreState } from "./generatore";
import generatore_product, { TGeneratoreModelStoreState } from "./generatoreestint";
import optional, { TOptionalStoreState } from "./optional";
import panche, { TPancheStoreState } from "./panche";
import porta_vetrata, { TPorteVetrateCustomStoreState, TPorteVetrateStandardStoreState } from "./porte_vetrate";
import riepilogo, { TRiepilogoStoreState } from "./riepilogo";
import scelta_configurazione, { TSceltaConfigurazioneStoreState } from "./scelta_configurazione";
import struttura_vano_doccia, { TStrutturaVanoDocciaStoreState } from "./struttura_vano_doccia";
import vano_doccia, { TVanoDocciaStoreState } from "./vano_doccia";
import vetrate, { TVetrateStoreState } from "./vetrate";
import voltaggio, { TVoltaggioStoreState } from "./voltaggio";

export type TGeneralStore = {
    getState: () => TGeneral;
}
export type TGeneral = {
    general: TGeneralStoreState;
    scelta_configurazione: TSceltaConfigurazioneStoreState;
    vano_doccia: TVanoDocciaStoreState;
    struttura_vano_doccia: TStrutturaVanoDocciaStoreState;
    vetrate: TVetrateStoreState;
    generatore: TGeneratoreStoreState;
    generatore_product: TGeneratoreModelStoreState;
    optional: TOptionalStoreState;
    porta_vetrata: TPorteVetrateStandardStoreState & TPorteVetrateCustomStoreState;
    panche: TPancheStoreState;
    accessori: TAccessoriStoreState;
    voltaggio: TVoltaggioStoreState;
    riepilogo: TRiepilogoStoreState;
}

const appReducer = combineReducers({general, scelta_configurazione, vano_doccia, struttura_vano_doccia,vetrate,generatore,generatore_product,optional,porta_vetrata, panche, accessori, voltaggio, riepilogo})

  
const rootReducer = (state:any, action:any) => {
    if (action.type === ActionTypes.ActionResetStore) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

  
export default rootReducer;
