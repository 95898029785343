import { FC } from "react";
import { withTranslation } from "react-i18next";
import { useWindowSize, WIDTH_DESKTOP } from "../../controller/Configuratore";
import {
  getLocalization,
  isMobile,
  isTablet,
  Localization,
} from "../../utils/utils";
import FormButton from "../Buttons/FormButton";
import { IconFrecciaDownNext } from "../Icon";

interface IWizard extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  currentStepIndex: number;
  steps: Array<any>;
  onGoForward: (currentStepIndex: number) => void;
  onGoBack: (currentStepIndex: number) => void;
  hideFooter?: boolean;
  hideHeader?: boolean;
  imgVano?: any;
  t?: any;
  disabledGoForward?: boolean;
  wizardHeader: any;
}

export const MAX_WIDTH_BODY = 580;

const Wizard: FC<IWizard> = ({
  currentStepIndex,
  steps,
  onGoForward,
  onGoBack,
  hideHeader,
  hideFooter,
  imgVano,
  disabledGoForward,
  wizardHeader,
  ...props
}: IWizard) => {
  const [width, height] = useWindowSize();
  const isLastStep = currentStepIndex === steps.length - 1;
  return (
    <div
      className={`flex h-full flex-col ${
        isLastStep ? "" : "justify-between overflow-hidden"
      }`}
    >

      <div id="body-wizard"
        className={`flex flex-col h-full ${!isLastStep && "overflow-scroll"}`}
      >
        {wizardHeader()}

        <div
          className="lg:px-4 xs:px-2 w-full mx-auto"
          style={{ maxWidth: isLastStep ? "unset" : MAX_WIDTH_BODY, minWidth: (width/2)<MAX_WIDTH_BODY ? "unset" : MAX_WIDTH_BODY}}
        >
          {steps[currentStepIndex]}

          {(!isLastStep && imgVano) && imgVano()}

          {!hideFooter ? (
          <div className="flex w-full xs:pb-8 xs:pt-2 md:py-20 md:mb-20 xs:justify-evenly lg:justify-start">
            <div className="xs:mb-2 sm:mb-0 xs:px-0 lg:px-3">
              <FormButton
                title={props.t("wizard.btn_indietro")}
                isDisabled={currentStepIndex === 0}
                onClick={() =>
                  currentStepIndex !== 0 && onGoBack(currentStepIndex)
                }
              />
            </div>

            <div className="">
              <FormButton
                title={props.t("wizard.btn_prosegui")}
                isDisabled={disabledGoForward || isLastStep}
                onClick={() => onGoForward(currentStepIndex)}
              />
            </div>
          </div>
         ) : null} 
        </div>

      </div>
    </div>
  );
};

export default withTranslation()(Wizard);
