import { Spin } from "antd";
import { FC, useRef, useState } from "react";
//@ts-ignore
import ImageGallery, { Fullscreen } from "react-image-gallery";
import Lottie from 'react-lottie';
import ic_fullscreen from "./../../assets/lottie/full-screen.json"

interface IGallery {
  gallery: string[];
}

const Gallery: FC<IGallery> = ({ gallery, ...props }: IGallery) => {
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);
  const galleryRef:any = useRef();

  const icFullScreenRef:any = useRef();
  const defaultOptions = {
    loop: false,
    autoplay: false, 
    animationData: ic_fullscreen,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <ImageGallery
        ref={galleryRef}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        showThumbnails={false}
        items={gallery.map((img: string) => {
          return { original: img };
        })}
      />
      <div className="flex justify-between bg-fluo-effe w-full items-center px-4 py-2">
        <div>
          <svg
            className={`arrow-gallery arrow-gallery-back ${
              currentGalleryIndex > 0 ? "active" : "disabled"
            }`}
            onClick={() => {
              //@ts-ignore
              const currIndex = galleryRef?.current?.getCurrentIndex();
              //@ts-ignore
              currIndex > 0 && galleryRef?.current?.slideToIndex(currIndex - 1);
              setCurrentGalleryIndex(currIndex - 1);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="47.361"
            height="10.707"
            viewBox="0 0 47.361 10.707"
          >
            <g
              id="Raggruppa_810"
              data-name="Raggruppa 810"
              transform="translate(0.707 0.354)"
            >
              <path
                id="Tracciato_550"
                data-name="Tracciato 550"
                d="M6661.563,471H6621"
                transform="translate(-6614.909 -466)"
                fill="none"
                stroke="#3e3e3e"
                strokeWidth="1"
              />
              <path
                id="Tracciato_551"
                data-name="Tracciato 551"
                d="M6650.664,467.049l-5,5,5,5"
                transform="translate(-6645.664 -467.049)"
                fill="none"
                stroke="#3e3e3e"
                strokeWidth="1"
              />
            </g>
          </svg>
        </div>
        <div>

          <div             onClick={() => {
              //@ts-ignore
              galleryRef?.current?.fullScreen();
            }} onMouseEnter={() => {
              //@ts-ignore
              icFullScreenRef?.current?.play()
            }} onMouseLeave={() => {
              //@ts-ignore
              icFullScreenRef?.current?.stop()
            }}>
          <Lottie  ref={icFullScreenRef} options={defaultOptions} height={26} width={30}  
                  isStopped={true}
                  />

          </div>
        </div>
        <div>
          <svg
            className={`arrow-gallery arrow-gallery-forward ${
              currentGalleryIndex < gallery.length - 1 ? "active" : "disabled"
            }`}
            onClick={() => {
              //@ts-ignore
              const currIndex = galleryRef?.current?.getCurrentIndex();
              //@ts-ignore
              currIndex < gallery.length - 1 && galleryRef?.current?.slideToIndex(currIndex + 1);
              setCurrentGalleryIndex(currIndex + 1);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="47.361"
            height="10.707"
            viewBox="0 0 47.361 10.707"
          >
            <g
              id="Raggruppa_809"
              data-name="Raggruppa 809"
              transform="translate(-459.054 -490.706)"
            >
              <path
                id="Tracciato_550"
                data-name="Tracciato 550"
                d="M6621,471h40.563"
                transform="translate(-6161.946 25.06)"
                fill="none"
                stroke="#3e3e3e"
                strokeWidth="1"
              />
              <path
                id="Tracciato_551"
                data-name="Tracciato 551"
                d="M6645.664,467.049l5,5-5,5"
                transform="translate(-6144.956 24.011)"
                fill="none"
                stroke="#3e3e3e"
                strokeWidth="1"
              />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};
export default Gallery;
