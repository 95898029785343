import { ActionTypes } from "./actionTypes";
import { TAccessoriStoreState } from "./reducers/accessori";
import { TGeneralStoreState } from "./reducers/general";
import { TGeneratoreStoreState } from "./reducers/generatore";
import { TGeneratoreModelStoreState } from "./reducers/generatoreestint";
import { TOptionalStoreState } from "./reducers/optional";
import { TPancheStoreState } from "./reducers/panche";
import { TPorteVetrateCustomStoreState, TPorteVetrateStandardStoreState } from "./reducers/porte_vetrate";
import { TRiepilogoStoreState } from "./reducers/riepilogo";
import { TSceltaConfigurazioneStoreState } from "./reducers/scelta_configurazione";
import { TStrutturaVanoDocciaStoreState } from "./reducers/struttura_vano_doccia";
import { TVanoDocciaStoreState } from "./reducers/vano_doccia";
import { TVetrateStoreState } from "./reducers/vetrate";
import { TVoltaggioStoreState } from "./reducers/voltaggio";

//Reset
export const actResetStore = () => ({
  type: ActionTypes.ActionResetStore
});

//General
export const actGeneral = (data: TGeneralStoreState) => ({
  type: ActionTypes.ActionGeneral,
  payload: data
});

//Scelta Configurazione
export const actSceltaConfigurazione = (data: TSceltaConfigurazioneStoreState) => ({
  type: ActionTypes.ActionSceltaConfigurazioneSetData,
  payload: data
});

//Vano Doccia
export const actVanoDocciaSetData = (data: TVanoDocciaStoreState) => ({
  type: ActionTypes.ActionVanoDocciaSetData,
  payload: data
});

//Vano Doccia
export const actStrutturaVanoDocciaSetData = (data: TStrutturaVanoDocciaStoreState) => ({
  type: ActionTypes.ActionStrutturaVanoDocciaSetData,
  payload: data
});

//Vetrate
export const actVetrateSetData = (data: TVetrateStoreState) => ({
  type: ActionTypes.ActionVetrateSetData,
  payload: data
});

//Generatore
export const actGeneratoreSetData = (data: TGeneratoreStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetData,
  payload: data
});

//Generatore Model
export const actGeneratoreSetModel = (data: TGeneratoreModelStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetModel,
  payload: data
});

//Optionals
export const actGeneratoreSetOptionals = (data: TOptionalStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetOptionals,
  payload: data
});

//Porte Vetrate
export const actPorteVetrateStandard = (data: TPorteVetrateStandardStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetPortaVetrata,
  payload: data
});
export const actResetPorteVetrateStandard = () => ({
  type: ActionTypes.ActionGeneratoreSetPortaVetrata,
  payload: {
    porta_listino: undefined,
    porta_prodotto: undefined,
    modello_porta: undefined,
    modello_vetrata: undefined
  } as TPorteVetrateStandardStoreState
});
export const actPorteVetrateCustom = (data: TPorteVetrateCustomStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetPortaVetrataCustom,
  payload: data
});
export const actResetPorteVetrateCustom = () => ({
  type: ActionTypes.ActionGeneratoreSetPortaVetrataCustom,
  payload: {
    porta_prodotto: undefined,
    larghezza_porta: undefined,
    altezza_porta: undefined,
    larghezza_vetrata: undefined,
    altezza_vetrata: undefined,
    materiale: undefined,
    modello: undefined,
    versione: undefined
  } as TPorteVetrateCustomStoreState
});


//Panche
export const actPanche = (data: TPancheStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetPanca,
  payload: data
});

//Accessori
export const actGeneratoreSetAccessori = (data: TAccessoriStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetAccessori,
  payload: data
});

//Voltaggio
export const actGeneratoreSetVoltaggio = (data: TVoltaggioStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetVoltaggio,
  payload: data
});

//Riepilogo
export const actGeneratoreSetRiepilogo = (data: TRiepilogoStoreState) => ({
  type: ActionTypes.ActionGeneratoreSetRiepilogo,
  payload: data
});