import { AsyncPaginate } from "react-select-async-paginate";
import { FC, useState } from "react";
import customStyle from "./customStyle";
import customStyleSecondary from "./customStyleSecondary";
import { components, OptionProps, GroupTypeBase } from "react-select";
import { withTranslation } from "react-i18next";

const CustomOption = (props: any) => {
  return (
    <components.Option {...props} className={`${props.className||""} option`}>
      {
        props.onStyleLabel ? props.onStyleLabel(props.data)
        : <p>{props.label}</p> 
      }
    </components.Option>
  );
};
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props} className={`dropdownIndicator ${props?.isOpened ? "drop-open" : "drop-close"}`}>
      {
        props?.isSecondary ? 
        <svg xmlns="http://www.w3.org/2000/svg" width="9.192" height="5.303" viewBox="0 0 9.192 5.303">
          <g id="Raggruppa_996" data-name="Raggruppa 996" transform="translate(4.596 -3.889) rotate(45)">
            <path id="Tracciato_940" data-name="Tracciato 940" d="M0,0H6" transform="translate(0 6)" fill="none" stroke="#00ffba" strokeWidth="1"/>
            <path id="Tracciato_941" data-name="Tracciato 941" d="M0,0H6.5" transform="translate(6 0) rotate(90)" fill="none" stroke="#00ffba" strokeWidth="1"/>
          </g>
        </svg>
        :
        <svg id="Raggruppa_800" data-name="Raggruppa 800" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <path id="Tracciato_940" data-name="Tracciato 940" d="M4779.788,439h12" transform="translate(-4779.788 -433)" fill="none" stroke={"#707070"} strokeWidth="1"/>
          <path id="Tracciato_941" data-name="Tracciato 941" d="M0,0H12" transform="translate(6) rotate(90)" fill="none" stroke={"#707070"} strokeWidth="1"/>
        </svg>
      }
    </components.DropdownIndicator>
  );
};

interface IAsyncSelectPaginate {
  currValue?: any;
  loadOptions: any;
  onChange?: any;
  onStyleLabel?:
    | React.ComponentType<OptionProps<any, boolean, GroupTypeBase<any>>>
    | undefined;
  isSearchable?: boolean;
  defaultOptions?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  t?:any;
  className?: string;
  noOptionsMessage?: string;
  isSecondary?:boolean;
  cacheUniqs?: any;
}
const AsyncSelect: FC<IAsyncSelectPaginate> = ({
  onStyleLabel,
  currValue,
  loadOptions,
  onChange,
  isSearchable,
  defaultOptions,
  placeholder,
  isDisabled,
  className,
  noOptionsMessage,
  isSecondary,
  ...props
}: IAsyncSelectPaginate) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <AsyncPaginate
        value={currValue || undefined}
        loadOptions={loadOptions}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        onChange={(val: any) => {
          onChange && onChange(val);
        }}
        onMenuClose={()=>{
          setIsOpened(false);
        }}
        onMenuOpen={()=>{
          setIsOpened(true);
        }}
        //menuIsOpen
        //@ts-ignore
        styles={{ ...(isSecondary ? customStyleSecondary : customStyle) }}
        className={`effe-async-paginate ${className} ${isOpened ? "effe-async-paginate-open":"effe-async-paginate-close"}`}
        isSearchable={isSearchable||false}
        placeholder={placeholder}
        additional={{
          page: 0,
        }}
        noOptionsMessage={()=>noOptionsMessage || props.t('general.no_option')}
        isDisabled={isDisabled}
        menuPlacement={"auto"}
        menuPosition={"fixed"}
        components={{
          Option: (prs: any) => CustomOption({ ...prs, onStyleLabel: onStyleLabel }),
          DropdownIndicator: (prs: any) => DropdownIndicator({ ...prs, isOpened, isSecondary }),
          }}
        cacheUniqs={props?.cacheUniqs}
      />
    </>
  );
};
export default withTranslation()(AsyncSelect);
