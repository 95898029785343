import { StepError } from "../../controller/Configuratore";
import Action, { ActionTypes } from "../actionTypes";

export enum EInternalExternalImage{
  INTERNAL,
  EXTERNAL
}
export type TGeneralStoreState = {
    loginTryCount?: number;
    skipInternalExternalStep?: boolean;
    internalExternalImage?: EInternalExternalImage;
    appLoading?: boolean;
    stepError?: StepError|undefined;
}
const initialState: TGeneralStoreState = {
    loginTryCount: 0,
    skipInternalExternalStep: false,
    internalExternalImage: EInternalExternalImage.EXTERNAL,
    appLoading: true,
    stepError: undefined
};


export default function(state = initialState, action:Action) {
  switch (action.type) {
    case ActionTypes.ActionGeneral: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
