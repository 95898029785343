import { FC } from "react";
import ActionButton, { IActionButtonType } from "../../Buttons/ActionButton";
import { Text } from "../../Typography";
import { Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import {
    Row,
    Select,
  } from "antd";
import { IconFrecciaDown, IconSelected } from "../../Icon";
import tailwindMyConfig from "../../../tailwind.my.config";
import { isMobile } from "../../../utils/utils";
import Image from "../../Image";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion"

const { Option } = Select;

type TItemToSelect = {
    name: string;
    value: any;
}

interface ISelectableDetailGridItem extends React.HtmlHTMLAttributes<HTMLElement> {
  title: string;
  subtitle?: string;
  description: string;
  descriptionHtml?: string;
  extraDescription?: any;
  isSelected: boolean;
  isLoading?: boolean;
  maxCharDescription?: number;
  onClick: any;
  image: string;
  classNameImage?: string;
  actionDescription?: any;
  actionOnClick?: any;
  //Select Quantity
  isVisibleSelectQuantity?: boolean;
  selectQuantity?: number|undefined;
  selectQuantityPlaceholder?: string;
  onChangeSelectQuantity?: (qty: number) => void;
  maxQuantity?: number;
  optionsQuantiy?: number[];
  labelQuantity?: string;
  //Btn Quantity
  buttonTitleSelect?: string;
  buttonTitleDeselect?: string;
  t?: any;
  heightCell?: number;
  //Select Extra
  itemsExtraToSelect?: TItemToSelect[];
  selectItemExtraPlaceholder?: string;
  onChangeSelectItemExtra?: (item: TItemToSelect) => void;
  disabled?: boolean;
  classNameTitleDefault?: string;
}

const SelectableDetailGridItem: FC<ISelectableDetailGridItem> = ({ title, subtitle, description, descriptionHtml, isSelected, isLoading, image, maxCharDescription, onClick, classNameImage, actionDescription, actionOnClick,
    heightCell, buttonTitleSelect, buttonTitleDeselect, extraDescription, isVisibleSelectQuantity, selectQuantity, maxQuantity, optionsQuantiy, labelQuantity, onChangeSelectQuantity, selectQuantityPlaceholder, itemsExtraToSelect, disabled,
    selectItemExtraPlaceholder, onChangeSelectItemExtra, classNameTitleDefault, ...props }: ISelectableDetailGridItem) => {
        return (
    <div className={`flex flex-col flex-1 justify-between items-start ${props.className} ${disabled && "opacity-40"}`}>
        <Row className="mb-6">
            <Col xs={12}>
                
                    <div className="flex">
                        <AnimatePresence>
                            {isSelected && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <IconSelected isSelected={isSelected}  className={`transition-opacity`}/>  
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <div className="flex flex-col">
                            <LayoutGroup id={title}>
                                <Text title={title} className={`text-black-effe text-left font-bold text-titleCard mb-0`} />
                            </LayoutGroup>
                            {
                                disabled ? 
                                <p className="font-regular text-fluo-effe text-titleCard">{props?.t("v3.elemento_non_disponibile")}</p>
                                :
                                <div className="flex">
                                    {(!!selectQuantity) && <p className="mb-0 mr-2 font-bold text-titleSectionGenerator">{`${props.t("panche.quantita")}: ${selectQuantity}`}</p>}
                                    {
                                        onClick ?                                     
                                        <ActionButton 
                                            importance={IActionButtonType.Primary} 
                                            title={isSelected ? (buttonTitleDeselect || props.t("general.deseleziona")) : (buttonTitleSelect || props.t("general.seleziona"))} 
                                            onClick={onClick}
                                            isLoading={isLoading} disabled={disabled || isLoading}
                                            icon
                                            isSelected={isSelected}/>
                                        : null 
                                    }
                                </div>
                            }
                        </div>
                    </div>
            </Col>
        </Row>
        <div className="flex h-full w-full lg:flex-row">
            <Col lg={4} md={4} sm={4} xs={4} className="px-0 justify-start flex border-wizard-effe">
                <Image img={image} 
                imgStyle={{maxHeight:150, borderRight:"1px solid #B1B1B1"}} 
                classNameImage={`${classNameImage} w-full object-contain pr-8`}
                />
            </Col>
            <Col lg={8} md={8} sm={8} xs={8} className="px-0">
                <div className={`h-full flex justify-between flex-col py-0 xs:px-3 md:px-6`}>
                    <div>
                        {subtitle && <Text title={String(subtitle).toUpperCase()} className={`${!disabled && classNameTitleDefault} text-wizard-effe font-regular mb-0 titleCard`}/>}
                        {description && <Text title={maxCharDescription ? description.substr(0,maxCharDescription-3)+"..." : description} className={`${!disabled && classNameTitleDefault} text-wizard-effe font-regular mb-0 titleCard`}/>}
                        {descriptionHtml && <div className={`${!disabled && classNameTitleDefault} text-wizard-effe mb-0 font-regular titleCard`} dangerouslySetInnerHTML={{__html:descriptionHtml}}></div>}
                        {extraDescription ? extraDescription() : null}
                        {
                            actionDescription ? 
                            <button onClick={actionOnClick}>
                                <Text title={actionDescription} className={`${!disabled && classNameTitleDefault} text-wizard-effe mb-0 titleCard underline`}/>
                            </button>
                            : null
                        }
                    </div>
                    {
                        itemsExtraToSelect?.length ?
                        <div className="my-2">
                            <Select
                            placeholder={selectItemExtraPlaceholder || ""}
                            onChange={(item:any) => {
                                onChangeSelectItemExtra && onChangeSelectItemExtra(item)
                            }}
                            value={selectQuantity || undefined}
                            suffixIcon={<IconFrecciaDown fill={tailwindMyConfig.theme.extend.colors["wizard-effe"]}
                            style={{position:"absolute",top:-22,right:-20, fontSize:10, width:30}}/>}
                        >
                            {itemsExtraToSelect.map((it: TItemToSelect, index:number) => {
                                return (
                                    <Option key={index} value={it.value} style={{padding:0,margin:0}}>
                                        <div className="flex items-center h-full">
                                            <span className="text-wizard-effe font-text px-2">{it.name}</span>
                                        </div>
                                    </Option>
                                );
                            })}
                        </Select>
                        </div>
                        : null
                    }
                    {
                        isVisibleSelectQuantity ?
                        <div className="my-2">
                            <Select
                            placeholder={selectQuantityPlaceholder || ""}
                            onChange={(qty:number) => {
                                onChangeSelectQuantity && onChangeSelectQuantity(qty)
                            }}
                            value={selectQuantity || undefined}
                            suffixIcon={<IconFrecciaDown fill={tailwindMyConfig.theme.extend.colors["wizard-effe"]}
                            style={{position:"absolute",top:-22,right:-20, fontSize:10, width:30}}/>}
                        >
                            {
                            
                            (optionsQuantiy || Array.from(Array(maxQuantity||5).keys())).map((it: any, index:number) => {
                                const val = optionsQuantiy?.[index] || index+1
                                return (
                                    <Option key={val} value={val} style={{padding:0,margin:0}}>
                                        <div className="flex items-center h-full">
                                            <span className="text-wizard-effe font-text px-2">{`${val}${labelQuantity?` ${labelQuantity}` : ""}`}</span>
                                        </div>
                                    </Option>
                                );
                            })
                            
                            }
                        </Select>
                        </div>
                        : null
                    }
                </div>
            </Col>
        </div>
    </div>
  );
};
export default withTranslation()(SelectableDetailGridItem);
