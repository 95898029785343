import currency from "currency.js"
import { TListinoItemAcfType, TModelloDetailPortaType } from "../redux/reducers/porte_vetrate"
import { SLUG_ADAPTA_CUSTOM, SLUG_ANGOLO_VETRO_PORTA_CONTOUR, SLUG_ANGOLO_VETRO_PORTA_INSIDE, SLUG_TUTTOVETRO_CONTOUR, SLUG_TUTTOVETRO_INSIDE } from "./consts"
import { CrmLanguage, getAuthInfo } from "./storage"

export enum UnitOfMeasure {
    CENTIMETRI = "CM",
    PIEDI = "FT",
    IN = "IN"
}
export enum Localization {
    AMERICA = "AMERICA",
    EUROPA = "EUROPA"
}
export enum Valute {
    EURO = "€",
    DOLLARI = "$"
}


// UNITA DI MISURA
export const getUnitOfMeasure = () => {
    return getLocalization()===Localization.EUROPA ? UnitOfMeasure.CENTIMETRI : UnitOfMeasure.PIEDI
}
export const getSecondaryUnitOfMeasure = () => {
    return getLocalization()===Localization.EUROPA ? null : UnitOfMeasure.IN
}
export const getUnitOfMeasureMCubiLabel = () => {
    return getLocalization()===Localization.EUROPA ? "generatoreestint.modelli.fields.m_cubi" : "generatoreestint.modelli.fields.piedi_cubi"
}
export const getUnitOfMeasureCmLabel = () => {
    return getLocalization()===Localization.EUROPA ? "unit_measure.cm" : "unit_measure.piedi"
}

export const fromFeetToCm = (feet:number) => {
    return feet*30.48
}
export const fromIncToCm = (inc:number) => {
    return inc*2.54
}
export const fromMCubiToFeetCubi = (m_cubi:number) => {
    return m_cubi*35.3147
}
export const getCmFromFtAndInch = (ft:number|undefined, inc:number|undefined) => {
    return fromFeetToCm(ft||0)+fromIncToCm(inc||0)
}

/**
 * Converte i metri cubi nell'unità utilizzata nel paese corrente
 * @param m_cubi Metri cubi
 * @returns Valore in metri cubi o feet cubi
 */
export const fromMCubiToCurrentUnitOfMeasure = (m_cubi:string|undefined) => {
    return m_cubi !== undefined ? getLocalization()===Localization.EUROPA ? m_cubi : fromMCubiToFeetCubi(Number(m_cubi)) : undefined
}

// LOCALIZZAZIONE
export const getLocalization = () => {
    return window.location.pathname.substr(1,2).toUpperCase() === "US" ? Localization.AMERICA : Localization.EUROPA
}

export const getCrmLanguage = () => {
    const {crm_language} = getAuthInfo();
    return crm_language
}

export const getLanguage = () => {
    const {crm_language} = getAuthInfo()
    if(getLocalization()===Localization.AMERICA)
        return "en-us"
    switch (crm_language) {
        case CrmLanguage.IT:
        case CrmLanguage.IT_EXPORT:   
            return "it"
        case CrmLanguage.DE:
            return "de"
        case CrmLanguage.EN:
            return "en"
        case CrmLanguage.ES:
            return "es"
        case CrmLanguage.FR:
            return "fr"
        default:
            return "en" //fallback lang
    }
}

//VALUTA
export const getValute = () => {
    return getLocalization()===Localization.EUROPA ? Valute.EURO : Valute.DOLLARI
}

//ROLES
export const hasValidRoleToLogin = (roles:any): boolean => {
    return !!roles?.["agenzia_autorizzata"] || !!roles?.["rivenditore_autorizzato"] || !!roles?.["dipendente_effe"]
}


//MEDIA-QUERY
export const isMobile = () => {
    return window.innerWidth <= 640
}

export const isTablet = () => {
    return !isMobile() && window.innerWidth <= 1024
}


// APPLICAZIONE
export const isKitCoibentazioneVisible = () => {
    return true
}
enum CategoryIdPorta {
    PORTA_STANDARD_IT = 60,
    PORTA_STANDARD_EN = 60, //TODO:
    PORTA_CUSTOM_IT = 59,
    PORTA_CUSTOM_EN = 59, //TODO:
}
export const getCategoryIdPorta = (is_standard: boolean) => {
    return is_standard ? 
    getLocalization()===Localization.AMERICA ? CategoryIdPorta.PORTA_STANDARD_EN : CategoryIdPorta.PORTA_STANDARD_IT
    : 
    getLocalization()===Localization.AMERICA ? CategoryIdPorta.PORTA_CUSTOM_EN : CategoryIdPorta.PORTA_CUSTOM_IT
}
enum CategoryIdModelloPortaVetrate {
    MODELLO_PORTA_IT = 43,
    MODELLO_PORTA_EN = 43, //TODO:
    MODELLO_VETRATA_IT = 35,
    MODELLO_VETRATA_EN = 35, //TODO:
}
export const getCategoryIdModelloPortaVetrate = (porte:boolean) => {
    return porte ? (getLocalization()===Localization.AMERICA ? CategoryIdModelloPortaVetrate.MODELLO_PORTA_EN : CategoryIdModelloPortaVetrate.MODELLO_PORTA_IT) : (getLocalization()===Localization.AMERICA ? CategoryIdModelloPortaVetrate.MODELLO_VETRATA_EN : CategoryIdModelloPortaVetrate.MODELLO_VETRATA_IT)
}
enum CategoryIdOptional {
    OPTIONALS_IT = 33,
    OPTIONALS_EN = 0 //TODO: 
}
export const getCategoryIdOptional = () => {
    return getLocalization()===Localization.AMERICA ? CategoryIdOptional.OPTIONALS_EN : CategoryIdOptional.OPTIONALS_IT
}
enum CategoryIdAccessori {
    ACCESSORI_IT = 61,
    ACCESSORI_EN = 61 
}
export const getCategoryIdAccessori = () => {
    return getLocalization()===Localization.AMERICA ? CategoryIdAccessori.ACCESSORI_EN : CategoryIdAccessori.ACCESSORI_IT
}
enum CategoryIdPanche {
    PANCHE_IT = 119,
    PANCHE_EN = 0 //TODO: 
}
export const getCategoryIdPanche = () => {
    return getLocalization()===Localization.AMERICA ? CategoryIdPanche.PANCHE_EN : CategoryIdPanche.PANCHE_IT
}


// NUMBERS
export const parseDimension = (value:string|undefined) : number => {
    return Number(value?.replace(".", '')||0)
}

// STRINGS
export const capitalizeAllWords = (str:string) => {
    return str ? str.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } ) : "";
}

export function capitalize(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  

export const toPrice = (price:TListinoItemAcfType|undefined): number => {
    if(getLocalization()===Localization.AMERICA) return Number(price?.price)
    else return price ? (Number(getCrmLanguage()===CrmLanguage.IT_EXPORT ? price.price_extra : price.price)) : 0
}

export const toPricePancheCustom = (price:TListinoItemAcfType|undefined): number => {
    if(getLocalization()===Localization.AMERICA) return Number(price?.price)
    else return price ? (Number(getCrmLanguage()===CrmLanguage.IT ? price.price : price.price_extra)) : 0
}

export const formatPrice = (price_unformatted:number|undefined, localization:Localization|string=Localization.EUROPA) => {
    return price_unformatted!== undefined ? (localization === Localization.EUROPA ?  currency(Math.round(price_unformatted), { separator: '.', precision: 0, symbol: "" }).format() : currency(Math.round(price_unformatted), { separator: ',', precision: 2, decimal: '.', symbol: "" }).format()) : ""; 
}

export const formatPriceForContext = (price_unformatted:number|undefined) => {
    return formatPrice(price_unformatted, getLocalization())
}


export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}
export const checkIfUUID =  ( uuid:string ) : boolean => {
    const s = "" + uuid;
    let _s ;
    _s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (_s === null) {
      return false;
    }
    return true;
}



export const isVetroPortaVetroPortaModel = (porta:TModelloDetailPortaType) => {

    return porta?.slug === SLUG_ANGOLO_VETRO_PORTA_CONTOUR || 
    porta?.slug === SLUG_ANGOLO_VETRO_PORTA_INSIDE
}

export const isCustomPortaVetrata = (slug:string): boolean => {

    return slug === SLUG_TUTTOVETRO_CONTOUR || 
    slug === SLUG_TUTTOVETRO_INSIDE || slug === SLUG_ADAPTA_CUSTOM
}


export const getIdLightHammam = (): string => {
    const {crm_language} = getAuthInfo()
    if(getLocalization()===Localization.AMERICA) return "27414" 
    switch (crm_language) {
        case CrmLanguage.IT:
        case CrmLanguage.IT_EXPORT:
            return "27409"
        case CrmLanguage.FR:
            return "27411"
        case CrmLanguage.EN:
            return "27410"
        case CrmLanguage.DE:
            return "27412"
        case CrmLanguage.ES:
            return "27413"
        default:
            return "27409";
    }
}

export const getIdModuleLightHammam = (): string => {
    const {crm_language} = getAuthInfo()
    if(getLocalization()===Localization.AMERICA) return "28776" 
    switch (crm_language) {
        case CrmLanguage.IT:
        case CrmLanguage.IT_EXPORT:
            return "27415"
        case CrmLanguage.FR:
            return "28777"
        case CrmLanguage.EN:
            return "28775"
        case CrmLanguage.DE:
            return "348922"
        case CrmLanguage.ES:
            return "28779"
        default:
            return "27415";
    }
}